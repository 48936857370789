import React from "react";
import axios from "axios";
import { MOBILE_WEB_BASE_URL, WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
// import PaidIcon from '@mui/icons-material/Paid';
import PaymentsIcon from '@mui/icons-material/Payments';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { BlockLoading } from 'react-loadingg';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    BarElement,
  } from 'chart.js';
import { faker } from '@faker-js/faker';
import { Bar } from 'react-chartjs-2';
class Main extends React.Component {
    constructor() {
        super();
        this.state = {
            token: cookie.load("token"),
            businessName: cookie.load("businessName"),
            userGroup: cookie.load("userGroup"),
            walletBalance: 0,
            vasWalletBalance:0,
            vouchers: [],
            boughtvouchers: [],
            code: "",
            password: "",
            secretKey: "",
            publicKey: "",
            discount: "",
            userRole: cookie.load("userRole"),
            networks: [],
            dailyReport: [],
            avaliableVouchers: {},
            walletHistory: [],
            mobileStatus: false,
            successfulTransaction: 0,
            unsuccessfulTransaction: 0
        };
        this.getVouchers = this.getVouchers.bind(this);
        this.getWalletBalance = this.getWalletBalance.bind(this);
        this.getBoughtVouchers = this.getBoughtVouchers.bind(this);
        this.getNetworks = this.getNetworks.bind(this);
        this.getDailyReport = this.getDailyReport.bind(this);
        this.getMobileStatus = this.getMobileStatus.bind(this);
        this.requestNewMobileApp = this.requestNewMobileApp.bind(this);
        this.onChangeCode = this.onChangeCode.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.getVouchers2 = this.getVouchers2.bind(this);

        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend
        );
    }

    getMobileStatus = () => {
        return false;
    }
    getVouchers2 = () => {
        const today = new Date();
    // Get the components of today's date
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth() + 1; // Months are zero-indexed, so add 1
    const todayDate = today.getDate();

    // Formatted string of today's date (in yyyy-mm-dd format)
    const formattedToday = `${todayYear}-${todayMonth < 10 ? '0' : ''}${todayMonth}-${todayDate < 10 ? '0' : ''}${todayDate}`;
    let successfulCount = 0;
    let unsuccessfulCount = 0;
        const id = toast.loading("Please wait...");
        axios
            .get(MOBILE_WEB_BASE_URL + "topupmobile-custom-report?startDate=2024-01-01&endDate="+ formattedToday, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.status === "2000") {
                    console.log(res.data);
                     const walletCount = res.data.data;
                     walletCount.forEach(item => {
                        if (item.status === "successfull") {
                            successfulCount++;
                            this.setState({ successfulTransaction:  successfulCount++ });
                        } else if (item.status === "unsuccessfull") {
                            unsuccessfulCount++;
                            this.setState({ unsuccessfulTransaction:  unsuccessfulCount++ });
                        }
                    });
                    this.setState({ walletHistory:  res.data.data.reverse().slice(0, 10) });
                    toast.dismiss(id);
                    
                } else {
                    toast.update(id, {
                        render: res.data.description,
                        type: "error",
                        isLoading: false,
                    });
                }
            })
            .catch((err) => {
                toast.update(id, {
                    render: "" + err,
                    type: "error",
                    isLoading: false,
                });
            });
    };

    requestNewMobileApp = (e, password, transactionPin) => {
        e.preventDefault();
        if (
            this.state.code !== "" &&
            this.state.password !== "") {
            const id = toast.loading("Please wait...");

            axios
                .post(WEB_BASE_URL + "/merchant/request-mobile", {
                    password: this.state.password,
                    transactionPin: this.state.code,
                    email: cookie.load("email"),
                    dsp: this.state.discount,
                    publicKey: this.state.publicKey,
                    secret: this.state.secretKey,
                }, {
                    headers: {
                        authorization: this.state.token,
                    },
                })
                .then((res) => {
                    if (res.data.status === "2000") {
                        toast.update(id, {
                            render: "Request sent successfully",
                            type: "success",
                            isLoading: false,
                        });
                    } else {
                        console.log({
                            password: this.state.password,
                            transactionPin: this.state.code
                        })
                        console.log(res);
                        toast.update(id, {
                            render: " " + res.data.description,
                            type: "error",
                            isLoading: false,
                        });
                    }
                })
                .catch((err) => {
                    toast.update(id, {
                        render: err,
                        type: "error",
                        isLoading: false,
                    });
                });
        } else {
            toast.error("Form is not Complete");
        }
    }

    getVouchers = () => {
        axios
            .get(WEB_BASE_URL + "/dealer/planned-order", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.data !== null) {
                    res.data.data.reverse();
                    this.setState({ vouchers: res.data.data });
                }
            })
            .catch((err) => {
                console.log(this.state.token);
                toast.error(err);
            });
    };

    getWalletBalance() {
        axios
            .get(WEB_BASE_URL + "/onboarding/account-balance", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                cookie.save("walletBalance", res.data.data.unicardBalance, {
                    path: "/",
                    expires: new Date(Date.now() + 60 * 30 * 1000),
                });

                this.setState({ walletBalance: res.data.data.unicardBalance });
                this.setState({vasWalletBalance: res.data.data.unicardBalance ? res.data.data.unicardBalance : 0})
            })
            .catch((err) => { console.log(WEB_BASE_URL + " " + err); })
    }

    getBoughtVouchers = () => {
        axios
            .get(WEB_BASE_URL + "/logical/get-voucher", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.description == "No data available") {
                } else {
                    res.data.data.reverse();
                    this.setState({ boughtvouchers: res.data.data });
                }
            })
            .catch((err) => { console.log(WEB_BASE_URL + " " + err); });
    };

    getNetworks() {
        axios
            .get(WEB_BASE_URL + "/onboarding/get-network", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.status !== "403") {
                    let networks = res.data.data.map((network) => {
                        return { name: network.network, status: network.status };
                    });
                    if (networks.length === 0) {
                        toast.error("No Networks Found, Please Setup Config");
                    }
                    this.setState({ networks: networks });
                }
            })
            .catch((err) => {
                console.log(WEB_BASE_URL + " " + err);
            });
    }

    getDailyReport() {
        axios
            .get(WEB_BASE_URL + "/logical/daily-report", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.status === "2000" && res.data.data !== null) {

                    this.setState({ dailyReport: res.data.data });
                }
            })
            .catch((err) => {
                console.log(WEB_BASE_URL + " " + err);
            });
    }

    onChangePassword(event) {
        this.setState({
            password: event.target.value,
        });
    }

    //set state for formName
    onChangeCode(event) {
        this.setState({
            code: event.target.value,
        });
    }

    componentDidMount() {
        const user = cookie.load("user");
        if (user !== undefined) {
            this.getWalletBalance();
            this.getVouchers2();
            this.setState({
                mobileStatus: this.getMobileStatus()
            })
        }
    }


    render() {
        var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "NGN",
        });

        const labels = ["Type of Transaction"];
    const options = {
        responsive: true,
        plugins: [{
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
        {
            beforeDraw: (chart) => {
                if (this.state.isLoading) {
                    const canvas = chart.canvas;
                    const ctx = canvas.getContext('2d');
                    const centerX = canvas.width / 2;
                    const centerY = canvas.height / 2;

                    ctx.save();
                    ctx.beginPath();
                    ctx.translate(centerX, centerY);
                    BlockLoading.render({
                        size: 50,
                        color: '#263B95',
                        speed: 1,
                        duration: 2000
                    }, ctx);
                    ctx.restore();
                }
            }
        }
        ],
        scales: {
            x: {
                grid: {
                    display: false,
                },
                scaleLabel: {
                    display: true,
                    labelString: "Transaction Type"
                },
            },
            y: {
                grid: {
                    display: false,
                },
                scaleLabel: {
                    display: true,
                    labelString: "Number of transactions"
                },
            }
        }
    };
    const data = {
        labels,
        datasets: [
            {
                label: "Total transaction value",
                data: '0',
                backgroundColor: '#263B95',
                borderColor: '#263B95',
                borderRadius: 14,
                borderWidth: 2,
                fill: true, // Add color to the area underneath the line
            },
            {
                label: "Total successful transaction",
                data: [this.state.successfulTransaction],
                backgroundColor: '#76c48b',
                borderColor: '#76c48b',
                borderRadius: 14,
                borderWidth: 2,
                fill: true, // Add color to the area underneath the line
            },
            {
                label: "Total successful transaction",
                data: [this.state.unsuccessfulTransaction],
                backgroundColor: '#e0365e',
                borderColor: '#e0365e',
                borderRadius: 14,
                borderWidth: 2,
                fill: true, // Add color to the area underneath the line
            },
        ],
    };
        return (
            <div className="page-content">
                <ToastContainer
                    position="top-center"
                    autoClose={false}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {this.state.mobileStatus === false ?
                    <div>
                        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                            <div className="ps-3">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 p-0">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:;">
                                                <i className="bx bx-home-alt"></i>
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Mobile App Request
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="ms-auto">
                                <div className="btn-group">

                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-body p-4"> <div className="" style={{
                                display: "flex",
                                minHeight: "50vh",
                                justifyContent: "center",
                                flexDirection: "column",
                                backgroundColor: "white",
                                borderRadius: "8px",
                            }}>
                                <div style={{
                                    fontWeight: 700,
                                    fontSize: 25,
                                }}>Request for Mobile App</div>
                                <div style={{
                                    marginTop: 5,
                                    fontWeight: 400,
                                    fontSize: 20,
                                }}>A Gateway to having your own Agents.</div>
                                <form className="row mt-3">
                                    <div className="row mb-3">
                                        <div className="col-6">
                                            <label className="form-label">Paystack secret key</label>
                                            <input
                                                type="texxt"
                                                onChange={(event) => {
                                                    this.setState({
                                                        secretKey: event.target.value,
                                                    });
                                                }}
                                                value={this.state.secretKey}
                                                class="form-control datepicker "
                                                id="P1254294827"
                                                required
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label">Paystack public key</label>
                                            <input
                                                type="text"
                                                onChange={(event) => {
                                                    this.setState({
                                                        publicKey: event.target.value,
                                                    });
                                                }}
                                                value={this.state.publicKey}
                                                class="form-control datepicker "
                                                id="P1254294827"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-6">
                                            <label className="form-label">Your Account Password</label>
                                            <input
                                                type="password"
                                                onChange={(event) => this.onChangePassword(event)}
                                                value={this.state.password}
                                                class="form-control datepicker "
                                                id="P1254294827"
                                                required
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label">Set Discount (Max 2%)</label>
                                            <input
                                                type="text"
                                                onChange={(event) => {
                                                    //accept only numbers less than 2 and decimals
                                                    if (event.target.value <= 2) {
                                                        this.setState({
                                                            discount: event.target.value,
                                                        });
                                                    } else {
                                                        toast.error("Discount can not be more than 2%");
                                                    }
                                                }}
                                                value={this.state.discount}
                                                class="form-control datepicker "
                                                id="P1254294827"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <label className="form-label">Authenticator Code</label>
                                            <input
                                                type="text"
                                                onChange={(event) => this.onChangeCode(event)}
                                                value={this.state.code}
                                                class="form-control datepicker "
                                                id="P1254294827"
                                                required
                                            />
                                        </div>
                                        <div style={{
                                            marginTop: 12,
                                            fontWeight: 800,
                                            fontSize: 20,
                                        }}><button type="submit" className="btn btn-primary" onClick={e => { this.requestNewMobileApp(e) }}>Request New App</button></div>
                                    </div>
                                </form>
                            </div>
                            </div>
                        </div>
                    </div>
                    : <>
                        <div className="row">
                            <div className="col-md-8 card">
                                <div className=" overflow-hidden mt-2 radius-10">
                                    <div className="card-body" style={{
                                        minHeight: "33vh",
                                        maxHeight: "40vh"
                                    }}>
                                         {this.state.isLoading && <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><BlockLoading color="#263B95" /></div>}
                                <Bar options={options} data={data} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4">

                                <div className="row ">
                                    <div className="col-md-5 card"
                                      style={{
                                            margin:"10px"
                                        }}  
                                    >
                                        <div className=" overflow-hidden radius-10">
                                            <div className="card-body">
                                                <div className=" align-items-stretch justify-content-between overflow-hidden pb-1">
                                                    <div className="">
                                                        <AccountBalanceWalletIcon
                                                        />
                                                        <div className="mb-1 mt-4">VTU Wallet balance</div>

                                                        <h5 className="" style={{
                                                            fontSize: "14px",
                                                            fontWeight: "600",
                                                        }}>
                                                            {formatter.format(this.state.walletBalance)}
                                                        </h5>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 card"
                                      style={{
                                            margin:"10px"
                                        }}  
                                    >
                                        <div className=" overflow-hidden radius-10">
                                            <div className="card-body">
                                                <div className="align-items-stretch justify-content-between overflow-hidden">
                                                    <div className="">
                                                        <PaymentsIcon/>
                                                        <div className="mb-1 mt-4">VAS Wallet balance</div>
                                                        <h5 className="" style={{
                                                        fontSize: "14px",
                                                        fontWeight: "600",
                                                        }}> {formatter.format(this.state.vasWalletBalance)}</h5> 
                                                        {/* <PaidIcon
                                                        />
                                                        <div className="mb-1 mt-4">Total transaction in value</div>
                                                        <h5 className="" style={{
                                                            fontSize: "14px",
                                                            fontWeight: "600",
                                                        }}>{formatter.format(0)}</h5> */}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-5 card "
                                      style={{
                                            margin:"10px"
                                        }}  
                                    >
                                        <div className="overflow-hidden radius-10">
                                            <div className="card-body">
                                                <div className="align-items-stretch justify-content-between overflow-hidden">
                                                    <div className="">
                                                        <CheckCircleOutlineIcon
                                                         style={{ color:"green"}}
                                                        />

                                                        <div className="mb-1 mt-4">Successful transaction</div>
                                                        <h5 className="" style={{
                                                            fontSize: "14px",
                                                            fontWeight: "600",
                                                        }}>{this.state.successfulTransaction}</h5>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 card"
                                      style={{
                                            margin:"10px"
                                        }}  
                                    >
                                        <div className=" overflow-hidden radius-10">
                                            <div className="card-body">
                                                <div className="align-items-stretch justify-content-between overflow-hidden">
                                                    <div className="">
                                                        <CancelIcon
                                                        style={{ color:"red"}}
                                                        />
                                                        <div className="mb-1 mt-4">Failed transaction</div>
                                                        <h5 className="" style={{
                                                            fontSize: "14px",
                                                            fontWeight: "600",
                                                        }}>{this.state.unsuccessfulTransaction}</h5>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-lg-12 col-xl-12 d-flex">
                                <div className="card radius-10 w-100">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center">
                                            <h6 className="mb-0">Recent Transactions</h6>
                                            <div className="fs-5 ms-auto dropdown">
                                                <div
                                                    className="dropdown-toggle dropdown-toggle-nocaret cursor-pointer"
                                                    data-bs-toggle="dropdown"
                                                >
                                                    <i className="bi bi-three-dots"></i>
                                                </div>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            Action
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            Another action
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <hr className="dropdown-divider" />
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            Something else here
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="card-body">
                        <div class="table-responsive">
                            <table
                                id="example"
                                class="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                    <th>No</th>
                                        <th>Product</th>
                                        <th>Email Address</th>
                                        <th>Beneficiary Phone</th>
                                        <th>Customer Ref</th>
                                        <th>Refund Status</th>
                                        <th>Status</th>
                                        <th>Balance after</th>
                                        <th>Balance before</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.walletHistory.map((item, index) => (
                                        <tr key={index}>
                                        <td>{index + 1}</td>
                                            <td>{item.product}</td>
                                            <td>{item.emailAddress}</td>
                                            <td>{item.beneficiaryPhone}</td>
                                            <td>{item.customerRef}</td>
                                            <td>{item.refundStatus}</td>
                                            <td>{item.status}</td>
                                            <td>{formatter.format(item.amountAfter) }</td>
                                            <td>{ formatter.format(item.amountBefore)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <nav class="float-end mt-3">
                            <ul class="pagination">
                                <li class="page-item disabled">
                                    <a class="page-link" href="#">
                                        Previous
                                    </a>
                                </li>
                                <li class="page-item active">
                                    <a class="page-link" href="#">
                                        1
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">
                                        2
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">
                                        3
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">
                                        Next
                                    </a>
                                </li>
                            </ul>
                        </nav> */}
                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

            </div >
        );
    }
}

export default Main;
