import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import NetworkSwitch from "./microcomponents/networkSwitch";
import { WEB_BASE_URL } from "../../unified/webbase";

class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      walletBalance: cookie.load("walletBalance"),
      token: cookie.load("token"),
      dealerId: "",
      userRole: cookie.load("userRole"),
      userGroup: cookie.load("userGroup"),
      formEmail: "",
      formRole: "",
      formName: "",
      formState: "",
      roles: [],
      businessAddress: "",
      networks: [],
      spdAIRTEL: 0,
      spdGLO: 0,
      spdMTN: 0,
      spd9MOBILE: 0,
      bussinessNameInput: false,
    };

    //bind all events
    this.onInviteUser = this.onInviteUser.bind(this);

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.getAllowedRoles = this.getAllowedRoles.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
    this.getStateOptions = this.getStateOptions.bind(this);
    this.getNetworks = this.getNetworks.bind(this);
    this.onChangeBusinessName = this.onChangeBusinessName.bind(this);
    this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
  }

  onInviteUser(event) {
    event.preventDefault();
    if (
      this.state.formEmail !== "" &&
      this.state.formRole !== "" &&
      this.state.formName !== ""
    ) {
      const id = toast.loading("Please wait...");
      const username = this.state.formName.split(" ");
      if (username[1] === undefined || username[0] === undefined) {
        toast.update(id, {
          render: "First name and last name are required",
          type: "error",
          isLoading: false,
        });
        return;
      }

      const user = {
        localGovArea: [this.state.formState],
        businessName: this.state.dealerId,
        businessEmail: this.state.formEmail,
        businessAddress: this.state.formState,
        emailAddress: this.state.formEmail,
        userRole: this.state.formRole,
        firstname: this.capitalizeFirstLetter(username[0]),
        lastname: this.capitalizeFirstLetter(username[1]),
        spdAirtel: this.state.spdAIRTEL,
        spdGlo: this.state.spdGLO,
        spdMtn: this.state.spdMTN,
        spdNineMobile: this.state.spd9MOBILE,
      };
      console.log(user);
      axios
        .post(WEB_BASE_URL + "/onboarding/invite-user", user, {
          headers: {
            authorization: this.state.token,
          },
        })
        .then((res) => {
          if (res.data.status == "2000") {
            toast.update(id, {
              render: "Invitation sent successfully",
              type: "success",
              isLoading: false,
            });
            setTimeout(() => {
              this.props.navigate("/users");
              this.props.navigate(0);
            }, 1000);
          } else {
            console.log(res);
            console.log(user)
            toast.update(id, {
              render: "" + res.data.description,
              type: "error",
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          console.log(user);
          toast.update(id, {
            render: "" + err,
            type: "error",
            isLoading: false,
          });
        });
    } else {
      toast.error("Form is not Complete");
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getAllowedRoles() {
    //get list of roles from backend
    let url = "/onboarding/get-roles";
    axios
      .get(WEB_BASE_URL + url, {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.data !== null) {
          console.log(res);
          if (this.state.userGroup == "MERCHANT" && this.state.userRole == "MANAGER") {
            this.setState({
              roles: [{ "inviteRole": "OPERATOR" }],
            });
            return;
          }
          this.setState({
            roles: res.data.data,
          });
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  getStateOptions = () => {
    axios
      .get(WEB_BASE_URL + "/onboarding/dealer-localGov", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        //check if data is not null
        if (res.data.data !== null) {
          let stateOptions = res.data.data.localGov.map((state) => {
            return { value: state, label: state };
          });
          this.setState({ stateOptions: stateOptions });
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  };

  getNetworks() {
    axios
      .get(WEB_BASE_URL + "/onboarding/get-network", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status !== "403") {
          console.log(res.data.data);
          let networks = res.data.data.map((network) => {
            return { name: network.network, status: network.status };
          });
          this.setState({ networks: networks });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  switchNetworkStatus(networkId, status) {
    const networks = [].concat(this.state.networks);
    networks[networkId].status = status === "active" ? "not active" : "active";
    this.setState({
      networks: networks,
    });
  }

  onChangeState = (e) => {
    this.setState({
      formState: e.value,
    });
  };

  //set state for formEmail
  onChangeEmail(event) {
    this.setState({
      formEmail: event.target.value,
    });
  }

  //set state for formName
  onChangeName(event) {
    this.setState({
      formName: event.target.value,
    });
  }

  //set state for formRole
  onChangeRole(event) {
    this.setState({
      formRole: event.target.value,
    });

    if (
      event.target.value == "POP_MANAGER" ||
      event.target.value == "STP_MANAGER"
    ) {
      this.setState({
        bussinessNameInput: true,
      });
    }
  }

  onChangeBusinessName(event) {
    this.setState({
      dealerId: event.target.value,
    });
  }

  componentDidMount() {
    this.getAllowedRoles();
    this.getStateOptions();
    this.getNetworks();
  }

  render() {
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.selectProps.menuColor,
        padding: 18,
      }),

      control: (provided, { selectProps: { width } }) => ({
        ...provided,
        borderRadius: "1px",
        padding: "2px 4px",
        width: width,
        minWidth: "310px",
      }),
    };
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Invite User
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button type="button" className="btn btn-primary">
                Wallet Balance: &#8358;{this.state.walletBalance}
              </button>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <form
              className="row"
              onSubmit={(e) => {
                this.onInviteUser(e);
              }}
            >
              <div className="row">
                <div className="col-6">
                  <label className="form-label">Email</label>
                  <input
                    type="text"
                    onChange={(event) => this.onChangeEmail(event)}
                    value={this.state.formEmail}
                    class="form-control datepicker "
                    id="P1254294827"
                    required
                  />
                </div>
                <div className="col-6">
                  <label className="form-label">
                    Full Name (FirstName First)
                  </label>
                  <input
                    type="text"
                    onChange={(event) => this.onChangeName(event)}
                    value={this.state.formName}
                    class="form-control datepicker "
                    id="P1254294827"
                    required
                  />
                </div>
              </div>

              <div className="row my-3">
                {/* <div className="col-6">
                  <label className="form-label">Local Government</label>
                  <div className="ms-auto position-relative">
                    <div className="position-absolute top-50 translate-middle-y search-icon px-3"></div>
                    <Select
                      onChange={this.onChangeState}
                      options={this.state.stateOptions}
                      styles={customStyles}
                      width="100%"
                      placeholder="Select Local Government Area"
                      borderRadius="17px"
                    />
                  </div>
                </div> */}
                <div className="col-6">
                  <label className="form-label">Role</label>
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    value={this.state.formRole}
                    onChange={(event) => this.onChangeRole(event)}
                    required
                  >
                    <option selected="">Select Role</option>
                    {this.state.roles.map((role) => (
                      <option value={role.inviteRole}>{role.inviteRole}</option>
                    ))}
                  </select>
                </div>
              </div>
              {/* <div className="row my-3">
                <div className="col-6">
                  <label className="form-label">Business Name</label>
                  <input
                    type="text"
                    onChange={(event) => this.onChangeBusinessName(event)}
                    value={this.state.dealerId}
                    class="form-control datepicker "
                    id="P1254294827"
                  />
                </div>
              </div> */}
              {this.state.networks.map((network, index) =>
                network.status === "active" ? (
                  <div className="col-12 my-1">
                    <label className="form-label">{network.name}</label>
                    <input
                      type="text"
                      name="dsp"
                      placeholder="Selling Price Discounted"
                      class="form-control"
                      onChange={(e) => {
                        this.setState({
                          [`spd${network.name}`]: e.target.value,
                        });
                      }}
                      disabled={network.status !== "active" ? true : false}
                      required
                    />
                  </div>
                ) : (
                  <></>
                )
              )}
              <div className="col-4">
                <button
                  type="submit"
                  style={{ marginTop: 2 }}
                  className="btn btn-primary d-flex"
                >
                  <div>
                    <i class="bi bi-cloud-arrow-down-fill"></i>
                  </div>
                  <div className="mx-3">Invite User</div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    );
  }
}

export default CreateUser;
