import  React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import cookie from "react-cookies";
import { WEB_BASE_URL } from '../../unified/webbase';
import axios from 'axios';
import { useEffect } from 'react';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  boxShadow: 24,
};


export default function VirtualAccountModal({open, handleClose}) {
     const [accNo, setAccNo] = useState('')
     const [accName, setAccName] = useState('')
     const [bank, setBank] = useState('')
    // const accNo = cookie.load("accNo")
    // const accName = cookie.load("accNa")
    const token = cookie.load("token")

    useEffect(() => {
       
      let api = "";
      api = "/onboarding/get-dedicated-account";
      axios
          .get(
              WEB_BASE_URL +
              api ,
              {
                  headers: {
                      authorization: token,
                  },
              }
          )
          .then((res) => {
              if (res.data.status === "2000" && res.data.data !== null) {
                  setAccName(res.data.data.accountName);
                  setAccNo(res.data.data.accountNumber);
                  setBank(res.data.data.bankName)
              } else {
                  toast.error("No data found");
                  
              }
          })
          .catch((err) => {
              console.log(WEB_BASE_URL + " " + err);
              toast.error("Error fetching transactions");
          }).finally(() => {
              
          });
  },[ token])
    const handleClick = async () => {
      try {
        await navigator.clipboard.writeText(accNo);
        toast.success('Account number copied')
        toast( {
            render:'Account number copied',
            type: "success",
          });
      
        // Reset copied state after a few seconds
        setTimeout(() => {
         
        }, 2000);
      } catch (error) {
        console.error('Failed to copy:', error);
      }
    };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg  p-4 pt-10'> 
          
            <div className=' '>
           
                <div className=' d-flex justify-content-end mb-3'>
                   
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-black cursor-pointer text-[38px]' fontSize="large"/>
                    
                    </div>
                </div>
                <div className=' p-5 card'
                 style={{
                        backgroundColor: "#D3EFF9",
                        borderRadius:  "16px",
                      }}>
                        <h6 className=' mb-3'>Virtual wallet</h6>
                      <div className=' mb-3'>
                      <h6 >Bank:</h6>
                      <h6 > {bank}</h6>
                      </div>
                      <div className=' mb-3'>
                      <h6 >Account name:</h6>
                      <h6 > {accName}</h6>
                      </div>
                      <div className=' mb-3'>
                      <h6 >Account number:</h6>
                      <h5 > {accNo} <ContentCopyIcon onClick={handleClick} style={{
                        color: "blue",
                        
                      }}  /></h5>
                      </div>
                    
                </div>
               <div>
                <PriorityHighIcon/> Use description of VTU or VAS
               </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}