

// class Transaction extends React.Component {
//   constructor() {
//     super();
//     this.state = {
//       someKey: "someValue",
//       startDate: new Date(),
//       token: cookie.load("token"),
//       endDate: new Date(),
//       walletBalance: 0,
//       transactionBalance: [],
//       vasWallet:cookie.load("vasWallet"),
//     };
//     //binding this to event-handler functions
//     this.setStartDate = this.setStartDate.bind(this);
//     this.getWalletBalance = this.getWalletBalance.bind(this);
//     this.getTransactionDetails = this.getTransactionDetails.bind(this);
//     this.setEndDate = this.setEndDate.bind(this);
//   }
//   setStartDate(date) {
//     this.setState({
//       startDate: date,
//     });
//   }

//   setEndDate(date) {
//     this.setState({
//       endDate: date,
//     });
//   }
//   getTransactionDetails(){
    // axios
    // .get(WEB_BASE_URL + "/merchant/unicard-custom-report?endDate=2024-02-28&startDate=2024-01-01", {
    //   headers: {
    //     authorization: this.state.token,
    //   },
    // })
    // .then((res) => {
    //   if(res.data.status === "2000"){
    //     this.setState({ transactionBalance: res.data.report ? res.data.report: 0});
    //     console.log(res.data.report)
    //   }
    // })
    // .catch((err) => { console.log(WEB_BASE_URL + " " + err); })
//   }

  // getWalletBalance() {
  //   axios
  //     .get(WEB_BASE_URL + "/onboarding/account-balance", {
  //       headers: {
  //         authorization: this.state.token,
  //       },
  //     })
  //     .then((res) => {
  //       cookie.save("walletBalance", res.data.data.entBalance, {
  //         path: "/",
  //         expires: new Date(Date.now() + 60 * 30 * 1000),
  //       });
  //       cookie.save("vasWallet", res.data.data.unicardBalance, {
  //         path: "/",
  //         expires: new Date(Date.now() + 60 * 30 * 1000),
  //     });
  //       this.setState({ walletBalance: res.data.data.entBalance ? res.data.data.entBalance : 0});
  //       this.setState({ vasWallet: res.data.data.unicardBalance ? res.data.data.unicardBalance : 0})
  //     })
  //     .catch((err) => { console.log(WEB_BASE_URL + " " + err); })
  // }

//   componentDidMount() {
//     this.getWalletBalance();
//     this.getTransactionDetails();
//   }
//   render() {
//     return (
      // <main className="page-content">
      //   <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      //     <div className="ps-3">
      //       <nav aria-label="breadcrumb">
      //         <ol className="breadcrumb mb-0 p-0">
      //           <li className="breadcrumb-item">
      //             <a href="javascript:;">
      //               <i className="bx bx-home-alt"></i>
      //             </a>
      //           </li>
      //           <li className="breadcrumb-item active" aria-current="page">
      //             Transactions
      //           </li>
      //         </ol>
      //       </nav>
      //     </div>
      //     <div className="ms-auto">
      //       <div className="btn-group">
      //         <button type="button" className="btn btn-primary">
      //           Wallet Balance: &#8358;{this.state.vasWallet}
      //         </button>
      //       </div>
      //     </div>
      //   </div>

      //   <div className="card">
      //     <div className="card-body">
      //       <form className="row">
      //         <div className="row">
      //           <div className="col-6">
      //             <label className="form-label">Transaction Date:</label>
      //             <DatePicker
      //               selected={this.state.startDate}
      //               onSelect={(date) => this.setStartDate(date)}
      //               onChange={(date) => this.setStartDate(date)}
      //             />
      //           </div>
      //           <div className="col-4">
      //             <label className="form-label">Transaction Type</label>
      //             <select
      //               className="form-select "
      //               aria-label="Default select example"
      //               value={this.props.formRole}
      //               onChange={(event) => this.props.onChangeRole(event)}
      //             >
      //               <option selected=""> Select Status</option>
      //               <option value="1">All</option>
      //               <option value="2">Used</option>
      //               <option value="3">Unused</option>
      //             </select>
      //           </div>
      //         </div>
      //         <div className="row">
      //           <div className="col-4">
      //             <button
      //               style={{ marginTop: 29 }}
      //               className="btn btn-primary d-flex"
      //             >
      //               <div>
      //                 <i class="bi bi-cloud-arrow-down-fill"></i>
      //               </div>
      //               <div className="mx-3">Download Report</div>
      //             </button>
      //           </div>
      //         </div>
      //       </form>
      //     </div>
      //   </div>

      //   <div className="card">
      //     <div className="card-header py-3">
      //       <div className="row g-3">
      //         <div className="col-lg-3 col-md-6 me-auto">
      //           <div className="ms-auto position-relative">
      //             <div className="position-absolute top-50 translate-middle-y search-icon px-3">
      //               <i className="bi bi-search"></i>
      //             </div>
      //             <input
      //               className="form-control ps-5"
      //               type="text"
      //               placeholder="Search Payment"
      //             />
      //           </div>
      //         </div>

      //         <div className="col-lg-2 col-6 col-md-3">
      //           <select className="form-select">
      //             <option>Show 10</option>
      //             <option>Show 30</option>
      //             <option>Show 50</option>
      //           </select>
      //         </div>
      //       </div>
      //     </div>
      //     <div class="card-body">
      //       <div class="table-responsive">
      //         <table
      //           id="example"
      //           class="table table-striped table-bordered"
      //           style={{ width: "100%" }}
      //         >
      //           <thead>
      //             <tr>
      //               <th>ID</th>
      //               <th>Phone Number</th>
      //               <th>Batch ID</th>
      //               <th>Serial Number</th>
      //               <th>Transaction date</th>
      //               <th>Network</th>
      //               <th>Amount</th>
      //             </tr>
      //           </thead>
      //           <tbody>
      //             <tr>
      //               <td>1</td>
      //               <td>
      //                 <i
      //                   className="bi bi-arrow-down"
      //                   style={{ color: "red", marginRight: "5px" }}
      //                 ></i>
      //                 08097565555
      //               </td>
      //               <td>#2345</td>
      //               <td>77567</td>

      //               <td>2011/04/25</td>
      //               <td>MTN</td>
      //               <td>$320,800</td>
      //             </tr>
      //           </tbody>
      //           <tfoot>
      //             <tr>
      //               <th>ID</th>
      //               <th>Phone Number</th>
      //               <th>Batch ID</th>
      //               <th>Serial Number</th>
      //               <th>Transaction date</th>
      //               <th>Network</th>
      //               <th>Amount</th>
      //             </tr>
      //           </tfoot>
      //         </table>
      //       </div>
      //       <nav class="float-end mt-3">
      //         <ul class="pagination">
      //           <li class="page-item disabled">
      //             <a class="page-link" href="#">
      //               Previous
      //             </a>
      //           </li>
      //           <li class="page-item active">
      //             <a class="page-link" href="#">
      //               1
      //             </a>
      //           </li>
      //           <li class="page-item">
      //             <a class="page-link" href="#">
      //               2
      //             </a>
      //           </li>
      //           <li class="page-item">
      //             <a class="page-link" href="#">
      //               3
      //             </a>
      //           </li>
      //           <li class="page-item">
      //             <a class="page-link" href="#">
      //               Next
      //             </a>
      //           </li>
      //         </ul>
      //       </nav>
      //     </div>
      //   </div>
      // </main>
//     );
//   }

//   componentDidMount() {
//     this.setState({
//       someKey: "otherValue",
//     });
//   }
// }

// export default Transaction;

import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import { WEB_BASE_URL } from "./webbase";
import cookie from "react-cookies";
import "react-datepicker/dist/react-datepicker.css";

export default function Transaction() {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [transactionDetails, setTransactionDetails] = useState([])
  const token = cookie.load("token")
  const [vasWallet, setVasWallet] = useState(0)

  useEffect(() =>{
   
      axios
        .get(WEB_BASE_URL + "/onboarding/account-balance", {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          cookie.save("walletBalance", res.data.data.entBalance, {
            path: "/",
            expires: new Date(Date.now() + 60 * 30 * 1000),
          });
          cookie.save("vasWallet", res.data.data.unicardBalance, {
            path: "/",
            expires: new Date(Date.now() + 60 * 30 * 1000),
        });
        setVasWallet(res.data.data.unicardBalance ? res.data.data.unicardBalance : 0)
        
        })
        .catch((err) => { console.log(WEB_BASE_URL + " " + err); })
   
  
  }, [token])

  useEffect(() =>{
    const date = new Date(endDate);

  // Format the Date object into "yyyy-MM-dd" format
  const formattedDate = date.toISOString().slice(0, 10);
    axios
    .get(WEB_BASE_URL + `/merchant/unicard-custom-report?endDate=` + formattedDate +
    "&startDate=2024-01-01", {
      headers: {
        authorization: token,
      },
    })
    .then((res) => {
      if(res.data.status === "2000"){
        setTransactionDetails(res.data.report ? res.data.report.reverse(): 0)
        //this.setState({ transactionBalance: res.data.report ? res.data.report: 0});
        console.log(res.data.report)
      }
    })
    .catch((err) => { console.log(WEB_BASE_URL + " " + err); })
  },[token])

  return (
    <div>
      <main className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Transactions
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button type="button" className="btn btn-primary">
                Wallet Balance: &#8358;{vasWallet}
              </button>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <form className="row">
              <div className="row">
                <div className="col-6">
                  <label className="form-label">Transaction Date:</label>
                  <DatePicker
                    selected={startDate}
                    onSelect={(date) => setStartDate(date)}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
                <div className="col-4">
                  <label className="form-label">Transaction Type</label>
                  <select
                    className="form-select "
                    aria-label="Default select example"
                    // value={this.props.formRole}
                    // onChange={(event) => this.props.onChangeRole(event)}
                  >
                    <option selected=""> Select Status</option>
                    <option value="1">All</option>
                    <option value="2">Used</option>
                    <option value="3">Unused</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <button
                    style={{ marginTop: 29 }}
                    className="btn btn-primary d-flex"
                  >
                    <div>
                      <i class="bi bi-cloud-arrow-down-fill"></i>
                    </div>
                    <div className="mx-3">Download Report</div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="card">
          <div className="card-header py-3">
            <div className="row g-3">
              <div className="col-lg-3 col-md-6 me-auto">
                <div className="ms-auto position-relative">
                  <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i className="bi bi-search"></i>
                  </div>
                  <input
                    className="form-control ps-5"
                    type="text"
                    placeholder="Search Payment"
                  />
                </div>
              </div>

              <div className="col-lg-2 col-6 col-md-3">
                <select className="form-select">
                  <option>Show 10</option>
                  <option>Show 30</option>
                  <option>Show 50</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="example"
                class="table table-striped table-bordered"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Value</th>
                    <th>Unit price</th>
                    <th>User Id</th>
                    <th>Transaction date</th>
                    <th>Merchant Account Id</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                {transactionDetails.map((e, index) =>{
                return  <tr key={index}>
                    <td>{e.id}</td>
                    <td>
                     {e.value}
                    </td>
                    <td>{e.unitPrice}</td>
                    <td>{e.userId}</td>

                    <td>{e.dateCreated !== undefined
                      ? new Date(e.dateCreated).toLocaleString()
                      : 0} </td>
                    <td>{e.merchantAccountId}</td>
                    <td>{e.used === true ? 'Used':'Unused'}</td>
                  </tr>
                })}
             
                </tbody>
                {/* <tfoot>
                  <tr>
                    <th>ID</th>
                    <th>Phone Number</th>
                    <th>Batch ID</th>
                    <th>Serial Number</th>
                    <th>Transaction date</th>
                    <th>Network</th>
                    <th>Amount</th>
                  </tr>
                </tfoot> */}
              </table>
            </div>
            <nav class="float-end mt-3">
              <ul class="pagination">
                <li class="page-item disabled">
                  <a class="page-link" href="#">
                    Previous
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </main>
    </div>
  )
}