import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "../../unified/webbase";
import axios from "axios";

class MakeFund extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      walletBalance: cookie.load("walletBalance"),
      history: props.history,
      token: cookie.load("token"),
      amount: "",
      accountName: "",
      naration: "",
      selectedType: "BANK_PAYMENT",
      refID: "",
      banks: [],
      business: {},
      authCode: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = (event) => {
    event.preventDefault();
    const id = toast.loading("Please wait...");
    console.log(this.state.token);

    //axios post to sign in
    axios
      .post(
        WEB_BASE_URL + "/dealer/fund-merchant-account",
        {
          "userId": this.state.accountName,
          "amount": this.state.amount,
          "referenceNumber": this.state.refID,
          "product": "vas",
          "remarks": this.state.naration,
          "transferMode": "BANK_PAYMENT",
          transactionPin: this.state.authCode,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        let iflog = res.data.status == 2000 ? true : false;

        if (iflog) {
          toast.update(id, {
            render: "Transaction Successful",
            type: "success",
            isLoading: false,
          });
          setTimeout(() => {
            this.props.navigate(0);
          }, 1000);
        } else {
          console.log(res);
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(id);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log({
          amount: this.state.amount,
          accountName: this.state.accountName,
          transferMode: this.state.selectedType,
          referenceId: this.state.refID,
          transactionPin: this.state.authCode,
        });
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
        setTimeout(() => {
          toast.dismiss(id);
        }, 2000);
      });
  };

  componentDidMount() {

  }

  render() {
    function formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Fund Wallet
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">

            </div>
          </div>
        </div>

        <div className="">
          <div className="">
            <div className="container py-2">

              <div className="row">
                <div className="col py-2">
                  <div className="card border-primary shadow radius-15">
                    <div className="card-body">
                      <h4 className="card-title text-primary">
                        Fill Details for Funding
                      </h4>
                      <p className="card-text mt-3">
                        <form
                          className="row g-3"
                          onSubmit={(event) => {
                            this.onSubmit(event);
                          }}
                        >

                          <div className="col-6">
                            <label className="form-label">
                              Amount (in Naira)
                            </label>
                            <input
                              onChange={(event) => {
                                this.setState({
                                  amount: event.target.value.replaceAll(
                                    ",",
                                    ""
                                  ),
                                });
                              }}
                              className="form-control"
                              value={formatNumber(this.state.amount)}
                              required={true}
                            />
                          </div>

                          <div className="col-6">
                            <label className="form-label">Narration</label>
                            <input
                              onChange={(event) => {
                                this.setState({
                                  naration: event.target.value,
                                });
                              }}
                              type="text"
                              className="form-control"
                              value={this.state.naration}
                              required={true}
                            />
                          </div>
                          <div className="col-6">
                            <label className="form-label">
                              Authenticator Code
                            </label>
                            <input
                              onChange={(event) => {
                                this.setState({
                                  authCode: event.target.value,
                                });
                              }}
                              type="text"
                              className="form-control"
                              value={this.state.authCode}
                            />
                          </div>

                          <div className="col-4 ">
                            <label className="form-label"> &nbsp; </label>
                            <button
                              className="btn btn-primary form-control"
                              type="submit"
                            >
                              Submit For Confirmation
                            </button>
                          </div>
                        </form>
                      </p>
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
      </main>
    );
  }
}

export default MakeFund;
