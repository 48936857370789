import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "./App.css";
import SignUp from "./unified/signUp";
import SignIn from "./unified/signIn";
import Dashboard from "./logical/components/dashboard";
import VerifyEmail from "./logical/components/verifyEmail";
import SuccessPage from "./logical/components/messages/successPage";
import AuthPage from "./unified/AuthenticatorSlide";
import ResetPassword from "./unified/ResetPassword";
import ResetPin from "./unified/ResetPin";
import ForgotPassword from "./unified/ForgotPassword";
import CompleteSignup from "./unified/CompleteSignUp";
import DirectSignUp from "./unified/signUpDirect";
import cookie from "react-cookies";

function App() {
  const navigate = useNavigate();
  const [activeProduct, setActiveProduct] = useState(cookie.load("activeProduct"));

  useEffect(() => {
  }, []);


  useEffect(() => {
    cookie.save("activeProduct", activeProduct, {
      path: "/",
      expires: new Date(Date.now() + 60 * 27 * 1000),
    });
  }, [activeProduct]);

  const WrappedComponent = (props) => {
    const location = useLocation();
    return (
      <props.Component navigate={navigate} location={location} {...props} activeProduct={activeProduct} setActiveProduct={setActiveProduct} />
    );
  };

  return (
    <div>
      <Routes>
        <Route
          exact
          path="/complete-signup"
          element={
            <WrappedComponent Component={CompleteSignup}></WrappedComponent>
          }
        />
        <Route
          exact
          path="/success-page"
          element={
            <WrappedComponent Component={SuccessPage}></WrappedComponent>
          }
        />
        <Route
          exact
          path="/direct-signUp"
          element={<WrappedComponent Component={DirectSignUp}></WrappedComponent>}
        />
        <Route
          exact
          path="/signUp"
          element={<WrappedComponent Component={SignUp}></WrappedComponent>}
        />
        <Route
          exact
          path="/signIn"
          element={<WrappedComponent Component={SignIn}></WrappedComponent>}
        />
        <Route
          exact
          path="/verifyEmail"
          element={
            <WrappedComponent Component={VerifyEmail}></WrappedComponent>
          }
        />
        <Route
          exact
          path="/authenticate"
          element={<WrappedComponent Component={AuthPage}></WrappedComponent>}
        />
        <Route
          path="/forgot-password"
          element={
            <WrappedComponent Component={ForgotPassword}></WrappedComponent>
          }
        />
        <Route
          path="/reset-password"
          element={
            <WrappedComponent Component={ResetPassword}></WrappedComponent>
          }
        />
        <Route
          path="/resetPin"
          element={<WrappedComponent Component={ResetPin}></WrappedComponent>}
        />
        <Route
          path="*"
          element={<WrappedComponent Component={Dashboard}></WrappedComponent>}
        />
      </Routes>
    </div>
  );
}

export default App;
