import React from 'react';
import Header from '../unified/header';
import SideBar from '../unified/SideBar';
import {
    BrowserRouter as Router,
    Routes as Switch,
    Route,
} from "react-router-dom";
import Main from './components/main';
import Transaction from "./components/transaction";
import Fund from "../logical/components/fund";
import FundWalletReq from "../logical/components/fundWalletReq";
import CreateUser from '../logical/components/CreateUser';
import TransferFund from '../logical/components/transferFund';
import WalletHistory from '../logical/components/WalletHistory';
import Support from '../logical/components/support';
import Users from '../logical/components/users';
import Profile from '../logical/components/profile';
import Compliance from '../logical/components/compliance';
import SetThreshold from '../unicard/components/SetThreshold';
import AppUsers from './components/appUsers';
import SetDiscount from './components/SetDiscount';
import RequestReseller from './components/RequestReseller';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            someKey: 'someValue'
        };
    }

    componentDidMount() {
        this.setState({
            someKey: 'otherValue'
        });
    }

    render() {
        return (<div>
            <Header {...this.props} activeProduct={this.props.activeProduct} setActiveProduct={this.props.setActiveProduct}></Header>
            <SideBar {...this.props} product={this.props.activeProduct} setActiveProduct={this.props.setActiveProduct}></SideBar>
            <Switch>
                <Route
                    exact
                    path="/"
                    key={1}
                    element={<Main {...this.props}></Main>}
                />
                <Route
                    exact
                    key={2}
                    path="/transactions"
                    element={<Transaction {...this.props}></Transaction>}
                />
                <Route
                    exact
                    key={3}
                    path="/profile"
                    element={<Profile {...this.props}></Profile>}
                />
                <Route
                    exact
                    key={4}
                    path="/users"
                    element={<Users {...this.props}></Users>}
                />
                <Route
                    exact
                    key={5}
                    path="/fund"
                    element={<Fund {...this.props}></Fund>}
                />
                <Route
                    exact
                    key={6}
                    path="/support"
                    element={<Support {...this.props}></Support>}
                />
                <Route
                    exact
                    key={7}
                    path="/wallet-history"
                    element={<WalletHistory {...this.props}></WalletHistory>}
                />
                <Route
                    exact
                    key={8}
                    path="/transfer-fund"
                    element={<TransferFund {...this.props}></TransferFund>}
                />
                <Route
                    exact
                    key={8}
                    path="/fund-wallet-req"
                    element={<FundWalletReq {...this.props}></FundWalletReq>}
                />
                <Route
                    exact
                    key={12}
                    path="/create-users"
                    element={<CreateUser {...this.props}></CreateUser>}
                />
                <Route
                    exact
                    key={13}
                    path="/system-config"
                    element={<Compliance walletBalance={this.state.walletBalance} {...this.props} />}
                />
                <Route
                    exact
                    key={63}
                    path="/app-users"
                    element={<AppUsers {...this.props}></AppUsers>}
                />
                <Route
                    exact
                    key={64}
                    path="/manage-commission"
                    element={<SetDiscount {...this.props}></SetDiscount>}
                />
                <Route
                    exact
                    key={25}
                    path="/set-threshold"
                    element={<SetThreshold {...this.props}></SetThreshold>}
                />
                <Route
                    exact
                    key={45}
                    path="/request-reseller"
                    element={<RequestReseller {...this.props}></RequestReseller>}
                />
            </Switch>
        </div>);
    }


}

export default Index;
