
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "../../unified/webbase";
import axios from "axios";

// class MakeFund extends React.Component {
//   constructor(props) {
//     super(props);
//     console.log(props);
//     this.state = {
//       walletBalance: cookie.load("walletBalance"),
//       history: props.history,
//       token: cookie.load("token"),
//       amount: "",
//       accountName: "",
//       naration: "",
//       selectedType: "BANK_PAYMENT",
//       refID: "",
//       banks: [],
//       business: {},
//       authCode: "",
//     };
//     this.onSubmit = this.onSubmit.bind(this);
//   }

//   onSubmit = (event) => {
//     event.preventDefault();
//     const id = toast.loading("Please wait...");
//     console.log(this.state.token);

//     //axios post to sign in
//     axios
//       .post(
//         WEB_BASE_URL + "/onboarding/fund-wallet",
//         {
//           "userId": this.state.accountName,
//           "amount": this.state.amount,
//           "referenceNumber": this.state.refID,
//           "remarks": this.state.naration,
//           "transferMode": "BANK_PAYMENT",
//           "product": "enterprise",
//           transactionPin: this.state.authCode,
//         },
//         {
//           headers: {
//             authorization: this.state.token,
//           },
//         }
//       )
//       .then((res) => {
//         let iflog = res.data.status == 2000 ? true : false;

//         if (iflog) {
//           toast.update(id, {
//             render: "Transaction Successful",
//             type: "success",
//             isLoading: false,
//           });
//           setTimeout(() => {
//             this.props.navigate(0);
//           }, 1000);
//         } else {
//           console.log(res);
//           toast.update(id, {
//             render: "" + res.data.description,
//             type: "error",
//             isLoading: false,
//           });
//           setTimeout(() => {
//             toast.dismiss(id);
//           }, 2000);
//         }
//       })
//       .catch((err) => {
//         console.log({
//           amount: this.state.amount,
//           accountName: this.state.accountName,
//           transferMode: this.state.selectedType,
//           referenceId: this.state.refID,
//           transactionPin: this.state.authCode,
//         });
//         toast.update(id, {
//           render: "Unable to make fund wallet request",
//           type: "error",
//           isLoading: false,
//         });
//         setTimeout(() => {
//           toast.dismiss(id);
//         }, 2000);
//       });
//   };

//   componentDidMount() {

//   }

//   render() {
//     function formatNumber(num) {
//       return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
//     }
//     return (
//       <main className="page-content">
//         <ToastContainer
//           position="top-center"
//           autoClose={3000}
//           hideProgressBar={false}
//           newestOnTop={false}
//           closeOnClick
//           rtl={false}
//           pauseOnFocusLoss
//           draggable
//           pauseOnHover
//         />
//         <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
//           <div className="ps-3">
//             <nav aria-label="breadcrumb">
//               <ol className="breadcrumb mb-0 p-0">
//                 <li className="breadcrumb-item">
//                   <a href="javascript:;">
//                     <i className="bx bx-home-alt"></i>
//                   </a>
//                 </li>
//                 <li className="breadcrumb-item active" aria-current="page">
//                   Fund Wallet
//                 </li>
//               </ol>
//             </nav>
//           </div>
//           <div className="ms-auto">
//             <div className="btn-group">

//             </div>
//           </div>
//         </div>

//         <div className="">
//           <div className="">
//             <div className="container py-2">

//               <div className="row">
//                 <div className="col py-2">
//                   <div className="card border-primary shadow radius-15">
//                     <div className="card-body">
//                       <h4 className="card-title text-primary">
//                         Fill Details for Funding
//                       </h4>
//                       <p className="card-text mt-3">
//                         <form
//                           className="row g-3"
//                           onSubmit={(event) => {
//                             this.onSubmit(event);
//                           }}
//                         >

//                           <div className="col-6">
//                             <label className="form-label">
//                               Amount (in Naira)
//                             </label>
//                             <input
//                               onChange={(event) => {
//                                 this.setState({
//                                   amount: event.target.value.replaceAll(
//                                     ",",
//                                     ""
//                                   ),
//                                 });
//                               }}
//                               className="form-control"
//                               value={formatNumber(this.state.amount)}
//                               required={true}
//                             />
//                           </div>

//                           <div className="col-6">
//                             <label className="form-label">Narration</label>
//                             <input
//                               onChange={(event) => {
//                                 this.setState({
//                                   naration: event.target.value,
//                                 });
//                               }}
//                               type="text"
//                               className="form-control"
//                               value={this.state.naration}
//                               required={true}
//                             />
//                           </div>
//                           <div className="col-6">
//                             <label className="form-label">
//                               Authenticator Code
//                             </label>
//                             <input
//                               onChange={(event) => {
//                                 this.setState({
//                                   authCode: event.target.value,
//                                 });
//                               }}
//                               type="text"
//                               className="form-control"
//                               value={this.state.authCode}
//                             />
//                           </div>

//                           <div className="col-4 ">
//                             <label className="form-label"> &nbsp; </label>
//                             <button
//                               className="btn btn-primary form-control"
//                               type="submit"
//                             >
//                               Submit For Confirmation
//                             </button>
//                           </div>
//                         </form>
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>


//             </div>

//           </div>
//         </div>
//       </main>
//     );
//   }
// }

// export default MakeFund;


import React, { useEffect, useState } from 'react'
import VirtualAccountModal from "./virtualAccountModal";
import PaystackModal from "./paystackModal";

function MakeFund() {
  const [userWallet, setUserWallet] = useState('0')
  const [vasWallet, setVasWallet] = useState('0')
  const [walletHistory, setWalletHistory] = useState([])
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  const [open1, setOpen1] = useState(false)
  const handleClose1 = () => setOpen1(false)
  const token = cookie.load("token")
  const bvnStatus = cookie.load("bvnStatus")
  useEffect(() =>{
    axios
    .get(WEB_BASE_URL + "/onboarding/account-balance", {
        headers: {
            authorization: token,
        },
    })
    .then((res) => {
      if(res.data.data != null){
        setUserWallet(res.data.data.entBalance)
        setVasWallet(res.data.data.unicardBalance)
      }
        
    })
    .catch((err) => { console.log(WEB_BASE_URL + " " + err); })
  }, [token])

  useEffect(() =>{
   
  
      axios
        .get(WEB_BASE_URL + "/logical/wallet-History", {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.data !== null) {
            
            setWalletHistory(res.data.data.reverse())
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(token);
        });
   
  }, [token])

  return (
    <div>
    <VirtualAccountModal
      open={open}
      handleClose={handleClose}
    />
    <PaystackModal
      open={open1}
      handleClose={handleClose1}
    />
   <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Fund Wallet
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">

            </div>
          </div>
        </div>

        <div className="">
          <div className="">
            <div className="container row g-3 mb-3 py-2">
              <div className=" row">
              <div className=" card col  p-4"
              style={{
                        backgroundColor: "#D3EFF9",
                        borderRadius:  "16px",
                        margin:"10px"
                      }}   
              >
              <div class="row g-2">
              <div class="col-12">
              <h5 className=" mb-3">NGN {userWallet}</h5>
                  <p>VTU Wallet balance</p>
              </div>
           
             
            </div>
                
              </div>
              <div className=" card col  p-4"
              style={{
                        backgroundColor: "#D3EFF9",
                        borderRadius:  "16px",
                        margin:"10px"
                      }}   
              >
              <div class="row g-2">
              <div class="col-12">
              <h5 className=" mb-3">NGN {vasWallet}</h5>
                  <p>VAS Wallet balance</p>
              </div>
             
             
            </div>
                
              </div>
              <div className=" card col  p-4"
              style={{
                        backgroundColor: "#D3EFF9",
                        borderRadius:  "16px",
                        margin:"10px"
                      }}   
              >
              <div class="row g-2">
              {/* <div class="col-8">
              <h5 className=" mb-3">NGN 1,550.56</h5>
                  <p>Wallet balance</p>
              </div> */}
              <div className="col-2">

              </div>
              <div className="col-10 mx-auto">
              <button
                    className="btn   d-flex"
                    onClick={() => setOpen(true)}
                    type="button"
                      style={{
                        backgroundColor: "#263B95",
                        color:"white",
                        fontSize:'14px',
                        textAlign:'center'
                      }}               
                        >
                       {bvnStatus ?"Virtual card funding " :"Set virtual account"} 
                      </button>
              </div>
              {/* <div class="col-5 mx-auto">
                 <button
                    className="btn   d-flex"
                    onClick={() => setOpen1(true)}
                    type="button"
                      style={{
                        backgroundColor: "#263B95",
                        color:"white",
                        fontSize:'14px',
                        textAlign:'center'
                      }}               
                        >
                        Paystack funding    
                      </button>
              </div> */}
             
            </div>
                
              </div>
              </div>
            </div>

            <div>
              <h4>Recent wallet history</h4>
              <div class="card-body">
            <div class="table-responsive">
              <table
                id="example"
                class="table table-striped table-bordered"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Reference ID</th>
                    <th>Username</th>
                    <th>Transaction Type</th>
                    <th>Transaction date</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {walletHistory.map((item, index) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>
                        <i
                          className="bi bi-arrow-down"
                          style={{ color: "red", marginRight: "5px" }}
                        ></i>
                        {item.username}
                      </td>

                      <td>Credit</td>
                      <td>{item.createdDate.split("T")[0]}</td>
                      <td>&#8358;{item.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default MakeFund