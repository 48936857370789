import  React, {useEffect, useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelIcon from '@mui/icons-material/Cancel';

import axios from 'axios';
import cookie from "react-cookies";
import DatePicker from "react-datepicker";
import { MOBILE_WEB_BASE_URL } from '../../unified/webbase';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  boxShadow: 24,
};

export default function PromoDiscount({open, handleClose}) {
     
     
    const [value, setValue] = useState('')
    const token = cookie.load("token")
  
    const [dsp, setDsp] = useState(0)
    const [promDsp, setPromDsp] = useState(0)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    const setThresholdValue = (e) =>{
        e.preventDefault()
        const date = new Date(endDate);

        // Format the Date object into "yyyy-MM-dd" format
        const endDate1 = date.toISOString().slice(0, 10);
        const date2 = new Date(startDate);

        // Format the Date object into "yyyy-MM-dd" format
        const startDate2 = date2.toISOString().slice(0, 10);
        const id = toast.loading("Please wait...");
    const valuess = {
        "dsp": dsp,
        "promDsp": promDsp,
        "promoEndDate": endDate1,
        "promoStartDate": startDate2
       
    }

    axios
    .post(MOBILE_WEB_BASE_URL + "/manage-discount", valuess,  {
      headers: {
        authorization: token,
      },
    })
    .then((res) => {
      if (res.data.status === "2000") {
        toast.update(id, {
          render: res.data.description,
          type: "success",
          isLoading: false,
        });
        setTimeout(() => {
        //   toast.dismiss(id);
          window.location.href ="/manage-commission";
        }, 2000);
      } else {
        console.log(res);
        toast.update(id, {
          render: res.data.description,
          type: "error",
          isLoading: false,
        });
      }
    })
    .catch((err) => {
      toast.update(id, {
        render: err,
        type: "error",
        isLoading: false,
      });
    });
}

useEffect(() =>{
    axios
    .get(MOBILE_WEB_BASE_URL + `/get-discount-user`, {
      headers: {
        authorization: token,
      },
    })
    .then((res) => {
      if(res.data.status === "2000" && res.data.data !== null ){
       setDsp(res.data.data.dsp)
       setPromDsp(res.data.data.promoDsp)
      }
    })
    .catch((err) => { console.log(MOBILE_WEB_BASE_URL + " " + err); })
},[token])


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg  p-4 pt-10'> 
          
            <div className=' '>
           
                <div className=' d-flex justify-content-end'>
                   
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-black cursor-pointer text-[38px]' fontSize="large"/>
                    
                    </div>
                </div>
                <h5 className=' px-4 '>Promo discount</h5>
                <form onSubmit={setThresholdValue} className="row">
       <div className="row">
       <div className="col-3"></div>
       <div className="col-12">
       
                <div className=' my-2'>
                <label className=' mb-2'>Discount selling price :</label>
                <input
                    className="form-control "
                    type="text"
                    required
                    value={dsp}
                    onChange={(e) => setDsp(e.target.value)}
                    placeholder="Set Dsp"
                  />
                </div>
                <div className=' my-2'>
                <label className=' mb-2'>Promo Discount selling price :</label>
                <input
                    className="form-control "
                    type="text"
                    required
                    value={promDsp}
                    onChange={(e) => setPromDsp(e.target.value)}
                    placeholder="Set Promo Dsp"
                  />
                </div>
                <label className="form-label">Start Date:</label>
                  <DatePicker
                    selected={startDate}
                    onSelect={(date) => setStartDate(date)}
                    onChange={(date) => setStartDate(date)}
                  />
                  <div>
                  <label className="form-label">End Date:</label>
                  <DatePicker
                    selected={endDate}
                    onSelect={(date) => setEndDate(date)}
                    onChange={(date) => setEndDate(date)}
                  />
                  </div>
                        </div>
                        <div className="col-3"></div>
                        </div>
                        <div className=' row'>
                        <div className="col-8"></div>
                        <div className="col-3 my-3">
                        <div className="ms-auto">
                                <div className="btn-group">
                                <button type="submit" 
                                onClick={setThresholdValue}
                                className="btn btn-primary">
                                    Submit
                                </button>
                                </div>
                            </div>
                        </div>
                        </div>
                        </form>
               
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}