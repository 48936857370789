import React from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import cookie from "react-cookies";
import Select from "react-select";
import { WEB_BASE_URL } from "../../unified/webbase";
import AuthModal from "./AuthModal";
class PrintUnits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      networkOptions: [
        { value: "MTN", label: "MTN" },
        { value: "AIRTEL", label: "AIRTEL" },
        { value: "GLO", label: "GLO" },
        { value: "9MOBILE", label: "9MOBILE" },
      ],

      token: cookie.load("token"),
      description: "",
      units: "",
      orderID: "",
      Pin: "",
    };
    this.onSave = this.onSave.bind(this);
    this.setDescription = this.setDescription.bind(this);
    this.setUnits = this.setUnits.bind(this);
    this.setorderID = this.setorderID.bind(this);
    this.setPin = this.setPin.bind(this);
  }

  onSave(event) {
    event.preventDefault();
    const id = toast.loading("Please wait...");

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;

    const user = {
      description: this.state.description,
      transactionPin: this.state.transactionPin,
      unit: this.state.units,
      orderID: this.state.orderID,
    };
    console.log(this.state.token);
    axios
      .post(WEB_BASE_URL + "/api/v2/u1/get-pin", user, {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000") {
          toast.update(id, {
            render: "Pin Generated successfully",
            type: "success",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(id);
            this.props.history.push("/vouchers");
          }, 2000);
        } else {
          console.log(res);
          toast.update(id, {
            render: "Generation failed",
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: err,
          type: "error",
          isLoading: false,
        });
      });
  }

  //write setter function
  setDescription(description) {
    this.setState({
      description: description.target.value,
    });
  }

  setUnits(units) {
    this.setState({
      units: units.target.value,
    });
  }

  setorderID(orderID) {
    this.setState({
      orderID: orderID.target.value,
    });
  }

  setPin(event) {
    this.setState({
      Pin: event.target.value,
    });
  }

  render() {
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.selectProps.menuColor,
        padding: 20,
      }),

      control: (provided, { selectProps: { width } }) => ({
        ...provided,
        borderRadius: "25px",
        padding: "2px 4px",
        width: width,
        minWidth: "310px",
      }),
    };
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Print Order
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
              >
                Wallet Balance : &#8358; {this.props.walletBalance}
              </button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <form
              autocomplete="off"
              class="row g-3"
              onSubmit={(event) => {
                this.onSave(event);
              }}
            >
              <div class="col-6">
                <label class="form-label">Voucher Description</label>
                <input
                  type="text"
                  name="description"
                  class="form-control"
                  value={this.state.description}
                  onChange={(event) => this.setDescription(event)}
                  required
                />
              </div>
              <div class="col-6">
                <label class="form-label">Order ID</label>
                <input
                  type="text"
                  name="description"
                  class="form-control"
                  value={this.state.orderID}
                  onChange={(event) => this.setDescription(event)}
                  required
                />
              </div>
              <div class="col-6">
                <label class="form-label">Units</label>
                <input
                  type="number"
                  name="units"
                  class="form-control"
                  role="voucher"
                  value={this.state.units}
                  onChange={(event) => this.setUnits(event)}
                  required
                />
              </div>

              <div class="text-start">
                <input
                  type="button"
                  class="btn btn-primary px-4"
                  value="Print Order"
                  data-bs-toggle="modal"
                  data-bs-target="#AuthModal"
                />
              </div>
              <AuthModal
                title="Enter Authenticator Code"
                id="AuthModal"
                onSubmit={this.onSubmit}
                formPin={this.state.Pin}
                setPin={this.setPin}
              ></AuthModal>
            </form>
          </div>
        </div>
      </main>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default PrintUnits;
