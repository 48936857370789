import axios from "axios";
import React, { useState, useEffect } from "react";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import { SME_WEB_BASE_URL, WEB_BASE_URL } from "../../unified/webbase";

const CreateBucket = (props) => {
  const [walletBalance, setWalletBalance] = useState(cookie.load("walletBalance"));
  const [token, setToken] = useState(cookie.load("token"));
//   const [dealerId, setDealerId] = useState("");
//   const [userRole, setUserRole] = useState(cookie.load("userRole"));
//   const [userGroup, setUserGroup] = useState(cookie.load("userGroup"));
  const [bucketId, setBucketId] = useState("");
  const [bucketName, setBucketName] = useState("");
  const [sponsorId, setSponsorId] = useState("");
  const [volume, setVolue] = useState("");
  

  useEffect(() => {
   

  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

 

  
 

  const createBucket = (event) => {
    const id = toast.loading("Please wait...");
    event.preventDefault();


      const user = {
       "apiKey": "goldenrule:1WL7AoemJcAPKNSdGjZcNLREmoFMABo78n_C4931JVYlh8JnQY",
        "bucketId": bucketId,
        "bucketName": bucketName,
        "sponsorId": sponsorId,
        "volume": volume
      
      };

      axios
        .post(SME_WEB_BASE_URL + "/glowsme/create-bucket", user, {
          headers: { authorization: token },
        })
        .then((res) => {
          if (res.data.status === "2000") {
            toast.update(id, {
                render: res.data.description,
                type: "success",
                isLoading: false,
              });
              setTimeout(() => {
                window.location.reload()
              }, 1000);
          
          } else {
            toast.update(id, {
              render: "" + res.data.description,
              type: "error",
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          toast.update(id, {
            render: "" + err,
            type: "error",
            isLoading: false,
          });
        });
    } 
  

  return (
    <main className="page-content">
      <ToastContainer />
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt"></i>
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Create SME Bucket
              </li>
            </ol>
          </nav>
        </div>
        <div className="ms-auto">
          <div className="btn-group">
            <button type="button" className="btn btn-primary">
              Wallet Balance: &#8358;{walletBalance}
            </button>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <form className="row" onSubmit={createBucket}>
            <div className="row">
            <div className="col-6">
                <label className="form-label">Bucket name</label>
                <input
                  type="text"
                  onChange={(e) => setBucketName(e.target.value)}
                  value={bucketName}
                  className="form-control"
                  required
                />
              </div>
              <div className="col-6">
                <label className="form-label">Bucket Id</label>
                <input
                  type="text"
                  onChange={(e) => setBucketId(e.target.value)}
                  value={bucketId}
                  className="form-control"
                  required
                />
              </div>
              
            </div>
            <div className="row my-4">
              <div className="col-6">
                <label className="form-label">Sponsor Id</label>
                <input
                  type="text"
                  onChange={(e) => setSponsorId(e.target.value)}
                  value={sponsorId}
                  className="form-control"
                  required
                />
              </div>
              <div className="col-6">
                <label className="form-label">Volumme</label>
                <input
                  type="text"
                  onChange={(e) => setVolue(e.target.value)}
                  value={volume}
                  className="form-control"
                  required
                />
              </div>
            </div>

            <div className="col-4">
              <button type="submit" style={{ marginTop: 2 }} className="btn btn-primary d-flex">
                <div>
                  <i className="bi bi-cloud-arrow-down-fill"></i>
                </div>
                <div className="mx-3">Create Bucket</div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default CreateBucket;
