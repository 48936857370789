import React from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "./webbase";
class GenerateVoucher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: cookie.load("token"),
      walletBalance: cookie.load("walletBalance"),
      description: "",
      units: "",
      unitAmount: "",
      transactionPin: "",
    };
    this.onSave = this.onSave.bind(this);
    this.setDescription = this.setDescription.bind(this);
    this.setUnits = this.setUnits.bind(this);
    this.setUnitAmount = this.setUnitAmount.bind(this);
    this.setTransactionPin = this.setTransactionPin.bind(this);
  }

  onSave(event) {
    event.preventDefault();
    const id = toast.loading("Please wait...");

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;

    const user = {
      nbf: today,
      description: this.state.description,
      transactionPin: this.state.transactionPin,
      unit: this.state.units,
      value: this.state.unitAmount,
    };
    console.log(this.state.token);
    axios
      .post(WEB_BASE_URL + "/merchant/generate-pin", user, {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000") {
          toast.update(id, {
            render: res.data.description
            ,
            type: "success",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(id);
            this.props.history.push("/vouchers");
          }, 2000);
        } else {
          console.log(res);
          toast.update(id, {
            render: res.data.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: err,
          type: "error",
          isLoading: false,
        });
      });
  }

  //write setter function
  setDescription(description) {
    this.setState({
      description: description.target.value,
    });
  }

  setUnits(units) {
    this.setState({
      units: units.target.value,
    });
  }

  setUnitAmount(unitAmount) {
    this.setState({
      unitAmount: unitAmount.target.value,
    });
  }

  setTransactionPin(transactionPin) {
    this.setState({
      transactionPin: transactionPin.target.value,
    });
  }
  handleUnitAmountChange = (event) => {
    this.setState({ unitAmount: event.target.value });
  };

  render() {
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Generate New Voucher
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
              >
                Wallet Balance : &#8358; {this.state.walletBalance}
              </button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <form
              autocomplete="off"
              class="row g-3"
              onSubmit={(event) => {
                this.onSave(event);
              }}
            >
              <div class="col-12">
                <label class="form-label">Voucher Description</label>
                <input
                  type="text"
                  name="description"
                  class="form-control"
                  value={this.state.description}
                  onChange={(event) => this.setDescription(event)}
                  required
                />
              </div>
              <div class="col-6">
                <label class="form-label">Units</label>
                <input
                  type="number"
                  name="units"
                  class="form-control"
                  role="voucher"
                  value={this.state.units}
                  onChange={(event) => this.setUnits(event)}
                  required
                />
              </div>
              <div class="col-6">
              <label className="form-label">Unit Amount</label>
              <select
                className="form-select"
                name="unitAmount"
                value={this.state.unitAmount}
                onChange={this.handleUnitAmountChange}
                required
              >
                <option value="">Select Unit Amount</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              </div>
              <div class="col-6">
                <label class="form-label">Enter your 2fa pin:</label>
                <input
                  type="password"
                  name="transactionPin"
                  autocomplete="new-password"
                  class="form-control"
                  value={this.state.transactionPin}
                  onChange={(event) => this.setTransactionPin(event)}
                  required
                />
              </div>
              <div class="text-start">
                <input
                  type="submit"
                  class="btn btn-primary px-4"
                  value="Generate New Voucher"
                />
              </div>
            </form>
          </div>
        </div>
      </main>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default GenerateVoucher;
