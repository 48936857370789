import React from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WEB_BASE_URL } from "./webbase";
import queryString from "query-string";

class DirectSignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dealerId: "TOPUPBOX",
      roleOptions: [{ value: "DEALER", label: "DEALER" }, { value: "MERCHANT", label: "MERCHANT" }],
      stateOptions: [
        //states in Nigeria
        { label: "FCT- Abuja", value: "FCT- Abuja" },
        { label: "Abia", value: "Abia" },
        { label: "Adamawa", value: "Adamawa" },
        { label: "Akwa Ibom", value: "Akwa Ibom" },
        { label: "Anambra", value: "Anambra" },
        { label: "Bauchi", value: "Bauchi" },
        { label: "Bayelsa", value: "Bayelsa" },
        { label: "Benue", value: "Benue" },
        { label: "Borno", value: "Borno" },
        { label: "Cross River", value: "Cross River" },
        { label: "Delta", value: "Delta" },
        { label: "Ebonyi", value: "Ebonyi" },
        { label: "Edo", value: "Edo" },
        { label: "Ekiti", value: "Ekiti" },
        { label: "Enugu", value: "Enugu" },
        { label: "Gombe", value: "Gombe" },
        { label: "Imo", value: "Imo" },
        { label: "Jigawa", value: "Jigawa" },
        { label: "Kaduna", value: "Kaduna" },
        { label: "Kano", value: "Kano" },
        { label: "Katsina", value: "Katsina" },
        { label: "Kebbi", value: "Kebbi" },
        { label: "Kogi", value: "Kogi" },
        { label: "Kwara", value: "Kwara" },
        { label: "Lagos", value: "Lagos" },
        { label: "Nasarawa", value: "Nasarawa" },
        { label: "Niger", value: "Niger" },
        { label: "Ogun", value: "Ogun" },
        { label: "Ondo", value: "Ondo" },
        { label: "Osun", value: "Osun" },
        { label: "Oyo", value: "Oyo" },
        { label: "Plateau", value: "Plateau" },
        { label: "Rivers", value: "Rivers" },
        { label: "Sokoto", value: "Sokoto" },
        { label: "Taraba", value: "Taraba" },
        { label: "Yobe", value: "Yobe" },
        { label: "Zamfara", value: "Zamfara" },
      ],
      history: props.history,
      // input variables
      formEmail: "",
      dealer: "",
      formRole: "DEALER",
      formPassword: "",
      formConfirmPassword: "",
      formFirstName: "",
      formLastName: "",
      formPhone: "",
      formState: "",
      formLocation: "",
      formDealerName: "",
    };

    //bind all functions
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeFirst = this.onChangeFirst.bind(this);
    this.onChangeLast = this.onChangeLast.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeDealerName = this.onChangeDealerName.bind(this);
    this.onChangeDealerName = this.onChangeDealerName.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getStateOptions = this.getStateOptions.bind(this);
    this.onChangeDealer = this.onChangeDealer.bind(this);
    this.myRoleHandler = this.myRoleHandler.bind(this);
  }


  //Onsubmit
  onSubmit = (e) => {
    e.preventDefault();
    const user = this.state.formFirstName.split(" ");
    const id = toast.loading("Please wait...");
    console.log({
      "businessAddress": this.state.formLocation,
      "businessAlias": this.state.formDealerName,
      "businessEmail": this.state.formEmail,
      "businessName": this.state.formDealerName,
      "businessPhoneNumber": this.state.formPhone,
      "categoryId": 0,
      "emailAddress": this.state.formEmail,
      "firstName": user[0],
      "lastName": user[1],
      "password": this.state.formPassword,
      "phoneNumber": this.state.formPhone,
      "states": this.state.formState,
      "username": this.state.formDealerName,
    })
    if (user[1] === undefined || user[0] === undefined) {
      toast.update(id, {
        render: "First name and last name are required",
        type: "error",
        isLoading: false,
      });
      return;
    }
    axios
      .post(WEB_BASE_URL + "/onboarding/merchant-signup", {
        "businessAddress": this.state.formLocation,
        "businessAlias": this.state.formDealerName,
        "businessEmail": this.state.formEmail,
        "businessName": this.state.formDealerName,
        "businessPhoneNumber": this.state.formPhone,
        "categoryId": 0,
        "emailAddress": this.state.formEmail,
        "firstName": user[0],
        "lastName": user[1],
        "password": this.state.formPassword,
        "phoneNumber": this.state.formPhone,
        "states": this.state.formState,
        "username": this.state.formDealerName,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status == "2000") {
          toast.update(id, {
            render: "Verification Email Sent",
            type: "success",
            isLoading: false,
          });
          this.props.navigate("/verifyEmail", {
            state: {
              requestId: res.data.data.requestId,
              userEmailAddress: this.state.formEmail,
            },
          });
        } else {
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: "You are unable to sign up at this moment.",
          type: "error",
          isLoading: false,
        });
      }).finally(() => {
        setTimeout(() => {
          toast.dismiss(id);
        }, 2000);
      });
  };

  getStateOptions = () => {
    axios
      .get(WEB_BASE_URL + "/onboarding/get-states")
      .then((res) => {
        console.log(res.data.data);
        let stateOptions = res.data.data.map((state) => {
          return { value: state.area, label: state.area, dealer: state.dealer };
        });
        this.setState({ stateOptions: stateOptions });
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  };
  //set state for formEmail
  onChangeEmail = (e) => {
    this.setState({
      formEmail: e.target.value,
    });
  };

  //set state for formRole
  onChangeRole = (e) => {
    this.setState({
      formRole: e.target.value,
    });
  };

  onChangeState = (e) => {
    this.setState({
      formState: e.value,
    });
  };

  onChangeDealer = (e) => {
    this.setState({
      formDealerName: e.value,
    });
  };

  //set state for formPassword
  onChangePassword = (e) => {
    this.setState({
      formPassword: e.target.value,
    });
  };

  // set state for formFirst
  onChangeFirst = (e) => {
    this.setState({
      formFirstName: e.target.value,
    });
  };

  // set state for formLast
  onChangeLast = (e) => {
    this.setState({
      formLastName: e.target.value,
    });
  };

  myRoleHandler = (e) => {
    alert(e.target.value)
    this.setState({
      formRole: e.target.value,
    });
  }
  // set state for formPhone
  onChangePhone = (e) => {
    this.setState({
      formPhone: e.target.value,
    });
  };

  //set state for formLocation
  onChangeLocation = (e) => {
    this.setState({
      formLocation: e.target.value,
    });
  };

  //set state for formDealerName
  onChangeDealerName = (e) => {
    this.setState({
      formDealerName: e.target.value,
    });
  };

  componentDidMount() {

    // this.getStateOptions();
  }

  render() {
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.selectProps.menuColor,
        padding: 20,
      }),

      control: (provided, { selectProps: { width } }) => ({
        ...provided,
        borderRadius: "25px",
        padding: "2px 4px",
        width: width,
        minWidth: "310px",
      }),
    };
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <main className="authentication-content">
          <div className="container-fluid">
            <div className="authentication-card">
              <div className="card shadow rounded-0 overflow-hidden">
                <div className="row g-0">
                  <div className="col-lg-6 bg-login d-flex align-items-center justify-content-center">
                    <img
                      src="assets/images/error/login-img.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="card-body p-4 p-sm-5">
                      <h5 className="card-title">Topupbox Dashboard - Sign Up</h5>
                      <p className="card-text mb-2">Register to get started!</p>

                      <form
                        className="form-body"
                        onSubmit={(event) => {
                          event.preventDefault();
                          this.onSubmit(event);
                        }}
                      >
                        <div className="row g-3">
                          <div className="col-12 ">
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-person-circle"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control radius-30 ps-5"
                                id="inputName"
                                placeholder="Enter First Name and Last Name"
                                onChange={this.onChangeFirst}
                                value={this.state.formFirstName}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-briefcase-fill"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control radius-30 ps-5"
                                id="inputName"
                                placeholder="Enter Business name"
                                onChange={this.onChangeDealerName}
                                value={this.state.formDealerName}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-person-circle"></i>
                              </div>
                              <Select
                                onChange={this.onChangeState}
                                options={this.state.stateOptions}
                                styles={customStyles}
                                width="100%"
                                placeholder="State..."
                                borderRadius="17px"
                              />
                            </div>
                          </div>

                          <div
                            className="col-12"
                            styles={{ marginTop: 10 + "px" }}
                          >
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-telephone-fill"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control radius-30 ps-5"
                                id="inputName"
                                placeholder="Phone Number"
                                onChange={this.onChangePhone}
                                value={this.state.formPhone}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-envelope-fill"></i>
                              </div>
                              <input
                                type="email"
                                className="form-control radius-30 ps-5"
                                id="inputEmailAddress"
                                placeholder="Email Address"
                                onChange={this.onChangeEmail}
                                value={this.state.formEmail}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-geo-alt-fill"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control radius-30 ps-5"
                                id="inputEmailAddress"
                                placeholder="Contact Address"
                                onChange={this.onChangeLocation}
                                value={this.state.formLocation}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-lock-fill"></i>
                              </div>
                              <input
                                type="password"
                                className="form-control radius-30 ps-5"
                                id="inputChoosePassword"
                                placeholder="Enter Password"
                                onChange={this.onChangePassword}
                                value={this.state.formPassword}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                              />
                              <label
                                className="form-check-label"
                                for="flexSwitchCheckChecked"
                              >
                                I Agree to the{" "}
                                <a href="/">Terms & Conditions</a>
                              </label>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="d-grid">
                              <button
                                type="submit"
                                className="btn btn-primary radius-30"
                              >
                                Get Started
                              </button>
                            </div>
                          </div>
                          <div className="col-12 text-center">
                            <p className="mb-0">
                              Already have an account?{" "}
                              <a href="/signIn">Sign in here</a>
                            </p>
                            <div class="breadcrumb-item active mt-2">
                              Copyright © 2022 TopUpBox. All rights reserved.
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default DirectSignUp;
