import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import AuthModal from "../AuthModal";
import NetworkSwitch from "../microcomponents/networkSwitch";
import { WEB_BASE_URL } from "../../../unified/webbase";
import Security from "./security";

class Network extends React.Component {
  constructor() {
    super();
    this.state = {
      privateKey: "",
      transactionPin: "",
      token: cookie.load("token"),
      networks: [],
      networklist: [
        {
          name: "MTN",
          MDP: 0,
          DSP: 0,
          status: "not active",
        },
        {
          name: "AIRTEL",
          MDP: 0,
          DSP: 0,
          status: "not active",
        },

        {
          name: "GLO",
          MDP: 0,
          DSP: 0,
          status: "not active",
        },

        {
          name: "NINEMOBILE",
          MDP: 0,
          DSP: 0,
          status: "not active",
        },
      ],
    };

    this.getNetworks = this.getNetworks.bind(this);
  }

  switchNetworkStatus(networkId, status) {
    const networks = [].concat(this.state.networks);
    networks[networkId].status = status === "active" ? "not active" : "active";
    this.setState({
      networks: networks,
    });
  }

  getNetworks() {
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/get-network", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        let networks = res.data.data.map((network) => {
          return {
            name: network.network,
            status: network.status,
            DSP: network.dsp,
            MDP: network.mdp,
          };
        });

        //find netwoklist that dont exist in the networks
        const networklist = this.state.networklist.filter((network) => {
          return networks.find((n) => n.name === network.name) === undefined;
        });

        networklist.forEach((network) => {
          networks.push(network);
        });
        this.setState({ networks: networks });
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  onSave(event) {
    event.preventDefault();

    const id = toast.loading("Please wait...");

    //get array of networks where status is active
    const networks = this.state.networks.filter((network) => {
      return network.status === "active";
    });
    networks.forEach((network) => {
      //check if values are not equals to 0
      if (network.MDP !== 0 || network.DSP !== 0) {
        axios
          .post(
            WEB_BASE_URL + "/api/v2/w2/set-discounted-price",
            {
              createdDate: new Date(),
              dsp: network.DSP,
              network: network.name,
              mdp: network.MDP,
              transactionPin: this.state.transactionPin,
              privateKey: this.state.privateKey,
            },
            {
              headers: {
                authorization: this.state.token,
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === "2000") {
              toast.update(id, {
                render: "Discounted Price Set Successfully",
                type: "success",
                isLoading: false,
              });
            } else {
              toast.update(id, {
                render: "" + res.data.description,
                type: "error",
                isLoading: false,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            toast.update(id, {
              render: "" + err,
              type: "error",
              isLoading: false,
            });
          })
          .then(() => {
            toast.dismiss(id);
          });
      } else {
        alert(network.MDP + " " + network.DSP);
        toast.update(id, {
          render: "Please enter values",
          type: "error",
          isLoading: false,
        });
      }
    });
  }

  setTransactionPin(e) {
    this.setState({
      transactionPin: e.target.value,
    });
  }

  setPrivateKey(e) {
    this.setState({
      privateKey: e.target.value,
    });
  }

  handleChange(e, index, type) {
    const value = e.target.value;
    const networks = [].concat(this.state.networks);
    networks[index][type] = value;
    this.setState({
      networks: networks,
    });
    console.log(networks);
  }
  componentDidMount() {
    this.getNetworks();
  }

  render() {
    return (
      <form
        autocomplete="off"
        class="row g-3 my-3"
        onSubmit={(event) => {
          this.onSave(event);
        }}
      >
        <h4>Activate Your Networks</h4>
        {this.state.networks.map((network, index) => (
          <NetworkSwitch
            id={index}
            key={index}
            name={network.name}
            mdp={network.MDP}
            dsp={network.DSP}
            status={network.status}
            handleChange={this.handleChange.bind(this)}
            onSwitch={this.switchNetworkStatus.bind(this)}
          ></NetworkSwitch>
        ))}

        <Security
          privateKey={this.state.privateKey}
          setPrivateKey={this.setPrivateKey.bind(this)}
        ></Security>
        <div className="row my-2 px-3 gap-1">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#AuthModal"
            class="btn btn-primary  small px-4  col-md-2"
          >
            Submit
          </button>
        </div>
        <AuthModal
          title="Enter Authenticator Code"
          id="AuthModal"
          formPin={this.state.transactionPin}
          setPin={this.setTransactionPin.bind(this)}
          onSubmit={this.onSave.bind(this)}
        ></AuthModal>
      </form>
    );
  }
}

export default Network;
