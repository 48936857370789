let origin = window.origin;
console.log(origin);
var WEB_BASE_URL = "";
var LOGICAL_WEB_BASE_URL = "";
var VAS_WEB_BASE_URL = "";
var MOBILE_WEB_BASE_URL = "";
var SME_WEB_BASE_URL ="";
if (origin === "http://localhost:3001") {
  WEB_BASE_URL = origin + "/api";
  LOGICAL_WEB_BASE_URL = origin + "/logical";
  VAS_WEB_BASE_URL = origin + "/vas";
  MOBILE_WEB_BASE_URL = origin + "/mobile";
  SME_WEB_BASE_URL = origin + "/sme";
} else {
  WEB_BASE_URL = origin + "/api";
  LOGICAL_WEB_BASE_URL = origin + "/logical";
  VAS_WEB_BASE_URL = origin + "/vas";
  MOBILE_WEB_BASE_URL = origin + "/mobile";
  SME_WEB_BASE_URL = origin + "/sme";
}

module.exports = { WEB_BASE_URL, LOGICAL_WEB_BASE_URL, VAS_WEB_BASE_URL,MOBILE_WEB_BASE_URL,SME_WEB_BASE_URL };
