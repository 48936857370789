import axios from "axios";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import { MOBILE_WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";

class Transaction extends React.Component {
    constructor() {
        super();
        this.state = {
            token: cookie.load("token"),
            walletBalance: cookie.load("walletBalance"),
            selectedTable: "Voucher Transactions",
            startDate: new Date(),
            endDate: new Date(),
            vouchers: [],
            walletHistory: [],
        };
        //binding this to event-handler functions
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.getVouchers = this.getVouchers.bind(this);
    }
    setStartDate(date) {
        this.setState({
            startDate: date,
        });
    }

    setEndDate(date) {
        this.setState({
            endDate: date,
        });
    }

    getVouchers = () => {
        const today = new Date();
    // Get the components of today's date
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth() + 1; // Months are zero-indexed, so add 1
    const todayDate = today.getDate();

    // Formatted string of today's date (in yyyy-mm-dd format)
    const formattedToday = `${todayYear}-${todayMonth < 10 ? '0' : ''}${todayMonth}-${todayDate < 10 ? '0' : ''}${todayDate}`;

        const id = toast.loading("Please wait...");
        axios
            .get(MOBILE_WEB_BASE_URL + "topupmobile-custom-report?startDate=2024-01-01&endDate="+ formattedToday, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.status === "2000") {
                    console.log(res.data);
                
                    this.setState({ walletHistory:  res.data.data.reverse() });
                    toast.dismiss(id);
                    
                } else {
                    toast.update(id, {
                        render: res.data.description,
                        type: "error",
                        isLoading: false,
                    });
                }
            })
            .catch((err) => {
                toast.update(id, {
                    render: "" + err,
                    type: "error",
                    isLoading: false,
                });
            });
    };

    componentDidMount() {
        this.getVouchers();
    }
    render() {
        var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "NGN",
        });
        return (
            <main className="page-content">
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;">
                                        <i className="bx bx-home-alt"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Transaction
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary">
                                Wallet Balance: {formatter.format(this.state.walletBalance) }
                            </button>
                        </div>
                    </div>
                </div>

                {/* <div className="card">
                    <div className="card-body">
                        <form className="row">
                            <div className="row">
                                <div className="col-6">
                                    <label className="form-label">Transaction Date:</label>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onSelect={(date) => this.setStartDate(date)}
                                        onChange={(date) => this.setStartDate(date)}
                                    />
                                </div>
                                <div className="col-4">
                                    <label className="form-label">Transaction Status</label>
                                    <select
                                        className="form-select "
                                        aria-label="Default select example"
                                        value={this.props.formRole}
                                        onChange={(event) => this.props.onChangeRole(event)}
                                    >
                                        <option selected=""> Select Status</option>
                                        <option value="1">All</option>
                                        <option value="2">Used</option>
                                        <option value="3">Unused</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <button
                                        style={{ marginTop: 29 }}
                                        className="btn btn-primary d-flex"
                                    >
                                        <div>
                                            <i class="bi bi-cloud-arrow-down-fill"></i>
                                        </div>
                                        <div className="mx-3">Download Report</div>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> */}

                <div className="card">
                    <div className="card-header py-3">
                        <div className="row g-3">
                            {/* <div className="col-lg-3 col-6 col-md-3">
                                <select
                                    className="form-select"
                                    value={this.state.selectedTable}
                                    onChange={(event) => {
                                        this.setState({
                                            selectedTable: event.target.value,
                                        });
                                    }}
                                >
                                    <option value="Vouchers Transactions">
                                        Voucher Transactions
                                    </option>
                                    <option value="Wallet Transactions">
                                        Wallet Transactions
                                    </option>
                                </select>
                            </div> */}
                            <div className="col-lg-3 col-md-6 me-auto">
                                <div className="ms-auto position-relative">
                                    <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                        <i className="bi bi-search"></i>
                                    </div>
                                    <input
                                        className="form-control ps-5"
                                        type="text"
                                        placeholder="Search Payment"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table
                                id="example"
                                class="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Email Address</th>
                                        <th>Beneficiary Phone</th>
                                        <th>Customer Ref</th>
                                        <th>Refund Status</th>
                                        <th>Status</th>
                                        <th>Balance after</th>
                                        <th>Balance before</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.walletHistory.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.product}</td>
                                            <td>{item.emailAddress}</td>
                                            <td>{item.beneficiaryPhone}</td>
                                            <td>{item.customerRef}</td>
                                            <td>{item.refundStatus}</td>
                                            <td>{item.status}</td>
                                            <td>{formatter.format(item.amountAfter) }</td>
                                            <td>{ formatter.format(item.amountBefore)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <nav class="float-end mt-3">
                            <ul class="pagination">
                                <li class="page-item disabled">
                                    <a class="page-link" href="#">
                                        Previous
                                    </a>
                                </li>
                                <li class="page-item active">
                                    <a class="page-link" href="#">
                                        1
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">
                                        2
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">
                                        3
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">
                                        Next
                                    </a>
                                </li>
                            </ul>
                        </nav> */}
                    </div>
                </div>
            </main>
        );
    }
}

export default Transaction;
