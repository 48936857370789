import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import { WEB_BASE_URL } from "../../../unified/webbase";

class Personal extends React.Component {
  constructor() {
    super();
    this.state = {
      user: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailAddress: "",
      },
      token: cookie.load("token"),
    };
    this.getProfile = this.getProfile.bind(this);
  }

  getProfile() {
    //toast loading
    const id = toast.loading("Please wait...");

    axios
      .get(WEB_BASE_URL + "/onboarding/get-user", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.data !== null) {
          toast.update(id, {
            render: "Successfully fetched profile",
            type: "success",
            isLoading: false,
          });
          this.setState({
            user: res.data.data[0],
          });

          toast.dismiss(id);
        } else {
          toast.update(id, {
            render: "Failed to fetch profile",
            type: "error",
            isLoading: false,
          });
        }

      })
      .catch((err) => {
        console.log(err);
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
      })
      .then(() => {
        toast.dismiss(id);
      });
  }

  componentDidMount() {
    if (this._Mounted) {
    } else {
      this.getProfile();
      this._Mounted = true;
    }
  }

  render() {
    return (
      <div className="py-3">
        <form class="row g-3">
          <h4>Update Personal Information</h4>
          <div class="col-6">
            <label class="form-label">Full Name</label>
            <input
              type="text"
              class="form-control"
              value={this.state.user.firstName + " " + this.state.user.lastName}
              disabled
            />
          </div>
          <div class="col-6">
            <label class="form-label">Phone Number</label>
            <input
              type="text"
              class="form-control"
              value={this.state.user.phoneNumber}
              disabled
            />
          </div>
          <div class="col-6">
            <label class="form-label">Email address</label>
            <input
              type="text"
              class="form-control"
              value={this.state.user.emailAddress}
              disabled
            />
          </div>
          <div class="col-6">
            <label class="form-label">Contact address</label>
            <input
              type="text"
              class="form-control"
              value={this.state.user.contactAddress}
              disabled
            />
          </div>
        </form>
        <div className="row my-4 px-3">
          <button
            onClick={(e) => { }}
            type="button"
            class="btn btn-primary  small px-4 col-md-2"
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

export default Personal;
