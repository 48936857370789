import React from "react";

class AuthModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      someKey: "someValue",
    };
  }

  render() {
    return (
      <div
        className="modal fade"
        id={this.props.id}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.props.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row g-3">
                <div className="col-8">
                  <input
                    type="password"
                    className="form-control"
                    autoComplete="new-password"
                    value={this.props.formPin}
                    onChange={(event) => this.props.setPin(event)}
                  />
                </div>
                <div className="col-4">
                  <button
                    type="button"
                    onClick={(event) => {
                      console.log(this.props.requestId);
                      this.props.onSubmit(
                        this.props.requestId,
                      );
                    }}
                    className="btn btn-primary"
                  >
                    Authenticate
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default AuthModal;
