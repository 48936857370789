import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "../../unified/webbase";
import axios from "axios";

class Fund extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      walletBalance: cookie.load("walletBalance"),
      history: props.history,
      token: cookie.load("token"),
      amount: "",
      accountName: "",
      bankName: "",
      selectedType: "BANK_PAYMENT",
      refID: "",
      banks: [],
      business: {},
      authCode: "",
    };
    this.getBanks = this.getBanks.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getBusiness = this.getBusiness.bind(this);
  }
  getBanks() {
    axios
      .get(WEB_BASE_URL + "/onboarding/get-banks", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.data !== null) {
          this.setState({
            banks: res.data.data,
          });
        } else {
          toast.error("No Networks Found, Please Setup Config");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getBusiness() {
    axios
      .get(WEB_BASE_URL + "/onboarding/get-user", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res);
        this.setState({
          business: res.data.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => { });
  }

  onSubmit = (event) => {
    event.preventDefault();
    const id = toast.loading("Please wait...");
    console.log(this.state.token);

    //axios post to sign in
    axios
      .post(
        WEB_BASE_URL + "/onboarding/fund-wallet",
        {
          amount: this.state.amount,
          senderAccountName: this.state.accountName,
          reciepientAccountName: this.state.bankName,
          transferMode: this.state.selectedType,
          referenceId: this.state.refID,
          transactionPin: this.state.authCode,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        let iflog = res.data.status == 2000 ? true : false;

        if (iflog) {
          toast.update(id, {
            render: "Transaction Successful",
            type: "success",
            isLoading: false,
          });
          setTimeout(() => {
            this.props.navigate(0);
          }, 1000);
        } else {
          console.log(res);
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(id);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log({
          amount: this.state.amount,
          accountName: this.state.accountName,
          transferMode: this.state.selectedType,
          referenceId: this.state.refID,
          transactionPin: this.state.authCode,
        });
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
        setTimeout(() => {
          toast.dismiss(id);
        }, 2000);
      });
  };

  componentDidMount() {
    this.getBanks();
    this.getBusiness();
  }

  render() {
    function formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Fund Wallet
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button type="button" className="btn btn-primary">
                Wallet Balance: &#8358;{this.state.walletBalance}
              </button>
            </div>
          </div>
        </div>

        <div className="">
          <div className="">
            <div className="container py-2">
              <h2
                className="font-weight-light text-left text-muted"
                style={{ paddingLeft: "6.7%" }}
              >
                Fund Your Wallet
              </h2>

              <div className="row">
                <div className="col-auto text-center flex-column d-none d-sm-flex">
                  <div className="row h-50">
                    <div className="col">&nbsp;</div>
                    <div className="col">&nbsp;</div>
                  </div>
                  <h5 className="m-2">
                    <span className="badge rounded-pill bg-light border">
                      &nbsp;
                    </span>
                  </h5>
                  <div className="row h-50">
                    <div className="col border-end">&nbsp;</div>
                    <div className="col">&nbsp;</div>
                  </div>
                </div>

                <div className="col py-2">
                  <div className="card radius-15">
                    <div className="card-body">
                      <h4 className="card-title text-muted">
                        Step 1 Transfer to Bank Account
                      </h4>
                      <hr />
                      <p className="card-text">
                        Fund your TopUpBox wallet by transferring funds(Naira)
                        via local bank
                      </p>
                      {this.state.banks.map((bank, index) => (
                        <p className="card-text">
                          <i>{bank.accountNumber}</i> {bank.bankName}
                        </p>
                      ))}
                      <p className="card-text">
                        Account Name: <i>{this.state.business.bussinessName}</i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-auto text-center flex-column d-none d-sm-flex">
                  <div className="row h-50">
                    <div className="col border-end">&nbsp;</div>
                    <div className="col">&nbsp;</div>
                  </div>
                  <h5 className="m-2">
                    <span className="badge rounded-pill bg-primary">
                      &nbsp;
                    </span>
                  </h5>
                  <div className="row h-50">
                    <div className="col border-end">&nbsp;</div>
                    <div className="col">&nbsp;</div>
                  </div>
                </div>
                <div className="col py-2">
                  <div className="card border-primary shadow radius-15">
                    <div className="card-body">
                      <h4 className="card-title text-primary">
                        Step 2 Fill in Transfer Details
                      </h4>
                      <p className="card-text">
                        <form
                          className="row g-3"
                          onSubmit={(event) => {
                            this.onSubmit(event);
                          }}
                        >
                          <div className="col-6">
                            <label className="form-label">Transfer Mode</label>
                            <select
                              className="form-select"
                              value={this.state.selectedType}
                              onChange={(event) => {
                                this.setState({
                                  selectedType: event.target.value,
                                });
                              }}
                            >
                              <option value="BANK_PAYMENT">Bank Payment</option>
                              <option value="CREDIT">Credits</option>
                            </select>
                          </div>
                          <div className="col-6">
                            <label className="form-label">Bank Name</label>
                            <input
                              onChange={(event) => {
                                this.setState({
                                  bankName: event.target.value,
                                });
                              }}
                              type="text"
                              className="form-control"
                              value={this.state.bankName}
                              required={true}
                            />
                          </div>
                          <div className="col-6">
                            <label className="form-label">Account Name</label>
                            <input
                              onChange={(event) => {
                                this.setState({
                                  accountName: event.target.value,
                                });
                              }}
                              type="text"
                              className="form-control"
                              value={this.state.accountName}
                              required={true}
                            />
                          </div>
                          <div className="col-6">
                            <label className="form-label">
                              Amount (in Naira)
                            </label>
                            <input
                              onChange={(event) => {
                                this.setState({
                                  amount: event.target.value.replaceAll(
                                    ",",
                                    ""
                                  ),
                                });
                              }}
                              className="form-control"
                              value={formatNumber(this.state.amount)}
                              required={true}
                            />
                          </div>
                          <div className="col-6">
                            <label className="form-label">Reference ID</label>
                            <input
                              onChange={(event) => {
                                this.setState({
                                  refID: event.target.value,
                                });
                              }}
                              type="text"
                              className="form-control"
                              value={this.state.refID}
                            />
                          </div>
                          <div className="col-6">
                            <label className="form-label">
                              Authenticator Code
                            </label>
                            <input
                              onChange={(event) => {
                                this.setState({
                                  authCode: event.target.value,
                                });
                              }}
                              type="text"
                              className="form-control"
                              value={this.state.authCode}
                            />
                          </div>

                          <div className="col-4 ">
                            <label className="form-label"> &nbsp; </label>
                            <button
                              className="btn btn-primary form-control"
                              type="submit"
                            >
                              Submit For Confirmation
                            </button>
                          </div>
                        </form>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-auto text-center flex-column d-none d-sm-flex">
                  <div className="row h-50">
                    <div className="col border-end">&nbsp;</div>
                    <div className="col">&nbsp;</div>
                  </div>
                  <h5 className="m-2">
                    <span className="badge rounded-pill bg-light border">
                      &nbsp;
                    </span>
                  </h5>
                  <div className="row h-50">
                    <div className="col border-end">&nbsp;</div>
                    <div className="col">&nbsp;</div>
                  </div>
                </div>
                <div className="col py-2">
                  <div className="card radius-15">
                    <div className="card-body">
                      <h4 className="card-title">
                        Step 3 Wait For Confirmation
                      </h4>
                      <p>Wait For Confirmation Mail</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
          </div>
        </div>
      </main>
    );
  }
}

export default Fund;
