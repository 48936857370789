import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import { WEB_BASE_URL } from "../../unified/webbase";

class TransferModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: props.history,
      token: cookie.load("token"),
      transactionPin: "",
      description: "",
      amount: "",
    };
    //binding
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = (e) => {
    e.preventDefault();
    const id = toast.loading("Please wait...");
    axios
      .post(
        WEB_BASE_URL + "/onboarding/transfer-fund",
        {
          amountfunded: parseInt(this.state.amount),
          dealerId: "string",
          recieverEmailAddress: this.props.emailAddress,
          transactionPin: this.state.transactionPin,
          description: this.state.description,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == 2000) {
          toast.update(id, {
            render: "Funds sent successfully",
            type: "success",
            isLoading: false,
          });
          this.state.history.push("/wallet-history");
        } else {
          console.log({
            amountfunded: parseInt(this.state.amount),
            dealerId: "string",
            recieverEmailAddress: this.props.emailAddress,
            transactionPin: this.state.transactionPin,
            description: this.state.description,
          })
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
      });
  };

  render() {
    return (
      <div
        className="modal fade"
        id={this.props.id}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.props.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                className="row g-3 "
                onSubmit={(e) => {
                  e.preventDefault();
                  this.onSubmit(e);
                }}
              >
                <div className="col-12">
                  <label className="form-label">User Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={this.props.emailAddress}
                    disabled={true}
                    required={true}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">Amount (in Naira)</label>
                  <input
                    type="number"
                    className="form-control"
                    value={this.props.amount}
                    onChange={(e) => {
                      this.setState({ amount: e.target.value });
                    }}
                    required={true}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.description}
                    onChange={(e) => {
                      this.setState({ description: e.target.value });
                    }}
                    required={true}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">Authenticator Code</label>
                  <input
                    type="password"
                    className="form-control"
                    value={this.state.transactionPin}
                    onChange={(e) => {
                      this.setState({ transactionPin: e.target.value });
                    }}
                    required={true}
                  />
                </div>

                <button type="submit" className="btn btn-primary">
                  Transfer
                </button>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() { }
}

export default TransferModal;
