import axios from "axios";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import { MOBILE_WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import ResellerButton from "./setResellerRequest";

const RequestReseller = () => {
    const [token, setToken] = useState(cookie.load("token"));
    const [walletBalance, setWalletBalance] = useState(cookie.load("walletBalance"));
    const [selectedTable, setSelectedTable] = useState("Voucher Transactions");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [open, setOpen] = useState(false)
    const handleClose = (() =>setOpen(false))

    const [dspAirtimee, setDspAirtime] = useState(0)
    const [dspCablee, setDspCablee] = useState(0)
    const [dspDataa, setDspData] = useState(0)
    const [dspElectricityy, setDspElectricity] = useState(0)
    const [dspInternett, setDspInternet] = useState(0)
    const [dspJambb, setDspJamb] = useState(0)
    const [dspWaecc, setdspWaec] = useState(0)
    const [email, setEmail] = useState('')
    const [walletHistory, setWalletHistory] = useState([]);

    const getVouchers = () => {
        const today = new Date();
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth() + 1;
        const todayDate = today.getDate();
        const formattedToday = `${todayYear}-${todayMonth < 10 ? '0' : ''}${todayMonth}-${todayDate < 10 ? '0' : ''}${todayDate}`;

        const id = toast.loading("Please wait...");
        axios
            .get(MOBILE_WEB_BASE_URL + "/get-reseller-request", {
                headers: {
                    authorization: token,
                },
            })
            .then((res) => {
                if (res.data.status === "2000") {
                    console.log(res.data);
                    setWalletHistory(res.data.data);
                    toast.dismiss(id);
                } else {
                    toast.update(id, {
                        render: res.data.description,
                        type: "error",
                        isLoading: false,
                    });
                }
            })
            .catch((err) => {
                toast.update(id, {
                    render: "" + err,
                    type: "error",
                    isLoading: false,
                });
            });
    };

    useEffect(() => {
        getVouchers();
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "NGN",
    });

    return (
        <main className="page-content">
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <ResellerButton
                open={open}
                handleClose={handleClose}
                dspAirtime={dspAirtimee}
                dspCable={dspCablee}
                dspData={dspDataa}
                dspElectricity={dspElectricityy}
                dspInternet={dspInternett}
                dspJamb={dspJambb}
                dspWaec={dspWaecc}
                email={email}
            />
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <a href="javascript:;">
                                    <i className="bx bx-home-alt"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Reseller Request
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="ms-auto">
                    <div className="btn-group">
                        <button type="button" className="btn btn-primary">
                            Wallet Balance: {formatter.format(walletBalance)}
                        </button>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header py-3">
                    <div className="row g-3">
                        {/* Other components or content */}
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table
                            id="example"
                            className="table table-striped table-bordered"
                            style={{ width: "100%" }}
                        >
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>User Role</th>
                                    <th>Dsp Airtime</th>
                                    <th>Dsp Data</th>
                                    <th>Dsp Cable</th>
                                    <th>Dsp Electricity</th>
                                    <th>Dsp Internet</th>
                                    <th>Dsp Waec</th>
                                    <th>Dsp Jamb</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {walletHistory.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.emailAddress}</td>
                                        <td>{item.userRole}</td>
                                        <td>{item.dspAirtime}</td>
                                        <td>{item.dspData}</td>
                                        <td>{item.dspCable}</td>
                                        <td>{item.dspElectricity}</td>
                                        <td>{item.dspInternet}</td>
                                        <td>{item.dspWaec}</td>
                                        <td>{item.dspJamb}</td>
                                        <td>{item.requestStatus}</td>
                                        <td>{item.createdDate}</td>
                                        <td>
                                            <button type="button" className="btn btn-primary"
                                            onClick={() =>{
                                                setOpen(true)
                                                setDspAirtime(item.dspAirtime)
                                                setDspData(item.dspData)
                                                setDspCablee(item.dspCable)
                                                setDspInternet(item.dspInternet)
                                                setdspWaec(item.dspWaec)
                                                setDspJamb(item.dspJamb)
                                                setDspElectricity(item.dspElectricity)
                                                setEmail(item.emailAddress)
                                            }}
                                            >Approve</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default RequestReseller;
