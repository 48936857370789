import  React, {useEffect, useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelIcon from '@mui/icons-material/Cancel';
import cookie from "react-cookies";
import { WEB_BASE_URL } from '../../unified/webbase';
import axios from 'axios';
import { usePaystackPayment } from 'react-paystack';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  boxShadow: 24,
};

export default function PaystackModal({open, handleClose}) {
     const [amount, setAmount] = useState()
     const [type, setType] = useState('')
     const token = cookie.load("token")
    const email = cookie.load("emailAddress")
     const initialConfig = { reference: null, email: email, amount: 0, publicKey:'pk_live_a36043760d0eb68ef8a09f98553e405a85232cee', };
     const [configP, setConfig] = useState(initialConfig);
     let initializePayment = usePaystackPayment(configP);

     const makePayment = (e) =>{
        e.preventDefault()
        const id = toast.loading("Please wait...");
    const valuess = {
        "amount": parseFloat(amount)* 100,
        "channel": [
          "card", "bank", "ussd", "qr", "mobile_money", "bank_transfer", "eft"
        ],
        "walletType": type
      }

    axios
    .post(WEB_BASE_URL + "/onboarding/fund-wallet", valuess,  {
      headers: {
        authorization: token,
      },
    })
    .then((res) => {
      if (res.data.status === "2000") {
        // toast.update(id, {
        //   render: res.data.description,
        //   type: "success",
        //   isLoading: false,
        // });
        // setTimeout(() => {
        // //   toast.dismiss(id);
        //   window.location.href ="/set-threshold";
        // }, 2000);
        const reference =  res.data.data.reference
              
              console.log(reference)
              setConfig({
                ...configP, channel: [
                  'card',
                ], email: email, reference: reference, amount: parseInt(amount) * 100,
              });
      } else {
        console.log(res);
        toast.update(id, {
          render: res.data.description,
          type: "error",
          isLoading: false,
        });
      }
    })
    .catch((err) => {
      toast.update(id, {
        render: err,
        type: "error",
        isLoading: false,
      });
    });
}
const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    console.log(reference)
     verifyPayment(reference.reference)
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }
  const verifyPayment = (ref) =>{
    const id = toast.loading("Please wait...");
    axios
    .get(WEB_BASE_URL + `/onboarding/verify-transaction?reference=${ref}`,  {
      headers: {
        authorization: token,
      },
    })
    .then((res) => {
      if (res.data.status === "2000") {
        toast.update(id, {
          render: res.data.description,
          type: "success",
          isLoading: false,
        });
        setTimeout(() => {
        //   toast.dismiss(id);
          window.location.href ="/fund";
        }, 2000);
       
      } else {
        console.log(res);
        toast.update(id, {
          render: res.data.description,
          type: "error",
          isLoading: false,
        });
      }
    })
    .catch((err) => {
      toast.update(id, {
        render: err,
        type: "error",
        isLoading: false,
      });
    });
  }
  

  useEffect(() => {
    console.log(configP)
    if (configP.reference === null || configP.email === undefined) return;
    initializePayment(onSuccess, onClose)
  }, [configP, initializePayment])


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg  p-4 pt-10'> 
          
            <div className=' '>
           
                <div className=' d-flex justify-content-end mb-3'>
                   
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-black cursor-pointer text-[38px]' fontSize="large"/>
                    </div>
                </div>
                <form>
                <div className="">
                            <label className="form-label">Amount </label>
                            <input
                              onChange={(e) => {
                                setAmount(e.target.value)
                              }}
                              required
                              type="text"
                              className="form-control"
                              value={amount}
                            />
                </div>
                  <div class="col-12 mt-3">
                            <label class="form-label">Merchant Account</label>
                            <select
                              required
                              className="form-select "
                              aria-label="User Account"
                              value={type}
                              onChange={(event) => {
                                console.log(event)
                                setType(event.target.value)
                              }}
                            >
                              <option value={""}> Select User </option>
                              <option value={"vas wallet"}>Vas wallet </option>
                              <option value={"vtu wallet"}>Vtu wallet </option>
                            </select>
                          </div>
                          <div className="col-4 ">
                            <label className="form-label"> &nbsp; </label>
                            <button
                             onClick={makePayment}
                              className="btn btn-primary form-control"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                </form>
                
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}