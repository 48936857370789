// /* eslint-disable jsx-a11y/anchor-is-valid */

// import React from "react";
// import axios from "axios";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import Voucheritem from "./voucheritem";
// import { WEB_BASE_URL } from "./webbase";
// import cookie from "react-cookies";
// import { ToastContainer, toast } from "react-toastify";
// import AuthModal from "./AuthModal";

// // create new class component
// // what is going on 

// class Vouchers extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       token: cookie.load("token"),
//       businessName: cookie.load("businessName"),
//       vouchers: [],
//       readyPrint: false,
//       readyVouchers: [],
//       Pin: "",
//     };
//     this.setPin = this.setPin.bind(this);
//     this.getVouchers = this.getVouchers.bind(this);
//     this.printDocument = this.printDocument.bind(this);
//   }

//   setPin(event) {
//     this.setState({
//       Pin: event.target.value,
//     });
//   }

//   getVouchers = () => {
//     const id = toast.loading("Please wait...");
//     axios
//       .get(WEB_BASE_URL + "/merchant/pin-manager", {
//         headers: {
//           authorization: this.state.token,
//         },
//       })
//       .then((res) => {
//         console.log(res.data.data);
//         res.data.data.reverse();
//         this.setState({ vouchers: res.data.data });
//         // toast.dismiss(id);
//       })
//       .catch((err) => {
//         toast.update(id, {
//           render: "" + err,
//           type: "error",
//           isLoading: false,
//         });
//         //this.props.history.push("/signIn");
//       }).finally(() => {
//         toast.dismiss(id);
//       });
//   };

//   printDocument(e, batchID) {
//     const id = toast.loading("Please wait...");
//     console.log(batchID)
//     axios
//       .get(WEB_BASE_URL + "/merchant/print-voucher?batchId=" + batchID + "&transactionPin=" + this.state.Pin, {
//         headers: {
//           Authorization: this.state.token,
//         },
//       })
//       .then((res) => {
//         console.log(res.data);
//         if (res.data.status == "2000") {
//           this.setState({ readyPrint: true, readyVouchers: res.data.data });
//           console.log(this.state.readyVouchers);
//           const input = document.getElementById("pdfdiv");
//           html2canvas(input).then((canvas) => {
//             var imgWidth = 200;
//             var pageHeight = 290;
//             var imgHeight = (canvas.height * imgWidth) / canvas.width;
//             var heightLeft = imgHeight;
//             const imgData = canvas.toDataURL("image/png");
//             const pdf = new jsPDF("p", "mm", "a4");
//             var position = 0;
//             var heightLeft = imgHeight;
//             pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
//             pdf.save("download.pdf");
//             this.setState({ readyPrint: false, readyVouchers: [] });
//             setTimeout(() => {
//               toast.dismiss(id);
//               this.props.history.push("/vouchers");
//             }, 1000);
//           });
//         } else {
//           toast.dismiss(id);
//           toast.error(res.data.description);
//         }
//       })
//       .catch((err) => {
//         toast.update(id, {
//           render: "Generation failed: " + err,
//           type: "error",
//           isLoading: false,
//         });
//         console.log(err);

//       });
//   }

//   componentDidMount() {
//     this.getVouchers();
//   }

//   render() {
//     const printDiv = {
//       display: this.state.readyPrint ? "block" : "none",
//     };
//     return (
//       <main className="page-content">
//         <ToastContainer
//           position="top-center"
//           autoClose={3000}
//           hideProgressBar={false}
//           newestOnTop={false}
//           closeOnClick
//           rtl={false}
//           pauseOnFocusLoss
//           draggable
//           pauseOnHover
//         />
//         <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
//           <div className="ps-3">
//             <nav aria-label="breadcrumb">
//               <ol className="breadcrumb mb-0 p-0">
//                 <li className="breadcrumb-item">
//                   <a href="javascript:;">
//                     <i className="bx bx-home-alt"></i>
//                   </a>
//                 </li>
//                 <li className="breadcrumb-item active" aria-current="page">
//                   View Vouchers
//                 </li>
//               </ol>
//             </nav>
//           </div>

//         </div>

//         <div class="card">
//           <div class="card-header py-3">
//             <div class="row g-3">
//               <div class="col-lg-3 col-md-6 me-auto">
//                 <div class="ms-auto position-relative">
//                   <div class="position-absolute top-50 translate-middle-y search-icon px-3">
//                     <i class="bi bi-search"></i>
//                   </div>
//                   <input
//                     class="form-control ps-5"
//                     type="text"
//                     placeholder="Search Payment"
//                   />
//                 </div>
//               </div>
//               <div class="col-lg-2 col-6 col-md-3">
//                 <select class="form-select">
//                   <option>Status</option>
//                   <option>Active</option>
//                   <option>Disabled</option>
//                   <option>Pending</option>
//                   <option>Show All</option>
//                 </select>
//               </div>
//               <div class="col-lg-2 col-6 col-md-3">
//                 <select class="form-select">
//                   <option>Show 10</option>
//                   <option>Show 30</option>
//                   <option>Show 50</option>
//                 </select>
//               </div>
//             </div>
//           </div>
//           <div class="card-body">
//             <div class="table-responsive">
//               <table class="table align-middle mb-0">
//                 <thead class="table-light">
//                   <tr>
//                     <th>Batch ID</th>
//                     <th>Description</th>
//                     <th>Units</th>
//                     <th>Unit Amount</th>
//                     <th>Status</th>
//                     <th>Created On </th>
//                     <th>Action</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {this.state.vouchers.map((voucher) => (
//                     <tr>
//                       <td>{voucher.id}</td>
//                       <td>
//                         <div class="d-flex align-items-center gap-3 cursor-pointer">
//                           <div class="">
//                             <p class="mb-0">{voucher.description}</p>
//                           </div>
//                         </div>
//                       </td>
//                       <td>{voucher.unit}</td>
//                       <td>&#8358;{voucher.value}</td>
//                       <td>
//                         {voucher.decrypted ? (
//                           <span class="badge rounded-pill alert-error">
//                             Printed
//                           </span>
//                         ) : (
//                           <span class="badge rounded-pill alert-success">
//                             Not Printed
//                           </span>
//                         )}
//                       </td>

//                       <td>{voucher.dateCreated.split("T")[0]}</td>
//                       <td>
//                         <div class="d-flex align-items-center gap-3 fs-6">
//                           <a
//                             href="javascript:;"
//                             class="text-primary"
//                             data-bs-toggle="tooltip"
//                             data-bs-placement="bottom"
//                             title="Activate"
//                             data-bs-original-title="View detail"
//                             aria-label="Views"
//                           >
//                             <i class="bi bi-collection-play-fill"></i>
//                           </a>

//                           <a
//                             href="javascript:void(0);"
//                             class="text-success"
//                             data-bs-toggle="modal"
//                             data-bs-target={"#PrintModal" + voucher.id}
//                             data-bs-placement="bottom"
//                             title=""
//                             data-bs-original-title="Download"
//                             aria-label="Download"
//                           >
//                             <i class="bi bi-cloud-arrow-down-fill"></i>
//                           </a>
//                           <a
//                             href="javascript:;"
//                             class="text-danger"
//                             data-bs-toggle="tooltip"
//                             data-bs-placement="bottom"
//                             title="Block"
//                             data-bs-original-title="Delete"
//                             aria-label="Delete"
//                           >
//                             <i class="bi bi-stop-circle-fill"></i>
//                           </a>
//                         </div>
//                       </td>
//                       <AuthModal
//                         title="Enter Authenticator Code"
//                         id={"PrintModal" + voucher.id}
//                         batchId={voucher.keyId}
//                         onSubmit={this.printDocument}
//                         formPin={this.state.Pin}
//                         setPin={this.setPin}
//                       ></AuthModal>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//             <nav class="float-end mt-3">
//               <ul class="pagination">
//                 <li class="page-item disabled">
//                   <a class="page-link" href="#">
//                     Previous
//                   </a>
//                 </li>
//                 <li class="page-item active">
//                   <a class="page-link" href="#">
//                     1
//                   </a>
//                 </li>
//                 <li class="page-item">
//                   <a class="page-link" href="#">
//                     2
//                   </a>
//                 </li>
//                 <li class="page-item">
//                   <a class="page-link" href="#">
//                     3
//                   </a>
//                 </li>
//                 <li class="page-item">
//                   <a class="page-link" href="#">
//                     Next
//                   </a>
//                 </li>
//               </ul>
//             </nav>
//           </div>

//           <div id="pdfdiv" style={printDiv}>
//             <div className="row g-3">
//               {this.state.readyVouchers.map((voucher, index) => (
//                 <>
//                   <Voucheritem
//                     id={voucher.id}
//                     pin={voucher.pin}
//                     serial={voucher.serialNo}
//                     dateCreated={voucher.dateCreated.split("T")[0]}
//                   ></Voucheritem>
//                   {index % 2 === 0 ? <></> : <div class="w-100"></div>}
//                 </>
//               ))}
//             </div>
//           </div>
//         </div>
//       </main>
//     );
//   }
// }

// export default Vouchers;


import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
import { WEB_BASE_URL } from './webbase';
import axios from 'axios';
import cookie from "react-cookies";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Voucheritem from "./voucheritem"
import AuthModal from "./AuthModal";
import { useNavigate } from 'react-router';

function Vouchers() {
        const token = cookie.load("token")
    const currentDate = new Date();

  // Subtract 14 days (14 * 24 * 60 * 60 * 1000 milliseconds) from the current date
  const twoWeeksAgoDate = new Date(currentDate.getTime() - (14 * 24 * 60 * 60 * 1000));
    const {navigate} = useNavigate()
    const [startDate, setStartDate] = useState(twoWeeksAgoDate)
    const [endDate, setEndDate] = useState(new Date())
    const [batchID, setbatchID] = useState('')
    const [readyVouchers, setReadyVouchers] = useState([])
    const [pin, setPin] = useState('')
    const[readyPrint, setReadyPrint] = useState(false)
    const [transactionsToDisplay, setTransactionsToDisplay] = useState([])
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "NGN",
    });
    useEffect(() => {
        const originalDate = new Date(decodeURIComponent(startDate));
        const originalDate2 = new Date(decodeURIComponent(endDate));
        // Extract year, month, and day components
        const year = originalDate.getFullYear();
        const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
        const day = String(originalDate.getDate()).padStart(2, '0');
        const year2 = originalDate2.getFullYear();
        const month2 = String(originalDate2.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
        const day2 = String(originalDate2.getDate()).padStart(2, '0');
        // Construct the desired date format
        const formattedDate = `${year}-${month}-${day}`;
        const formattedDate2 = `${year2}-${month2}-${day2}`;
        let api = "";
        api = "/merchant/custom-batch-history";
        axios
            .get(
                WEB_BASE_URL +
                api + "?endDate=" +
                formattedDate2+
                "&startDate=" +
                formattedDate,
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            .then((res) => {
                if (res.data.status === "2000" && res.data.data !== null) {
                    console.log(res.data.data);
                   setTransactionsToDisplay(res.data.data)
                } else {
                    toast.error("No data found");
                    setTransactionsToDisplay([])
                }
            })
            .catch((err) => {
                console.log(WEB_BASE_URL + " " + err);
                toast.error("Error fetching transactions");
            }).finally(() => {
                
            });
    },[endDate, startDate, token])
    const printDiv = {
      display: readyPrint ? "block" : "none",
    };

    const printDocument = (e, batchID) => {
  const id = toast.loading("Please wait...");
  console.log(batchID);
  axios
    .get(WEB_BASE_URL + "/merchant/print-voucher?batchId=" + batchID + "&transactionPin=" + pin, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => {
      console.log(res.data);
      if (res.data.status === "2000") {
        setReadyPrint(true);
        setReadyVouchers(res.data.data);

        console.log(readyVouchers);
        const input = document.getElementById("pdfdiv");
        html2canvas(input).then((canvas) => {
          var imgWidth = 200;
          var pageHeight = 290;
          var imgHeight = (canvas.height * imgWidth) / canvas.width;
          var heightLeft = imgHeight;
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          var position = 0;
          var heightLeft = imgHeight;
          pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
          pdf.save("download.pdf");
          setTimeout(() => {
            setReadyPrint(false);
            setReadyVouchers([]);
            toast.dismiss(id);
            window.location.href = "/vouchers";
          }, 1000);
        });
      } else {
        toast.dismiss(id);
        toast.error(res.data.description);
      }
    })
    .catch((err) => {
      toast.update(id, {
        render: "Generation failed: " + err,
        type: "error",
        isLoading: false,
      });
      console.log(err);
    });
};

  return (
    
    <main className="page-content">
     <ToastContainer
         position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
    <div  className="">
    <h3 className=' mb-2'>Vouchers</h3>  
    <div className=' card  p-4'>
    <form className="row">
              <div className="row">
                <div className="col-3">
                  <label className="form-label">From:</label>
                  <DatePicker
                    selected={startDate}
                    onSelect={(date) => setStartDate(date)}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
                <div className="col-3">
                  <label className="form-label">To:</label>
                  <DatePicker
                    selected={endDate}
                    onSelect={(date) => setEndDate(date)}
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              </div>
            
            </form>
    </div>
        <div className=' mt-4'>
        <div class="table-responsive">
                            
                            <table className="table align-middle mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th>Serial number</th>
                                        <th>Amount</th>
                                        <th>Created date</th>
                                        <th>Description</th>
                                        <th>End Serial No</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   
                                    {transactionsToDisplay.length !== 0 ? (
                                        transactionsToDisplay.map((voucher, index) => (
                                            <tr key={index}>
                                                <td>{voucher?.fromSerialNo}</td>
                                                <td>{voucher?.amount}</td>
                                                <td>{
                                                    voucher.createdDate !== undefined
                                                        ? voucher.createdDate.split("T")[0]
                                                        : 0
                                                }</td>
                                                <td>{voucher?.description}</td>
                                                
                                                <td>{voucher?.endSerialNo}</td>
                                                <td>
                                                   <div class="d-flex align-items-center gap-3 fs-6"
                                                   onClick={() =>{
                                                    setbatchID(voucher.keyId)
                                                    printDocument(voucher.keyId,voucher.keyId)
                                                   }}
                                                   >
                                                

                                                
                                                    <i class="bi bi-cloud-arrow-down-fill"></i>
                                              
                                                
                                                </div>
                                                </td>

                                                  <AuthModal
                                              title="Enter Authenticator Code"
                                              id={"PrintModal" + voucher.keyId}
                                              batchId={voucher.keyId}
                                              onSubmit={printDocument}
                                              formPin={pin}
                                              setPin={setPin}
                                            ></AuthModal>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr colSpan="7">
                                            <td>No Transaction Record.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div> 
            </div>

                  <div id="pdfdiv" style={printDiv}>
            <div className="row g-3">
             {readyVouchers.map((voucher, index) => (
                 <>
                   <Voucheritem
                     id={voucher.id}
                     toUse={voucher.toUse}
                     pin={voucher.pin}
                     serial={voucher.serialNo}
                     dateCreated={voucher.dateCreated.split("T")[0]}
                   ></Voucheritem>
                   {index % 2 === 0 ? <></> : <div class="w-50"></div>}
                 </>
               ))}
             </div>
           </div>  
    </div>
    </main>
  )
}

export default Vouchers