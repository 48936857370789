import React from "react";

class ForgotPinModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      newPin: "",
    };

    this.handleChangePin = this.handleChangePin.bind(this);
    this.handleChangeOtp = this.handleChangeOtp.bind(this);
  }

  handleChangePin(event) {
    this.setState({
      newPin: event.target.value,
    });
  }

  handleChangeOtp(event) {
    this.setState({
      otp: event.target.value,
    });
  }

  render() {
    return (
      <div
        className="modal fade"
        id="ForgotPinModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Forgot Transaction Pin
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row g-3">
                <div className="col-12">
                  <label className="form-label">Email OTP</label>
                  <input
                    onChange={(event) => this.handleChangeOtp}
                    type="email"
                    className="form-control"
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">New Pin</label>
                  <input
                    onChange={(event) => this.handleChangePin}
                    type="text"
                    className="form-control"
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                onClick={(e) => {
                  this.props.onSubmit();
                }}
                type="button"
                className="btn btn-primary"
              >
                Invite User
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default ForgotPinModal;
