import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
import { WEB_BASE_URL } from './webbase';
import axios from 'axios';
import cookie from "react-cookies";

function VoucherHistory() {
        const token = cookie.load("token")
    const currentDate = new Date();

  // Subtract 14 days (14 * 24 * 60 * 60 * 1000 milliseconds) from the current date
  const twoWeeksAgoDate = new Date(currentDate.getTime() - (14 * 24 * 60 * 60 * 1000));

    const [startDate, setStartDate] = useState(twoWeeksAgoDate)
    const [endDate, setEndDate] = useState(new Date())
    const [transactionsToDisplay, setTransactionsToDisplay] = useState([])
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "NGN",
    });
    useEffect(() => {
        const originalDate = new Date(decodeURIComponent(startDate));
        const originalDate2 = new Date(decodeURIComponent(endDate));
        // Extract year, month, and day components
        const year = originalDate.getFullYear();
        const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
        const day = String(originalDate.getDate()).padStart(2, '0');
        const year2 = originalDate2.getFullYear();
        const month2 = String(originalDate2.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
        const day2 = String(originalDate2.getDate()).padStart(2, '0');
        // Construct the desired date format
        const formattedDate = `${year}-${month}-${day}`;
        const formattedDate2 = `${year2}-${month2}-${day2}`;
        let api = "";
        api = "/merchant/unicard-custom-report";
        axios
            .get(
                WEB_BASE_URL +
                api + "?endDate=" +
                formattedDate2+
                "&startDate=" +
                formattedDate,
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            .then((res) => {
                if (res.data.status === "2000" && res.data.report !== null) {
                    console.log(res.data.report);
                   setTransactionsToDisplay(res.data.report)
                } else {
                    toast.error("No data found");
                    setTransactionsToDisplay([])
                }
            })
            .catch((err) => {
                console.log(WEB_BASE_URL + " " + err);
                toast.error("Error fetching transactions");
            }).finally(() => {
                
            });
    },[endDate, startDate, token])
  return (
    
    <main className="page-content">
     <ToastContainer
         position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
    <div  className="">
    <h3 className=' mb-2'>VoucherHistory</h3>  
    <div className=' card  p-4'>
    <form className="row">
              <div className="row">
                <div className="col-3">
                  <label className="form-label">From:</label>
                  <DatePicker
                    selected={startDate}
                    onSelect={(date) => setStartDate(date)}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
                <div className="col-3">
                  <label className="form-label">To:</label>
                  <DatePicker
                    selected={endDate}
                    onSelect={(date) => setEndDate(date)}
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              </div>
            
            </form>
    </div>
        <div className=' mt-4'>
        <div class="table-responsive">
                            
                            <table className="table align-middle mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th>ID</th>
                                        <th>Value</th>
                                        <th>Unit Price</th>
                                        <th>Status</th>
                                        <th>Date Created</th>
                                        <th>Valid still</th>
                                        <th>Marchant Account ID</th>
                                        <th>Auth pin</th>
                                        <th>Used</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   
                                    {transactionsToDisplay.length !== 0 ? (
                                        transactionsToDisplay.map((voucher, index) => (
                                            <tr key={index}>
                                                <td>{voucher?.id}</td>
                                                <td>{voucher?.value}</td>
                                                <td>{voucher?.unitPrice}</td>
                                                <td>{voucher?.status}</td>
                                                <td>{
                                                    voucher.dateCreated !== undefined
                                                        ? voucher.dateCreated.split("T")[0]
                                                        : 0
                                                }</td>
                                                <td>{
                                                    voucher.validTill !== undefined
                                                        ? voucher.validTill.split("T")[0]
                                                        : 0
                                                }</td>
                                                <td>{voucher?.merchantAccountId}</td>
                                                <td>{formatter.format(voucher.authPin)}</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="product-info">
                                                            <h6 className="product-name mb-1">
                                                                {voucher.used === false ? <span>Not used</span>: <span>Used</span>}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </td>
                                               
                                              
                                            </tr>
                                        ))
                                    ) : (
                                        <tr colSpan="7">
                                            <td>No Transaction Record.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div> 
        </div>  
    </div>
    </main>
  )
}

export default VoucherHistory