import React from "react";
import axios from "axios";
import { WEB_BASE_URL } from "./webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaidIcon from '@mui/icons-material/Paid';
//import OutboundTwoToneIcon from '@mui/icons-material/OutboundTwoTone';
import { BlockLoading } from 'react-loadingg';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import PaymentsIcon from '@mui/icons-material/Payments';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
} from 'chart.js';

import { faker } from '@faker-js/faker';
import { Bar } from 'react-chartjs-2';

class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      token: cookie.load("token"),
      businessName: cookie.load("businessName"),
      vouchers: [],
      vouchersReport: [],
      walletBalance: 0,
      transactionCount: {},
      endDate: new Date(),
      vasWallet:0,
    };
    this.getVouchers = this.getVouchers.bind(this);
    this.getTrasanctionCount = this.getTrasanctionCount.bind(this);
    this.vouchersReport = this.getTrasanctionCount.bind(this);
    this.getWalletBalance = this.getWalletBalance.bind(this);

    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      BarElement,
      Title,
      Tooltip,
      Legend,
      Filler,
  );
  }

  getWalletBalance() {
    axios
      .get(WEB_BASE_URL + "/onboarding/account-balance", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        cookie.save("walletBalance", res.data.data.entBalance, {
          path: "/",
          expires: new Date(Date.now() + 60 * 30 * 1000),
        });
        cookie.save("vasWallet", res.data.data.unicardBalance, {
          path: "/",
          expires: new Date(Date.now() + 60 * 30 * 1000),
      });
        this.setState({ walletBalance: res.data.data.entBalance ? res.data.data.entBalance : 0});
        this.setState({ vasWallet: res.data.data.unicardBalance ? res.data.data.unicardBalance : 0})
      })
      .catch((err) => { console.log(WEB_BASE_URL + " " + err); })
  }

  getTrasanctionCount() {
    const today = new Date();
    // Get the components of today's date
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth() + 1; // Months are zero-indexed, so add 1
    const todayDate = today.getDate();

    // Formatted string of today's date (in yyyy-mm-dd format)
    const formattedToday = `${todayYear}-${todayMonth < 10 ? '0' : ''}${todayMonth}-${todayDate < 10 ? '0' : ''}${todayDate}`;

    // Subtract one day (24 hours) from today's date
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Get the components of yesterday's date
    const yesterdayYear = yesterday.getFullYear();
    const yesterdayMonth = yesterday.getMonth() + 1; // Months are zero-indexed, so add 1
    const yesterdayDate = yesterday.getDate();

    // Formatted string of yesterday's date (in yyyy-mm-dd format)
    const formattedYesterday = `${yesterdayYear}-${yesterdayMonth < 10 ? '0' : ''}${yesterdayMonth}-${yesterdayDate < 10 ? '0' : ''}${yesterdayDate}`;
    let api = "";
    if (this.state.userGroup == "MERCHANT") {
        api = "/merchant/unicard-custom-report";
    } else {
        api = "/merchant/unicard-custom-report";
    }
    axios
        .get(WEB_BASE_URL + api + "?endDate=" + formattedToday
            + "&merchantEmail=&startDate=2024-01-01" , {
            headers: {
                authorization: this.state.token,
            },
        })
        .then((res) => {
            if (res.data.status === "2000" && res.data.data !== null) {
                this.setState({ transactionCount: res.data.data });
            }
        })
        .catch((err) => {
            console.log(WEB_BASE_URL + " " + err);
        });
}
  getVouchers = () => {

    axios
      .get(WEB_BASE_URL + `/merchant/unicard-custom-report?endDate=` + '2024-04-01' +
      "&startDate=2024-01-01", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        // res.data.data.reverse();
        //this.setState({ vouchers: res.data.report.reverse() });
       this.setState({vouchersReport:res.data.report.reverse().slice(0, 10)})
      })
      .catch((err) => {
        toast.error("" + err);
      });
  };
  componentDidMount() {
    this.getWalletBalance();
    this.getVouchers();
    this.getTrasanctionCount();
  }

  render() {

    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });

    const labels = ["Type of Transaction"];
    const options = {
        responsive: true,
        plugins: [{
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
        {
            beforeDraw: (chart) => {
                if (this.state.isLoading) {
                    const canvas = chart.canvas;
                    const ctx = canvas.getContext('2d');
                    const centerX = canvas.width / 2;
                    const centerY = canvas.height / 2;

                    ctx.save();
                    ctx.beginPath();
                    ctx.translate(centerX, centerY);
                    BlockLoading.render({
                        size: 50,
                        color: '#263B95',
                        speed: 1,
                        duration: 2000
                    }, ctx);
                    ctx.restore();
                }
            }
        }
        ],
        scales: {
            x: {
                grid: {
                    display: false,
                },
                scaleLabel: {
                    display: true,
                    labelString: "Transaction Type"
                },
            },
            y: {
                grid: {
                    display: false,
                },
                scaleLabel: {
                    display: true,
                    labelString: "Number of transactions"
                },
            }
        }
    };
    const data = {
        labels,
        datasets: [
            {
                label: "Total generated",
                data: [this.state.transactionCount["total generated"]],
                backgroundColor: '#263B95',
                borderColor: '#263B95',
                borderRadius: 14,
                borderWidth: 2,
                fill: true, // Add color to the area underneath the line
            },
            {
                label: "Total unused",
                data: [this.state.transactionCount["total unUsed"]],
                backgroundColor: '#76c48b',
                borderColor: '#76c48b',
                borderRadius: 14,
                borderWidth: 2,
                fill: true, // Add color to the area underneath the line
            },
            {
                label: "Total used",
                data: [this.state.transactionCount["total used"]],
                backgroundColor: '#e0365e',
                borderColor: '#e0365e',
                borderRadius: 14,
                borderWidth: 2,
                fill: true, // Add color to the area underneath the line
            },
        ],
    };
    return (
      <div className="page-content">
        <div className="row">
          <div className="col-md-8">
          <div className="overflow-hidden radius-10">
                            <div className="card-body align-items-center flex justify-content-between" style={{
                                minHeight: "30vh",
                                maxHeight: "40vh",
                                paddingLeft: "7%"
                            }}>
                                {this.state.isLoading && <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><BlockLoading color="#263B95" /></div>}
                                <Bar options={options} data={data} />
                            </div>
                        </div>
          </div>

          <div className="col-sm-4">

            <div className="row ">
              <div className="col-md-5 card " 
              style={{
                        margin:"10px"
                      }}  
              >
                <div className=" overflow-hidden radius-10">
                  <div className="card-body">
                    <div className=" align-items-stretch justify-content-between overflow-hidden pb-1">
                      <div className="">
                      <AccountBalanceWalletIcon/>
                        <div className="mb-1 mt-4">VTU Wallet Balance</div>

                        <h5 className="" style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}>
                          {formatter.format(this.state.walletBalance)}
                        </h5>
                      
                        
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            
              <div className="col-md-5 card "
              style={{
                        margin:"10px"
                      }}  
              >
                <div className=" overflow-hidden radius-10">
                  <div className="card-body">
                    <div className="align-items-stretch justify-content-between overflow-hidden">
                      <div className="">
                      <PaymentsIcon/>
                        <div className="mb-1 mt-4">VAS Wallet balance</div>
                        <h5 className="" style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}> {formatter.format(this.state.vasWallet)}</h5> 
                       
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-5 card "
              style={{
                        margin:"10px"
                      }}  
              >
                <div className=" overflow-hidden radius-10">
                  <div className="card-body">
                    <div className="align-items-stretch justify-content-between overflow-hidden">
                      <div className="">
                      <PaidIcon
                        />
                        <div className="mb-1 mt-4">Transactions</div>
                        <h5 className="" style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}>0</h5>
                        {/* <div className="mb-1 mt-4">Total value</div> */}
                        {/* <h5 className="" style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}>{formatter.format(0)}</h5> */}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
           
              <div className="col-md-5 card"
              style={{
                        margin:"10px"
                      }}  
              >
                <div className=" overflow-hidden radius-10">
                  <div className="card-body">
                    <div className="align-items-stretch justify-content-between overflow-hidden">
                      <div className="">
                        <AutoModeIcon />
                        <div className="mb-1 mt-4">Total debit</div>
                        <h5 className="" style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}>{formatter.format(0)}</h5>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-12 col-xl-12 d-flex">
            <div className="card radius-10 w-100">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <h6 className="mb-0">Recent Vouchers</h6>
                  <div className="fs-5 ms-auto dropdown">
                    <div
                      className="dropdown-toggle dropdown-toggle-nocaret cursor-pointer"
                      data-bs-toggle="dropdown"
                    >
                      <i className="bi bi-three-dots"></i>
                    </div>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-responsive mt-2">
                  <table className="table align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Batch ID</th>
                        <th>Description</th>
                        <th>Unit Quantity</th>
                        <th>Price</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.vouchersReport.map((voucher, index) => (
                        <tr key={index}>
                          <td>{voucher.id}</td>
                          <td>
                            <div className="d-flex align-items-center gap-3">
                              <div className="product-info">
                                <h6 className="product-name mb-1">
                                  {voucher.userId}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>{voucher.value}</td>
                          <td>{voucher.unitPrice}</td>
                          <td>{voucher.dateCreated.split("T")[0]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
