import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "./webbase";
import Shimmer from 'react-js-loading-shimmer';
import ArchiveIcon from '@mui/icons-material/Archive';
class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userRole: cookie.load("userRole"),
      sidebar: cookie.load("sidebar") ? cookie.load("sidebar") : [],
      interval: 0,
      activeItemIndex: -1,
      token: cookie.load("token"),
      user: cookie.load("user") ? cookie.load("user") : [],
      dealerId: cookie.load("dealerId") ? cookie.load("dealerId") : null,
      userName: cookie.load("userName") ? cookie.load("userName") : null,
      userGroup: cookie.load("userGroup") ? cookie.load("userGroup") : null,
      description: cookie.load("description")
        ? cookie.load("description")
        : null,
      enterpriseToken: cookie.load("enterpriseToken") ? cookie.load("enterpriseToken") : null,
    };
    this.getProfile = this.getProfile.bind(this);
    this.saveCookies = this.saveCookies.bind(this);
    this.checkCookies = this.checkCookies.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.getMenu = this.getMenu.bind(this);
  }

  handleSignOut = () => {
    cookie.remove("user");
    cookie.remove("token");
    cookie.remove("sidebar");
    cookie.remove("userRole");
    clearInterval(this.state.interval);
    this.props.navigate("/signIn");
    this.props.navigate(0);
  };
  handleItemClick = (index) => {
    this.setState((prevState) => ({
      activeItemIndex: prevState.activeItemIndex === index ? -1 : index,
    }));
  };
  checkCookies() {
    const user = cookie.load("user");
    const token = cookie.load("token");
    const sidebar = cookie.load("sidebar");

    if (!user || !token || !sidebar) {
      clearInterval(this.state.interval);
      this.props.navigate("/signIn");
      this.props.navigate(0);
      return;
    }
  }
  async getProfile() {
    //toast loading
    axios
      .get(WEB_BASE_URL + "/onboarding/get-user", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        // console.log(res)
        this.setState({
          user: res.data.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }


  async saveCookies(token) {
    await this.getProfile();
    cookie.save("token", token, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("user", this.state.user, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("dealerId", this.state.dealerId, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("userName", this.state.userName, {
      path: "/",
      expires: new Date(Date.now() + 60 * 22 * 1000),
    });
    cookie.save("userRole", this.state.userRole, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("userGroup", this.state.userGroup, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("sidebar", this.state.sidebar, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("enterpriseToken", this.state.enterpriseToken, {
      path: "/",
      expires: new Date(Date.now() + 60 * 27 * 1000),
    });
    cookie.save("description", this.state.description, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("user", this.state.user, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
  };



  getMenu() {
    this.setState({
      isLoading: true,
    })

    axios
      .get(WEB_BASE_URL + "/onboarding/get-menu?product=" + this.props.activeProduct, {
        headers: {
          Authorization: this.state.token,
        },
      }).then(sidebar => {
        let userRole = this.state.userRole;
        if (this.state.userRole == "MERCHANT") userRole = this.state.userRole.charAt(0).toUpperCase() + this.state.userRole.slice(1).toLowerCase();
        let obj = sidebar.data.data.sidebarMenu.filter(item => item.role == userRole || item.role == "all");
        this.setState({
          sidebar: obj[0].Side,
        })
        cookie.save("sidebar", obj[0].Side, {
          path: "/",
          expires: new Date(Date.now() + 60 * 16 * 1000),
        });
      }).catch(res => { })
      .finally(res => {
        this.setState({
          isLoading: false,
        })
      })
  }

  refreshToken() {
    axios
      .post(
        WEB_BASE_URL + "/onboarding/refresh-token",
        {},
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "2000") {
          this.setState({
            token: res.data.data.Token,
          });

          this.saveCookies(res.data.data.Token);
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          this.props.navigate("/signIn");
          this.props.navigate(0);
        }
        console.log(err);
      });
  }

  componentDidMount() {
    //GET SIDEBAR FROM cookie
    if (cookie.load("sidebar") !== undefined) {
      this.getMenu();
      let IntervalId = setInterval(this.checkCookies, 10000);
      this.refreshToken();

      this.setState({
        sidebar: cookie.load("sidebar"),
        interval: IntervalId,
      });
    } else {
      this.getMenu()
    }
  }

  componentDidUpdate() { }

  componentWillUnmount() {
    //stop checking cookies
    clearInterval(this.state.interval);
  }

  render() {
    // const { sidebar } = this.state;
    return (
      <aside className="sidebar-wrapper bg-white" data-simplebar="init">
        <div className="sidebar-header bg-white">
          <div>
            <img
              src="assets/images/logo-icon.png"
              className="logo-icon"
              alt="logo icon"
            />
          </div>
          <div>
            <img
              src="assets/images/llogo2.png"
              className="logo-text"
              style={{ maxWidth: "150px", padding: "10px" }}
              alt="logo text"
            />
          </div>
          <div className="toggle-icon ms-auto">
            {" "}
            <i className="bi bi-list"></i>
          </div>
        </div>

        <ul className="metismenu" id="menu" style={{ overflow: "scroll" }}>
          {/* {this.state?.sidebar?.map((item, index) =>
            item?.name !== "Compliance" ? (
              <li key={index} className={window.location.pathname == item?.url ? "mm-active" : "" + " "}>
                <a
                  className={item?.submenu.length > 0 ? "has-arrow" : ""}
                  href={item?.name == "API Documentation" ? "https://developer.topupbox.com" : item?.url}
                >
                  <div className="parent-icon">
                    <img src={require("../assets/menu_icons/" + item.iconActive)} alt="icon" />

                  </div>
                  <div className="menu-title">{item?.name}</div>
                </a>
                <ul className="submenu">
                  {item?.submenu.map((subitem, subindex) => (
                    <li key={subindex}>
                      <a href={subitem.url}>
                        <i className="bi bi-circle"></i>
                        {subitem.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            ) : (
              <div key={index}></div>
            )
          )} */}
          {this.state?.sidebar?.map((item, index) =>
          item.name !== "Compliance" ? (
            <li key={index} className={window.location.pathname === item.url ? "mm-active" : ""}>
              <a
                className={item.submenu.length > 0 ? "has-arrow" : ""}
                href={item.name === "API Documentation" ? 
                //"https://developer.topupbox.com"
                "https://documenter.getpostman.com/view/19486823/2sA3XPBMpH"
                 : item.url}
                onClick={() => this.handleItemClick(index)}
              >
                <div className="parent-icon">
                  <img src={require(`../assets/menu_icons/${item.iconActive}`)} alt="icon" />
                </div>
                <div className="menu-title">{item.name}</div>
              </a>
              {this.state.activeItemIndex === index && (
                <ul className="submenu">
                  {item.submenu.map((subitem, subindex) => (
                    <li key={subindex}>
                      <a href={subitem.url}>
                        <i className="bi bi-circle"></i>
                        {subitem.name}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ) : (
            <div key={index}></div>
          )
        )}
          <li>
            <a href="/support">
              <div className="parent-icon">
                <img src={"./assets/images/menu_icons/Support1.svg"} alt="icon" />
              </div>
              <div className="menu-title">Support</div>
            </a>
          </li>
          <li>
            <a
              href="/logout"
              onClick={(e) => {
                e.preventDefault();
                this.handleSignOut();
              }}
            >
              <div className="parent-icon">
                <i className="bi bi-lock-fill"></i>
              </div>
              <div className="menu-title">Logout</div>
            </a>
          </li>
        </ul>

        <div class="breadcrumb-item active px-4 mt-4 pt-4 mb-4">
          Powered By TopUpBox LLC
        </div>
      </aside >
    );
  }
}

export default SideBar;
