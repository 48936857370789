/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Voucheritem from "./voucheritem";
import { WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";

class Printed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: cookie.load("token"),
      businessName: cookie.load("businessName"),
      vouchers: [],
      readyPrint: false,
      readyVouchers: [],
    };

    this.getVouchers = this.getVouchers.bind(this);
    this.printDocument = this.printDocument.bind(this);
  }

  getVouchers = () => {
    const id = toast.loading("Please wait...");
    axios
      .get(WEB_BASE_URL + "/api/v2/u1/pin-manager", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        res.data.data.reverse();
        this.setState({ vouchers: res.data.data });
        toast.dismiss(id);
      })
      .catch((err) => {
        // toast.update(id, {
        //   render: "" + err,
        //   type: "error",
        //   isLoading: false,
        // });
        // this.props.history.push("/signIn");
      });
  };

  printDocument(batchID) {
    axios
      .get(WEB_BASE_URL + "/api/v2/u1/print-voucher?batchId=" + batchID, {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        this.setState({ readyPrint: true, readyVouchers: res.data.data });
        console.log(this.state.readyVouchers);
        const input = document.getElementById("pdfdiv");
        html2canvas(input).then((canvas) => {
          var imgWidth = 200;
          var pageHeight = 290;
          var imgHeight = (canvas.height * imgWidth) / canvas.width;
          var heightLeft = imgHeight;
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          var position = 0;
          var heightLeft = imgHeight;
          pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
          pdf.save("download.pdf");
          this.setState({ readyPrint: false, readyVouchers: [] });
        });
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      });
  }

  componentDidMount() {
    //this.getVouchers();
  }

  render() {
    const printDiv = {
      display: this.state.readyPrint ? "block" : "none",
    };
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  View Orders
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button type="button" className="btn btn-primary">
                Wallet Balance: &#8358;200
              </button>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header py-3">
            <div class="row g-3">
              <div class="col-lg-3 col-md-6 me-auto">
                <div class="ms-auto position-relative">
                  <div class="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i class="bi bi-search"></i>
                  </div>
                  <input
                    class="form-control ps-5"
                    type="text"
                    placeholder="Search Payment"
                  />
                </div>
              </div>

              <div class="col-lg-2 col-6 col-md-3">
                <select class="form-select">
                  <option>Show 10</option>
                  <option>Show 30</option>
                  <option>Show 50</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead class="table-light">
                  <tr>
                    <th>Order ID</th>
                    <th>Description</th>
                    <th>Units</th>
                    <th>Unit Amount</th>
                    <th>Network</th>
                    <th>Printed On </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <div class="d-flex align-items-center gap-3 cursor-pointer">
                        <div class="">
                          <p class="mb-0">Voucher</p>
                        </div>
                      </div>
                    </td>
                    <td>2</td>
                    <td>&#8358; 200</td>
                    <td>MTN</td>
                    <td>13-3-2022</td>
                  </tr>

                  {this.state.vouchers.map((voucher) => (
                    <tr>
                      <td>{voucher.id}</td>
                      <td>
                        <div class="d-flex align-items-center gap-3 cursor-pointer">
                          <div class="">
                            <p class="mb-0">{voucher.description}</p>
                          </div>
                        </div>
                      </td>
                      <td>{voucher.unit}</td>
                      <td>&#8358;{voucher.value}</td>
                      <td>
                        {voucher.decrypted ? (
                          <span class="badge rounded-pill alert-error">
                            Printed
                          </span>
                        ) : (
                          <span class="badge rounded-pill alert-success">
                            Not Printed
                          </span>
                        )}
                      </td>
                      <td>MTN</td>
                      <td>{voucher.dateCreated.split("T")[0]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <nav class="float-end mt-3">
              <ul class="pagination">
                <li class="page-item disabled">
                  <a class="page-link" href="#">
                    Previous
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div id="pdfdiv" style={printDiv}>
            <div className="row g-3">
              {this.state.readyVouchers.map((voucher, index) => (
                <>
                  <Voucheritem
                    id={voucher.id}
                    pin={voucher.pin}
                    serial={voucher.serialNo}
                    dateCreated={voucher.dateCreated.split("T")[0]}
                  ></Voucheritem>
                  {index % 2 === 0 ? <></> : <div class="w-100"></div>}
                </>
              ))}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Printed;
