import React from "react";
import Modal from "./modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "../../unified/webbase";
import EditUser from "./editUserSpd";
class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      userEmail: cookie.load("user"),
      token: cookie.load("token"),
      users: [],
      roles: [],
      pin: [],
      formRole: "ADMINISTRATOR",
    };

    this.onInviteUser = this.onInviteUser.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.getAllowedRoles = this.getAllowedRoles.bind(this);
    this.setPin = this.setPin.bind(this);
    this.reInvite = this.reInvite.bind(this);
    this.editSPD = this.editSPD.bind(this);
  }

  onInviteUser(event, email) {
    event.preventDefault();
    if (this.state.formEmail !== "" && this.state.formRole !== "") {
      const id = toast.loading("Please wait...");
      axios
        .post(
          WEB_BASE_URL + "/onboarding/change-role",
          {
            emailAddress: email,
            newRole: this.state.formRole,
            transactionPin: this.state.pin,
          },
          {
            headers: {
              authorization: this.state.token,
            },
          }
        )
        .then((res) => {
          console.log({
            emailAddress: email,
            newRole: this.state.formRole,
            transactionPin: this.state.pin,
          });
          if (res.data.status == "2000") {
            toast.update(id, {
              render: "Role changed successfully",
              type: "success",
              isLoading: false,
            });
          } else {
            toast.update(id, {
              render: "" + res.data.description,
              type: "error",
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          toast.update(id, {
            render: "" + err,
            type: "error",
            isLoading: false,
          });
        });
    } else {
      toast.error("User Role Edit failed!!!");
    }
  }

  //get all users
  getUsers() {
    axios
      .get(WEB_BASE_URL + "/onboarding/get-all-users", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        let iflog = res.data.status == 2000 ? true : false;

        if (iflog) {
          this.setState({
            users: res.data.data,
          });
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getAllowedRoles() {
    //get list of roles from backend
    let url = "/api/v2/w2/get-roles";

    axios
      .get(WEB_BASE_URL + url, {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status == "2000") {
          console.log(res.data.data);
          this.setState({
            roles: res.data.data,
          });
        } else {
          console.log(res);
        }
      });
  }
  setPin(event) {
    this.setState({ pin: event.target.value });
  }

  deactivateUser(id) {
    let response = window.confirm(
      "Are you sure you want to deactivate this user?"
    );
    if (response === true) {
      const id = toast.loading("Please wait...");
      axios
        .get(WEB_BASE_URL + "/dealer/deActivate-user?" + id, {
          headers: {
            authorization: this.state.token,
          },
        })
        .then((res) => {
          if (res.data.status == "2000") {
            toast.update(id, {
              render: "User deactivated successfully",
              type: "success",
              isLoading: false,
            });
          } else {
            toast.update(id, {
              render: "" + res.data.description,
              type: "error",
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          toast.update(id, {
            render: "" + err,
            type: "error",
            isLoading: false,
          });
        });
    }
  }

  editSPD(event, table_id, email, spd9MOBILE, spdMTN, spdAIRTEL, spdGLO) {
    event.preventDefault();
    console.log(email);
    const id = toast.loading("Please wait...");
    axios
      .post(
        WEB_BASE_URL + "/dealer/edit-user-spd?id=" + table_id,
        {
          emailAddress: email,
          spdAirtel: spdAIRTEL,
          spdGlo: spdGLO,
          spdMtn: spdMTN,
          spdNineMobile: spd9MOBILE,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "2000") {
          toast.update(id, {
            render: "User Discount Edited successfully",
            type: "success",
            isLoading: false,
          });
        } else {
          console.log(res);
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
      });
  }

  reInvite(email) {
    console.log(email);
    axios
      .post(
        WEB_BASE_URL + "/onboarding/re-invite-user?emailAddress=" + email,
        {
          emailAddress: email,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.status === "2000") {
          toast.success("User Reinvited successfully");
        } else {
          toast.error(res.data.description);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //set state for formRole
  onChangeRole(event) {
    this.setState({
      formRole: event.target.value,
    });
  }

  componentDidMount() {
    this.getUsers();
    this.getAllowedRoles();
  }

  render() {
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  All Users
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <h5 className="mb-0">All Users Under You</h5>
              <form className="ms-auto position-relative">
                <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                  <i className="bi bi-search"></i>
                </div>
                <input
                  className="form-control ps-5"
                  type="text"
                  placeholder="search"
                />
              </form>
            </div>
            <div className="table-responsive mt-3">
              <table className="table align-middle">
                <thead className="table-secondary">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.users.map((user, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="d-flex align-items-center gap-3 cursor-pointer">
                          <img
                            src="assets/images/dummy.png"
                            className="rounded-circle"
                            width="44"
                            height="44"
                            alt=""
                          />
                          <div className="">
                            <p className="mb-0">{user.name}</p>
                          </div>
                        </div>
                      </td>
                      <td>{user.emailAddress}</td>
                      <td>{user.role}</td>
                      <td>{user.status === null ? "Not active" : "Active"}</td>
                      <td>
                        <div className="table-actions d-flex align-items-center gap-3 fs-6">
                          <a
                            href="javascript:;"
                            className="text-warning"
                            data-bs-toggle="modal"
                            data-bs-target={"#editModal" + user.id}
                            title="Edit"
                          >
                            <i className="bi bi-pencil-fill"></i>
                          </a>
                          <a
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target={"#editSPDModal" + user.id}
                            title="Edit"
                          >
                            <i class="bi bi bi-gear-fill"></i>
                          </a>
                          {user.status === null ? (
                            <a
                              href="javascript:void(0);"
                              class="text-success mx-2"
                              title="ReInvite User"
                              data-bs-original-title="ReInvite"
                              aria-label="ReInvite User"
                              onClick={(e) => this.reInvite(user?.emailAddress)}
                            >
                              <i class="bi  bi-arrow-repeat"></i>
                            </a>
                          ) : (
                            <a
                              href="/"
                              className="text-danger mx-2"
                              title="Disable/Deactivate"
                              onClick={(e) => {
                                e.preventDefault();
                                this.deactivateUser(user.id);
                              }}
                            >
                              <i class="bi bi-x-circle-fill"></i>
                            </a>
                          )}
                        </div>
                      </td>
                      <Modal
                        title="Edit User"
                        id={"editModal" + user.id}
                        buttonText="Effect Role Change"
                        onSubmit={this.onInviteUser}
                        onChangeEmail={this.onChangeEmail}
                        onChangeRole={this.onChangeRole}
                        setPin={this.setPin}
                        formEmail={user.emailAddress}
                        roles={this.state.roles}
                        formRole={this.state.formRole}
                      ></Modal>

                      <EditUser
                        title="Edit User"
                        id={"editSPDModal" + user.id}
                        table_id={user.id}
                        buttonText="Effect Discount Change"
                        onSubmit={this.editSPD}
                        onChangeEmail={this.onChangeEmail}
                        onChangeRole={this.onChangeRole}
                        setPin={this.setPin}
                        formEmail={user.emailAddress}
                        roles={this.state.roles}
                        formRole={this.state.formRole}
                      ></EditUser>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Users;
