import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { WEB_BASE_URL } from './webbase';
import axios from 'axios';
import cookie from "react-cookies";
import SetMarchantTreshold from './setMarchantThreshold';
function SetThreshold() {
    const [products, setProducts] = useState({})
    const [value, setValue] = useState('')
    const [valueAmount, setValueAmount] = useState('')
    const token = cookie.load("token")
    
    const [vtu, setVtu] = useState('')
    const [vas, setVas] = useState('')
    const [name, setName] = useState('')
    const [sim, setSim] = useState('')
    const [email, setEmail] = useState('')
    const userRole = cookie.load("userRole")
    const [getMarchantThreshold, setGetMarchantThreshold] = useState([])
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => {
      setModalOpen(true);
    };
  
    const handleModalClose = () => {
      setModalOpen(false);
    };

    useEffect(() => {
       
        let api = "";
        api = "/onboarding/get-treshold";
        axios
            .get(
                WEB_BASE_URL +
                api ,
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            .then((res) => {
                if (res.data.status === "2000" && res.data.data !== null) {
                    console.log(res.data.data);
                   setProducts(res.data.data)
                } else {
                    toast.error("No data found");
                    setProducts([])
                }
            })
            .catch((err) => {
                console.log(WEB_BASE_URL + " " + err);
                toast.error("Error fetching transactions");
            }).finally(() => {
                
            });
    },[ token])
    useEffect(() => {
       
      let api = "";
      api = "/onboarding/merchant-treshold";
      axios
          .get(
              WEB_BASE_URL +
              api ,
              {
                  headers: {
                      authorization: token,
                  },
              }
          )
          .then((res) => {
              if (res.data.status === "2000" && res.data.data !== null) {
                  console.log(res.data.data);
                 setGetMarchantThreshold(res.data.data)
              } else {
                  toast.error("No data found");
                  setProducts([])
              }
          })
          .catch((err) => {
              // console.log(WEB_BASE_URL + " " + err);
              // toast.error("Error fetching transactions");
          }).finally(() => {
              
          });
  },[ token])

  
    const setThresholdValue = (e) =>{
            e.preventDefault()
            const id = toast.loading("Please wait...");
        const valuess = {
            "product": value,
            "treshold": valueAmount
        }

        axios
        .post(WEB_BASE_URL + "/onboarding/set-treshold", valuess,  {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          if (res.data.status === "2000") {
            toast.update(id, {
              render: res.data.description,
              type: "success",
              isLoading: false,
            });
            setTimeout(() => {
            //   toast.dismiss(id);
              window.location.href ="/set-threshold";
            }, 2000);
          } else {
            console.log(res);
            toast.update(id, {
              render: res.data.description,
              type: "error",
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          toast.update(id, {
            render: err,
            type: "error",
            isLoading: false,
          });
        });
    }

    const handleChange = (event) => {
        const selectedProduct = event.target.value;
       if(selectedProduct === 'vtuTreshold'){
        setValueAmount(products?.vtuTreshold)
       }
       else if(selectedProduct === 'tresholdSim'){
        setValueAmount(products?.tresholdSim)
       }
       else  if(selectedProduct === 'vasTreshold'){
        setValueAmount(products?.vasTreshold)
       } else{
        setValueAmount('')
       }
        setValue(selectedProduct);
        
      };

  return (
    <main className="page-content">
         <ToastContainer
         position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
      />
      <SetMarchantTreshold
       open={modalOpen}
        handleClose={handleModalClose}
        email={email}
        vas={vas}
        vtu={vtu}
        sim={sim}
        name={name}
      />
    
       <h3 className=' mb-2'>Set Threshold</h3> 
       <div className=' card  p-4'>
       <div className=' mt-4'>
       <h4 className=' mb-2'>Your Threshold value</h4>
       <div className='row'>
       {userRole ==="DEALER" &&
       <div className=' mb-3 col-3' >
              <h6>Name: Sim Treshold</h6>
              <p>Threshold value: {products?.tresholdSim}</p>
            </div>
       }
           
            <div className=' mb-3 col-3' >
              <h6 >Name:   Vtu Treshold</h6>
              <p>Threshold value: {products?.vtuTreshold}</p>
            </div>
            <div className=' mb-3 col-3' >
              <h6>Name: Vas Treshold</h6>
              <p>Threshold value: {products?.vasTreshold}</p>
            </div>
       </div>
         
       </div>
       <form onSubmit={setThresholdValue} className="row">
       <div className="row">
       <div className="col-12"></div>
       <div className="col-6">
       <div className=' py-3'>
                  <label className="form-label">Products</label>
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    value={value}
                    required
                    onChange={handleChange}
                  >
                    <option value=""> Select Product</option>
                    {userRole ==="DEALER" &&
                    <option value="tresholdSim"> Sim Treshold</option>
                    }
                    <option value="vasTreshold"> Vas Treshold</option>
                    <option value="vtuTreshold"> Vtu Treshold</option>
                    
                  </select>
                </div>
                <div>
                <label className=' mb-2'>Amount :</label>
                <input
                    className="form-control "
                    type="text"
                    required
                    value={valueAmount}
                    onChange={(e) => setValueAmount(e.target.value)}
                    placeholder="Set threshold"
                  />
                </div>
       </div>
       <div className="col-3"></div>
       </div>
       <div className=' row'>
       <div className="col-12"></div>
       <div className="col-3 my-3">
       <div className="ms-auto">
            <div className="btn-group">
              <button type="submit" 
              onClick={setThresholdValue}
              className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
       </div>
       </div>
       </form>
       </div> 

        {userRole === "DEALER" &&
        <div className=' mt-6'>
        <div className=' bg-white mt-4'>
        <div class="table-responsive">
                            
                            <table className="table align-middle mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th>Email address</th>
                                        <th>vtuTreshold</th>
                                        <th>Treshold sim</th>
                                        <th>vasTreshold</th>
                                        <th>BusinessName</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   
                                    {getMarchantThreshold.length !== 0 ? (
                                      getMarchantThreshold.map((voucher, index) => (
                                            <tr key={index}>
                                                <td>{voucher?.emailAddress}</td>
                                                <td>{voucher?.vtuTreshold}</td>
                                                <td>{
                                                    voucher.tresholdSim
                                                }</td>
                                                <td>{voucher?.vasTreshold}</td>
                                                
                                                <td>{voucher?.BusinessName}</td>
                                                <td >
                                                <button type="submit" 
                                                 onClick={ () =>{
                                                  handleModalOpen()
                                                  setSim(voucher.tresholdSim)
                                                  setVas(voucher?.vasTreshold)
                                                  setVtu(voucher?.vtuTreshold)
                                                  setEmail(voucher?.emailAddress)
                                                  setName(voucher?.BusinessName)
                                                 }
                                                 }
                                                  className="btn btn-primary">
                                                      set threshold
                                                  </button>
                                                
                                                </td>

                                                  {/* <AuthModal
                                              title="Enter Authenticator Code"
                                              id={"PrintModal" + voucher.keyId}
                                              batchId={voucher.keyId}
                                              onSubmit={printDocument}
                                              formPin={pin}
                                              setPin={setPin}
                                            ></AuthModal> */}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr colSpan="7">
                                            <td>No Transaction Record.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div> 
            </div>
       </div>
        }
       
    </main>
  )
}

export default SetThreshold