import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "../../unified/webbase";
import EditUser from "./editUserSpd";

class ManageCommission extends React.Component {
    constructor() {
        super();
        this.state = {
            userEmail: cookie.load("user"),
            token: cookie.load("token"),
            users: [],
            roles: [],
            pin: [],
            commisions: [],
             searchQuery: ''
        };

        this.getUsers = this.getUsers.bind(this);
        this.setPin = this.setPin.bind(this)
        this.editSPD = this.editSPD.bind(this);
    }

    getCommission(users) {
        axios
            .get(WEB_BASE_URL + "/dealer/get-commission", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                let iflog = res.data.status == "2000" ? true : false;
                console.log(res.data.data.data)
                if (iflog) {
                    this.setState({
                        commisions: users.map(item => {
                            const matchedItem = res.data.data.data.find(x => x.accountId == item.accountId);
                            return { ...item, ...matchedItem };
                        }),
                    });
                } else {
                    toast.error("Error Fetching Merchants");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error Fetching Merchants:" + err.code);
            });
    }

    //get all users
    getUsers() {
        axios
            .post(WEB_BASE_URL + "/onboarding/all-merchants", {}, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                console.log(res.data.data.data);
                let iflog = res.data.status == "2000" ? true : false;

                if (iflog) {
                    this.getCommission(res.data.data.data)
                } else {
                    toast.error("Error Fetching Merchants");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error Fetching Merchants:" + err.code);
            });
    }


    setPin(event) {
        this.setState({ pin: event.target.value });
    }

    editSPD(event, accountId, table_id, email, spd9MOBILE, spdMTN, spdGLO, spdAIRTEL, pin) {
        event.preventDefault();
        console.log(email);
        const id = toast.loading("Please wait...");
        axios
            .post(
                WEB_BASE_URL + "/dealer/set-commission",
                {
                    airtelDiscount: spdAIRTEL,
                    gloDiscount: spdGLO,
                    mtnDiscount: spdMTN,
                    categoryName: "Merchant",
                    id: table_id,
                    accountId: accountId,
                    nineMobileDiscount: spd9MOBILE,
                    transactionPin: pin,
                },
                {
                    headers: {
                        authorization: this.state.token,
                    },
                }
            )
            .then((res) => {
                if (res.data.status == "2000") {
                    toast.update(id, {
                        render: "User Discount Edited successfully",
                        type: "success",
                        isLoading: false,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1400);
                } else {
                    console.log(res);
                    toast.update(id, {
                        render: "" + res.data.description,
                        type: "error",
                        isLoading: false,
                    });
                }
            })
            .catch((err) => {
                toast.update(id, {
                    render: "" + err,
                    type: "error",
                    isLoading: false,
                });
                console.log(
                    {
                        airtelDiscount: spdAIRTEL,
                        gloDiscount: spdGLO,
                        mtnDiscount: spdMTN,
                        categoryName: "Merchant",
                        id: table_id,
                        accountId: accountId,
                        nineMobileDiscount: spd9MOBILE,
                        transactionPin: pin,
                    }
                )
            });
    }

    //set state for formRole
    onChangeRole(event) {
        this.setState({
            formRole: event.target.value,
        });
    }

    componentDidMount() {
        this.getUsers();
    }
    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
      };
    render() {
        const { commisions, searchQuery } = this.state;
        const filteredUsers = commisions.filter((user) =>
            (user && user.businessEmail &&  user.businessEmail.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user && user.businessName &&  user.businessName.toLowerCase().includes(searchQuery.toLowerCase()))
        );
        return (
            <main className="page-content">
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;">
                                        <i className="bx bx-home-alt"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Manage Commissions
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <h5 className="mb-0">All Merchants Under You</h5>
                            <form className="ms-auto position-relative">
                                <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                    <i className="bi bi-search"></i>
                                </div>
                                <input
                                    className="form-control ps-5"
                                    type="text"
                                    placeholder="search"
                                    value={searchQuery}
                                    onChange={this.handleSearchChange}
                                />
                            </form>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table align-middle">
                                <thead className="table-secondary">
                                    <tr>

                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>MTN Dis.</th>
                                        <th>Glo Dis.</th>
                                        <th>Airtel Dis.</th>
                                        <th>9mobile Dis.</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredUsers.map((user, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-flex align-items-center gap-3 cursor-pointer">
                                                    <img
                                                        src="assets/images/dummy.png"
                                                        className="rounded-circle"
                                                        width="44"
                                                        height="44"
                                                        alt=""
                                                    />
                                                    <div className="">
                                                        <p className="mb-0">{user.businessName}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{user?.businessEmail}</td>
                                            <td>%{user?.mtnDiscount ? user?.mtnDiscount : 0}</td>
                                            <td>%{user?.gloDiscount ? user?.gloDiscount : 0}</td>
                                            <td>%{user?.airtelDiscount ? user?.airtelDiscount : 0}</td>
                                            <td>%{user?.nineMobileDiscount ? user?.nineMobileDiscount : 0}</td>
                                            <td>
                                                <div className="table-actions d-flex align-items-center gap-3 fs-6">
                                                    <a
                                                        href="javascript:;"
                                                        data-bs-toggle="modal"
                                                        data-bs-target={"#editSPDModal" + user.id}
                                                        title="Edit"
                                                    >
                                                        <i class="bi bi bi-gear-fill"></i>
                                                    </a>
                                                </div>
                                            </td>
                                            <EditUser
                                                title="Edit User"
                                                id={"editSPDModal" + user.id}
                                                table_id={user.id}
                                                buttonText="Effect Discount Change"
                                                onSubmit={this.editSPD}
                                                accountId={user?.accountId}
                                                onChangeEmail={this.onChangeEmail}
                                                onChangeRole={this.onChangeRole}
                                                setPin={this.setPin}
                                                formEmail={user.businessEmail}
                                                roles={this.state.roles}
                                                formRole={this.state.formRole}
                                            ></EditUser>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default ManageCommission;
