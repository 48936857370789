import React from 'react';
import Header from '../unified/header';
import SideBar from '../unified/SideBar';
import {
    BrowserRouter as Router,
    Routes as Switch,
    Route,
} from "react-router-dom";
import Main from './components/main';
import Transaction from "./components/transaction";
import CreateUser from '../logical/components/CreateUser';
import WalletHistory from './components/WalletHistory';
import Support from '../logical/components/support';
import Users from '../logical/components/users';
import Profile from '../logical/components/profile';
import Compliance from '../logical/components/compliance';
import ManageCommision from './components/manageCommission';
import FundWalletReqHistory from '../logical/components/fundWalletReqHistory';
import ManageMerchants from './components/manageMerchants';
import Fund from './components/fund';
import MakeFund2 from './components/makeFund';
import FundWalletReq from './components/fundWalletReq';
import PaymentRecord from './components/paymentRecord';
import SetThreshold from '../unicard/components/SetThreshold';
import MakeFund from '../enterprise/components/makeFund';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            someKey: 'someValue'
        };
    }

    componentDidMount() {
        this.setState({
            someKey: 'otherValue'
        });
    }

    render() {
        return (<div>
            <Header {...this.props} activeProduct={this.props.activeProduct} setActiveProduct={this.props.setActiveProduct}></Header>
            <SideBar {...this.props} product={this.props.activeProduct} setActiveProduct={this.props.setActiveProduct}></SideBar>
            <Switch>
                <Route
                    exact
                    path="/"
                    key={1}
                    element={<Main {...this.props}></Main>}
                />
                <Route
                    exact
                    key={2}
                    path="/transactions"
                    element={<Transaction {...this.props}></Transaction>}
                />
                <Route
                    exact
                    key={15}
                    path="/payment-records"
                    element={<PaymentRecord {...this.props}></PaymentRecord>}
                />
                <Route
                    exact
                    key={3}
                    path="/profile"
                    element={<Profile {...this.props}></Profile>}
                />
                <Route
                    exact
                    key={4}
                    path="/users"
                    element={<Users {...this.props}></Users>}
                />

                <Route
                    exact
                    key={5}
                    path="/fund"
                    element={<Fund {...this.props}></Fund>}
                />
                <Route
                    exact
                    key={6}
                    path="/support"
                    element={<Support {...this.props}></Support>}
                />
                <Route
                    exact
                    key={7}
                    path="/wallet-history"
                    element={<WalletHistory {...this.props}></WalletHistory>}
                />
                <Route
                    exact
                    key={9}
                    path="/fund-wallet"
                    element={<MakeFund {...this.props}></MakeFund>}
                />
                <Route
                    exact
                    key={10}
                    path="/req-history"
                    element={<FundWalletReqHistory></FundWalletReqHistory>}
                />
                <Route
                    exact
                    key={12}
                    path="/create-users"
                    element={<CreateUser {...this.props}></CreateUser>}
                />
                <Route
                    exact
                    key={14}
                    path="/manage-commissions"
                    element={<ManageCommision {...this.props} />}
                />
                <Route
                    exact
                    key={14}
                    path="/manage-merchants"
                    element={<ManageMerchants {...this.props} />}
                />
                <Route
                    exact
                    key={15}
                    path="/make-fund"
                    element={<MakeFund2 {...this.props} ></MakeFund2>}
                />

                <Route
                    exact
                    key={17}
                    path="/system-config"
                    element={<Compliance walletBalance={this.state.walletBalance} {...this.props} />}
                />
                <Route
                    exact
                    key={25}
                    path="/set-threshold"
                    element={<SetThreshold {...this.props}></SetThreshold>}
                />
            </Switch>
        </div >);
    }


}

export default Index;
