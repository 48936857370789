/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React from "react";
import { WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import AuthModal from "./AuthModal";

class FundWalletReq extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();

    this.state = {
      walletBalance: cookie.load("walletBalance"),
      token: cookie.load("token"),
      userRole: cookie.load("userRole"),
      userGroup: cookie.load("userGroup"),
      wallethistory: [],
      startDate: new Date(y, m, 1),
      endDate: new Date(),
      Pin: "",
      Narration: "",
    };
    //binding this to event-handler functions
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.getWalletHistory = this.getWalletHistory.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.approve = this.approve.bind(this);
    this.decline = this.decline.bind(this);
    this.setPin = this.setPin.bind(this);
    this.setNarration = this.setNarration.bind(this);
  }
  setStartDate(date) {
    this.setState({
      startDate: date,
    });
  }
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  setEndDate(date) {
    this.setState({
      endDate: date,
    });
  }

  approve(trans_id, event) {
    event.preventDefault();
    const id = toast.loading("Please wait...");

    let api = "";

    if (
      this.state.userRole === "MANAGER" &&
      this.state.userGroup == "DEALER"
    ) {
      api = WEB_BASE_URL + "/dealer/approve-fundWallet";
    } else {
      api = WEB_BASE_URL + "/dealer/confirm-fundWallet";
    }

    axios
      .post(
        api,
        {
          remark: this.state.Narration,
          id: trans_id,
          transactionPin: this.state.Pin,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 2000) {
          toast.update(id, {
            render: "Success",
            type: "success",
            isLoading: false,
          });
          this.setState({ Pin: "", Narration: "" });
          this.props.navigate("/fund-wallet-req");
          this.props.navigate(0);
        } else {
          toast.update(id, {
            render: res.data.description + ": " + trans_id,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
      })
      .then(() => {
        setTimeout(() => {
          toast.dismiss(id);
        }, 3000);
      });
  }

  decline(trans_id, event) {
    event.preventDefault();
    const id = toast.loading("Please wait...");
    let api = "";
    api = WEB_BASE_URL + "/dealer/decline-fundWallet";

    axios
      .post(
        api,
        {
          remark: this.state.Narration,
          id: trans_id,
          transactionPin: this.state.Pin,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 2000) {
          toast.update(id, {
            render: "Success",
            type: "success",
            isLoading: false,
          });
          this.setState({ Pin: "", Narration: "" });
          this.props.navigate("/fund-wallet-req");
          this.props.navigate(0);
        } else {
          toast.update(id, {
            render: res.data.description + ": " + trans_id,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
      })
      .then(() => {
        setTimeout(() => {
          toast.dismiss(id);
        }, 3000);
      });
  }

  setPin(event) {
    this.setState({
      Pin: event.target.value,
    });
  }

  getWalletHistory() {
    const startdate = this.state.startDate
      .toLocaleDateString("en-GB")
      .split("/")
      .reverse()
      .join("-");
    const enddate = this.state.endDate
      .toLocaleDateString("en-GB")
      .split("/")
      .reverse()
      .join("-");

    axios
      .get(WEB_BASE_URL + "/dealer/fund-wallet-request", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        res.data.data.reverse();
        this.setState({ wallethistory: res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //set Narration state
  setNarration(event) {
    this.setState({
      Narration: event.target.value,
    });
  }

  componentDidMount() {
    this.getWalletHistory();
  }

  render() {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Fund Wallet Requests
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button type="button" className="btn btn-primary">
                Wallet Balance: &#8358;{this.state.walletBalance}
              </button>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <form className="row">
              <div className="row">
                <div className="col-6">
                  <label className="form-label">Time Frame (FROM):</label>
                  <DatePicker
                    selected={this.state.startDate}
                    onSelect={(date) => this.setStartDate(date)}
                    onChange={(date) => this.setStartDate(date)}
                  />
                </div>
                <div className="col-6">
                  <label className="form-label">Time Frame (TO):</label>
                  <DatePicker
                    selected={this.state.endDate}
                    onSelect={(date) => this.setEndDate(date)}
                    onChange={(date) => this.setEndDate(date)}
                  />
                </div>
              </div>
              <div className="row my-3">
                <div className="col-4">
                  <label className="form-label">Transaction Status</label>
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    value={this.props.formRole}
                    onChange={(event) => this.props.onChangeRole(event)}
                  >
                    <option selected=""> Select Status</option>
                    <option value="1">All</option>
                    <option value="2">Paid</option>
                    <option value="3">Pending</option>
                    <option value="4">Failed</option>
                  </select>
                </div>
                <div className="col-4">
                  <button
                    style={{ marginTop: 29 }}
                    className="btn btn-primary d-flex"
                  >
                    <div>
                      <i class="bi bi-cloud-arrow-down-fill"></i>
                    </div>
                    <div className="mx-3">Download Report</div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="card">
          <div className="card-header py-3">
            <div className="row g-3">
              <div className="col-lg-3 col-6 col-md-3">
                <div className="ms-auto position-relative">
                  <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i className="bi bi-search"></i>
                  </div>
                  <input
                    className="form-control ps-5"
                    type="text"
                    placeholder="Search Payment"
                  />
                </div>
              </div>

              <div className="col-lg-2 col-6 col-md-3">
                <select className="form-select">
                  <option>Show 10</option>
                  <option>Show 30</option>
                  <option>Show 50</option>
                </select>
              </div>
              <div className="col-lg-2 col-6 col-md-3">
                {
                  //get list of items that have status has not approved
                  this.state.wallethistory.filter(
                    (item) => item.status !== "approved"
                  ).length > 0 ? (
                    <></>
                  ) : (
                    <div className="text-center pt-2 text-danger">
                      No New Requests
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="example"
                class="table table-striped table-bordered"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Reference ID</th>
                    <th>Username</th>
                    <th>Sender account Name</th>
                    <th>Bank Name</th>
                    <th>Status</th>
                    <th>Transaction date</th>
                    <th>Transfer Mode</th>
                    <th>Amount</th>
                    {this.state.userGroup === "DEALER" && (
                      <th>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {this.state.wallethistory.map((wallet) =>
                    <>
                      <tr>
                        <td>{wallet.id}</td>
                        <td>

                          {wallet?.username}
                        </td>
                        <td>{wallet.createdBy}</td>
                        <td>{wallet.reciepientAccountName}</td>

                        <td>
                          <span class="badge rounded-pill alert-warning">
                            {wallet.status}
                          </span>
                        </td>
                        <td>{wallet.createdDate.split("T")[0]}</td>
                        <td>{wallet.transferMode}</td>
                        <td> {formatter.format(wallet.amount)}</td>
                        {
                          this.state.userGroup === "DEALER" && (
                            <td>
                              <a
                                href="javascript:void(0);"
                                type="button"
                                class="text-danger mr-2 p-2"
                                data-bs-toggle="modal"
                                data-bs-target={"#AuthModal" + wallet.referenceId}
                              >
                                Confirm
                              </a>
                              <a
                                href="javascript:void(0);"
                                type="button"
                                class="text-danger mr-2 p-2"
                                data-bs-toggle="modal"
                                data-bs-target={
                                  "#AuthModalD" + wallet.referenceId
                                }
                              >
                                Decline
                              </a>
                            </td>
                          )
                        }
                      </tr>
                      <AuthModal
                        title="Enter Authenticator Code"
                        id={"AuthModal" + wallet.referenceId}
                        wallet_id={wallet.id}
                        formNarration={this.state.Narration}
                        setNarration={this.setNarration}
                        formPin={this.state.Pin}
                        setPin={this.setPin}
                        onSubmit={this.approve}
                      ></AuthModal>
                      <AuthModal
                        title="Enter Authenticator Code"
                        id={"AuthModalD" + wallet.referenceId}
                        wallet_id={wallet.id}
                        formNarration={this.state.Narration}
                        setNarration={this.setNarration}
                        formPin={this.state.Pin}
                        setPin={this.setPin}
                        onSubmit={this.decline}
                      ></AuthModal>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default FundWalletReq;
