/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Voucheritem from "./voucheritem";
import { WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import PrintModal from "./printModal";
import { height } from "@mui/system";
import Mtn from "./networkPrint.js/mtn";
import Airtel from "./networkPrint.js/airtel";

class VouchersB extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      walletBalance: cookie.load("walletBalance"),
      token: cookie.load("token"),
      userRole: cookie.load("userRole"),
      sidebar: cookie.load("sidebar") ? cookie.load("sidebar") : [],
      user: cookie.load("user") ? cookie.load("user") : [],
      dealerId: cookie.load("dealerId") ? cookie.load("dealerId") : null,
      userName: cookie.load("userName") ? cookie.load("userName") : null,
      description: cookie.load("description")
        ? cookie.load("description")
        : null,
      businessName: cookie.load("businessName"),
      vouchers: [],
      backup: [],
      readyPrint: false,
      readyVouchers: [],
      units: "",
      pin: "",
      user: {},
      selectedFilePin: "",
    };

    this.getVouchers = this.getVouchers.bind(this);
    this.printDocument = this.printDocument.bind(this);
    this.setUnits = this.setUnits.bind(this);
    this.setPin = this.setPin.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.saveCookies = this.saveCookies.bind(this);
  }

  getVouchers = () => {
    this.refreshToken();
    const id = toast.loading("Please wait...");
    setInterval(() => {
      axios
        .get(WEB_BASE_URL + "/logical/get-voucher", {
          headers: {
            authorization: this.state.token,
          },
        })
        .then((res) => {
          if (res.data.description == "No data available") {
            toast.update(id, {
              render: "No Vouchers Available",
              type: "error",
              isLoading: false,
            });
          } else {
            this.refreshToken();
            console.log(res.data);
            res.data.data.reverse();
            const filteredData = res.data.data.filter(
              (item) => item.orderStatus !== "executed"
            );
            this.setState({ vouchers: filteredData, backup: filteredData });
            toast.dismiss(id);
          }
        })
        .catch((err) => {
          toast.update(id, {
            render: "" + err,
            type: "error",
            isLoading: false,
          });
          this.props.history.push("/signIn");
        });
    }, 10000);
  };

  printDocument(batchID, description) {
    const id = toast.loading("Please wait...");
    this.setState({
      selectedFilePin: this.state.pin,
    });

    axios
      .post(
        WEB_BASE_URL + "/logical/print-voucher",
        {
          transactionPin: this.state.pin,
          requestId: batchID,
        },
        {
          timeout: 1800000,
          maxContentLength: 1000000000,
          maxBodyLength: 1000000000,
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status == "2000") {
          this.refreshToken();
          if (res.data.data !== null) {
            this.setState({
              readyPrint: true,
              readyVouchers: res.data.data,
            });
            toast.update(id, {
              render: "Preparing Document...",
              type: "warning",
              isLoading: true,
            });
            setTimeout(() => {
              this.performPrint(id, this.state.pin, description).then((res) => {
                this.setState({
                  readyPrint: false,
                  readyVouchers: [],
                });

                this.props.navigate("/vouchers-h");
                this.props.navigate(0);
                this.props.navigate(0);
              });
            }, 400);
          } else {
            toast.dismiss(id);
            toast.error(res.data.description);
          }
        } else {
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
        console.log(err);
      });
  }

  getProfile() {
    //toast loading

    axios
      .get(WEB_BASE_URL + "/onboarding/get-user", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        this.setState({
          user: res.data.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  performPrint = async (id, pin, description) => {
    if (this.state.readyVouchers[0].network == "MTN") {
      let print = new Mtn(
        id,
        pin,
        description,
        this.state.readyVouchers,
        this.state.user.bussinessName
      );
      print.Print();
    } else if (this.state.readyVouchers[0].network == "AIRTEL") {
      let print = new Airtel(
        id,
        pin,
        description,
        this.state.readyVouchers,
        this.state.user.bussinessName
      );
      print.Print();
    } else if (
      this.state.readyVouchers[0].network == "NINEMOBILE" ||
      this.state.readyVouchers[0].network == "GLO"
    ) {
      let print = new Airtel(
        id,
        pin,
        description,
        this.state.readyVouchers,
        this.state.user.bussinessName
      );
      print.Print();
    }
  };

  setUnits(e) {
    this.setState({ units: e });
  }

  setPin(e) {
    this.setState({ pin: e.target.value });
  }

  saveCookies = (token) => {
    cookie.save("token", token, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("user", this.state.user, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("dealerId", this.state.dealerId, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("userName", this.state.userName, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("userRole", this.state.userRole, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("sidebar", this.state.sidebar, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
    cookie.save("description", this.state.description, {
      path: "/",
      expires: new Date(Date.now() + 60 * 16 * 1000),
    });
  };

  refreshToken() {
    //call refresh token post api
    axios
      .post(
        WEB_BASE_URL + "/onboarding/refresh-token",
        {},
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "2000") {
          //  console.log(res);
          this.setState({
            token: res.data.data.Token,
          });

          this.saveCookies(res.data.data.Token);
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          this.props.navigate("/signIn");
          this.props.navigate(0);
        }
        console.log(err);
      });
  }

  componentDidMount() {
    this.getVouchers();
    this.getProfile();
  }

  render() {
    const printDiv = {
      display: this.state.readyPrint ? "block" : "none",
      minWidth: "9.25in",
      maxWidth: "8.25in",
      overflow: "auto",
    };
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Print Vouchers
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button type="button" className="btn btn-primary">
                Wallet Balance:
                {formatter.format(this.state.walletBalance)}
              </button>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header py-3">
            <div class="row g-3">
              <div class="col-lg-3 col-md-6 me-auto">
                <div class="ms-auto position-relative">
                  <div class="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i class="bi bi-search"></i>
                  </div>
                  <input
                    class="form-control ps-5"
                    type="text"
                    placeholder="Search Batch"
                    onChange={(e) => {
                      let search = e.target.value;
                      if (search.length === 0) {
                        this.setState({
                          vouchers: this.state.backup,
                        });
                        return;
                      }
                      let vouchers = [].concat(this.state.vouchers);

                      let filteed = vouchers.filter((voucher) => {
                        return voucher.network
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      });

                      this.setState({
                        vouchers: filteed,
                      });
                    }}
                  />
                </div>
              </div>
              <div class="col-lg-2 col-6 col-md-3">
                <select class="form-select">
                  <option>Show 10</option>
                  <option>Show 30</option>
                  <option>Show 50</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead class="table-light">
                  <tr>
                    <th>Batch ID</th>
                    <th>Request ID</th>
                    <th>Description</th>
                    <th>Units</th>
                    <th>Voucher denomination</th>
                    <th>Network</th>
                    <th>Selling Price</th>
                    <th>Created On </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.vouchers.map((voucher) => (
                    <>
                      <tr>
                        <td>{voucher.id}</td>
                        <td>{voucher?.reqDescripionCode}</td>
                        <td>
                          <div class="d-flex align-items-center gap-3 cursor-pointer">
                            <div class="">
                              <p class="mb-0">{voucher.orderDescription}</p>
                            </div>
                          </div>
                        </td>
                        <td>{voucher.units}</td>
                        <td>&#8358;{voucher.unitAmount}</td>
                        <td>{voucher.network}</td>
                        <td>&#8358;{voucher.sellingPrice}</td>

                        <td>
                          {voucher.createdDate
                            ? voucher.createdDate.split("T")[0]
                            : null}
                        </td>
                        <td>
                          <a
                            href="javascript:void(0);"
                            class="text-success"
                            data-bs-toggle="modal"
                            data-bs-target={"#PrintModal" + voucher.id}
                            title="Download"
                            data-bs-original-title="Download"
                            aria-label="Download"
                          >
                            <i class="bi-cloud-arrow-down-fill"></i>
                          </a>
                        </td>
                      </tr>
                      <PrintModal
                        title="Print Batch File"
                        id={"PrintModal" + voucher.id}
                        batchId={voucher.requestId}
                        description={voucher.orderDescription}
                        onSubmit={this.printDocument}
                        pin={this.state.pin}
                        setPin={this.setPin}
                        units={voucher.units}
                        setUnits={this.setUnits}
                      ></PrintModal>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
            <nav class="float-end mt-3">
              <ul class="pagination">
                <li class="page-item disabled">
                  <a class="page-link" href="#">
                    Previous
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div id="pdfdiv" style={printDiv}>
            <div className="row g-1">
              {this.state.readyVouchers.map((voucher, index) =>
                voucher.unitAmount == 100 || voucher.unitAmount == 200 ? (
                  <>
                    <Voucheritem
                      id={index}
                      pin={voucher.pin}
                      pin3={voucher.pin3}
                      serial={voucher.serial}
                      sequence={voucher.sequence}
                      network={voucher.network}
                      orderDescription={voucher.orderDescription}
                      unitAmount={voucher.unitAmount}
                      dateCreated={voucher.expiration}
                      businessName={this.state.user.bussinessName}
                    ></Voucheritem>
                    {(index + 1) % 40 === 0 ? (
                      <div class="w-100 my-4"></div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <Voucheritem
                      id={index}
                      pin={voucher.pin}
                      pin3={voucher.pin3}
                      serial={voucher.serial}
                      sequence={voucher.sequence}
                      network={voucher.network}
                      unitAmount={voucher.unitAmount}
                      orderDescription={voucher.orderDescription}
                      dateCreated={voucher.expiration}
                      businessName={this.state.user.bussinessName}
                    ></Voucheritem>
                    {(index + 1) % 20 === 0 ? (
                      <div class="w-100 my-4"></div>
                    ) : (
                      <></>
                    )}
                  </>
                )
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default VouchersB;
