import React from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Select from "react-select";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "../../unified/webbase";



class AddMtnAxis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            msisdn: "",
            pin: "",
            walletBalance: cookie.load("walletBalance"),
            networkOptions: [
                { value: "MTN", label: "MTN" },
                { value: "AIRTEL", label: "AIRTEL" },
                { value: "GLO", label: "GLO" },
                { value: "NINEMOBILE", label: "9MOBILE" },
            ],
            unitAmountOptions: [
                { value: "100", label: "100" },
                { value: "200", label: "200" },
                { value: "500", label: "500" },
                { value: "1000", label: "1000" },
            ],
            users: [],
            businessName: cookie.load("businessName"),
            token: cookie.load("token"),
            MtnAxis: [],
            username: "",
            password: "",
            passwordShow: false,
        };
        this.onSave = this.onSave.bind(this);
        this.setId = this.setId.bind(this);
        this.getMTNAxis = this.getMTNAxis.bind(this);
        this.setMsisdn = this.setMsisdn.bind(this);
        this.setPin = this.setPin.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.setUsername = this.setUsername.bind(this);
    }

    onSave(event) {
        event.preventDefault();
        const id = toast.loading("Please wait...");
        axios
            .post(WEB_BASE_URL + "/dealer/new-mtn-axis", {
                "msisdn": this.state.msisdn,
                "password": this.state.password,
                "transactionPin": this.state.pin
            }, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.status === "2000") {
                    toast.update(id, {
                        render: "" + res.data.description,
                        type: "success",
                        isLoading: false,
                    });
                    setTimeout(() => {
                        toast.dismiss(id);
                        this.props.navigate(0);
                    }, 2000);
                } else {
                    console.log(res);
                    toast.update(id, {
                        render: "" + res.data.description,
                        type: "error",
                        isLoading: false,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                toast.dismiss(id);
                toast.error("An error occured while setting MTN Axis")
            });
    }

    getMTNAxis() {
        axios
            .get(WEB_BASE_URL + "/manage/all-mtn-msisdn", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                console.log(res.data.data.data);
                let iflog = res.data.status == "2000" ? true : false;

                if (iflog) {
                    this.setState({
                        MtnAxis: res.data.data.data,
                    });
                } else {
                    toast.error("Error Fetching MTN axis");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error Fetching MTN axis:" + err.code);
            });
    }

    getUsers() {
        axios
            .post(WEB_BASE_URL + "/onboarding/all-merchants", {

            }, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                console.log(res.data.data.data);
                let iflog = res.data.status == 2000 ? true : false;

                if (iflog) {
                    this.setState({
                        users: res.data.data.data,
                    });
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //write setter function
    setId(e) {
        this.setState({
            id: e.target.value,
        });
    }


    setPin(e) {
        this.setState({
            pin: e.target.value,
        });
    }

    setUsername(e) {
        this.setState({
            username: e.target.value,
        });
    }
    setPassword(e) {
        this.setState({
            password: e.target.value,
        });
    }


    setMsisdn(e) {
        this.setState({
            msisdn: e.target.value,
        });
    }

    render() {
        const customStyles = {
            menu: (provided, state) => ({
                ...provided,
                borderBottom: "1px dotted pink",
                color: state.selectProps.menuColor,
                padding: 20,
            }),

            control: (provided, { selectProps: { width } }) => ({
                ...provided,
                borderRadius: "25px",
                padding: "2px 4px",
                width: width,
                minWidth: "310px",
            }),
        };
        return (
            <main className="page-content">
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;">
                                        <i className="bx bx-home-alt"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Add MTN Axis
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">
                        <div className="btn-group">
                            <button
                                type="button"
                                className="btn btn-primary"
                            >
                                Wallet Balance : &#8358; {this.state.walletBalance}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form
                            autocomplete="off"
                            class="row g-3"
                            onSubmit={(event) => {
                                this.onSave(event);
                            }}
                        >
                            <div class="col-6">
                                <label class="form-label">MSISDN</label>
                                <input
                                    type="number"
                                    min="0"
                                    name="pin"
                                    class="form-control"
                                    value={this.state.msisdn}
                                    onChange={(event) => this.setMsisdn(event)}
                                    required
                                />
                            </div>
                            <div class="col-6">
                                <label class="form-label">Password</label>
                                <div class="input-group" id="show_hide_password">
                                    <input
                                        type={this.state.passwordShow ? "text" : "password"}
                                        name="pin"
                                        class="form-control"
                                        value={this.state.password}
                                        onChange={(event) => this.setPassword(event)}
                                        required
                                    />
                                    <div class="input-group-addon" style={{
                                        cursor: "pointer",
                                        position: "absolute",
                                        top: 10,
                                        right: 15,
                                    }}>
                                        <div
                                            onClick={e => {
                                                e.preventDefault()
                                                this.setState({
                                                    passwordShow: !this.state.passwordShow
                                                })
                                            }}
                                        >
                                            {this.state.passwordShow ? <i class="bi bi-eye-slash-fill" aria-hidden="true"></i> : <i class="bi bi-eye-fill"></i>}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label class="form-label">Authenticator Code</label>
                                <input
                                    type="number"
                                    min="0"
                                    name="pin"
                                    class="form-control"
                                    value={this.state.pin}
                                    onChange={(event) => this.setPin(event)}
                                    required
                                />
                            </div>

                            <div class="text-start">
                                <input
                                    type="Submit"
                                    class="btn btn-primary px-4"
                                    value="Submit"
                                />
                            </div>
                        </form>
                    </div>
                </div >
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <h5 className="mb-0">All MTN MSISDNs</h5>
                            <form className="ms-auto position-relative">
                                <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                    <i className="bi bi-search"></i>
                                </div>
                                <input
                                    className="form-control ps-5"
                                    type="text"
                                    placeholder="search"
                                />
                            </form>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table align-middle">
                                <thead className="table-secondary">
                                    <tr>

                                        <th>ID</th>
                                        <th>Dealer ID</th>
                                        <th>MSISDN</th>
                                        <th>USER ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.MtnAxis.map((user, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-flex align-items-center gap-3 cursor-pointer">
                                                    <div className="">
                                                        <p className="mb-0">{user.id}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{user?.dealerId}</td>
                                            <td>{user?.msisdn}</td>
                                            <td>{user?.userId}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main >
        );
    }

    componentDidMount() {
        this.getMTNAxis()
        this.getUsers();
    }
}

export default AddMtnAxis;
