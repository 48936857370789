import React from "react";
import axios from "axios";
import { WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { faker } from '@faker-js/faker';
import { Line } from 'react-chartjs-2';
class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      token: cookie.load("token"),
      businessName: cookie.load("businessName"),
      walletBalance: 0,
      vouchers: [],
      boughtvouchers: [],
      userRole: cookie.load("userRole"),
      networks: [],
      dailyReport: [],
      avaliableVouchers: {},
    };
    this.getVouchers = this.getVouchers.bind(this);
    this.getWalletBalance = this.getWalletBalance.bind(this);
    this.getBoughtVouchers = this.getBoughtVouchers.bind(this);
    this.getNetworks = this.getNetworks.bind(this);
    this.getDailyReport = this.getDailyReport.bind(this);
    this.getAvaliable = this.getAvaliable.bind(this);

    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
  }

  getVouchers = () => {
    axios
      .get(WEB_BASE_URL + "/dealer/planned-order", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.data !== null) {
          res.data.data.reverse();
          this.setState({ vouchers: res.data.data });
        }
      })
      .catch((err) => {
        console.log(this.state.token);
        toast.error(err);
      });
  };

  getWalletBalance() {
    axios
      .get(WEB_BASE_URL + "/onboarding/account-balance", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        cookie.save("walletBalance", res.data.data.unicardBalance, {
          path: "/",
          expires: new Date(Date.now() + 60 * 30 * 1000),
        });

        this.setState({ walletBalance: res.data.data.unicardBalance });
      })
      .catch((err) => { console.log(WEB_BASE_URL + " " + err); })
  }

  getBoughtVouchers = () => {
    axios
      .get(WEB_BASE_URL + "/logical/get-voucher", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.description == "No data available") {
        } else {
          res.data.data.reverse();
          this.setState({ boughtvouchers: res.data.data });
        }
      })
      .catch((err) => { console.log(WEB_BASE_URL + " " + err); });
  };

  getNetworks() {
    axios
      .get(WEB_BASE_URL + "/onboarding/get-network", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status !== "403") {
          let networks = res.data.data.map((network) => {
            return { name: network.network, status: network.status };
          });
          if (networks.length === 0) {
            toast.error("No Networks Found, Please Setup Config");
          }
          this.setState({ networks: networks });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  getDailyReport() {
    axios
      .get(WEB_BASE_URL + "/logical/daily-report", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000" && res.data.data !== null) {

          this.setState({ dailyReport: res.data.data });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  getAvaliable() {
    axios
      .get(WEB_BASE_URL + "/logical/available-voucher", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000" && res.data.data !== null) {
          this.setState({ avaliableVouchers: res.data.data });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  componentDidMount() {
    const user = cookie.load("user");
    const userRole = cookie.load("userRole");
    if (user !== undefined) {
      this.getWalletBalance();
      this.getBoughtVouchers();
      if (
        userRole === "DEALER" ||
        userRole === "MANAGER" ||
        userRole === "ADMINISTRATOR" ||
        userRole === "ACCOUNT_OFFICER"
      ) {
        this.getVouchers();
        this.getDailyReport();
        this.getNetworks();
        this.getAvaliable();
      }
    }
  }

  render() {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: false,
          text: 'Chart.js Line Chart',
        },
      },
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: {
            display: false
          }
        }
      }
    };
    const data = {
      labels,
      datasets: [
        {
          label: 'MTN',
          data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: '9Mobile',
          data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };
    return (
      <div className="page-content">
        < ToastContainer
          position="top-center"
          autoClose={false}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="row">
          <div className="col-md-8">
            <div className="card overflow-hidden radius-10">
              <div className="card-body" style={{
                minHeight: "30vh",
                maxHeight: "40vh"
              }}>
                <Line options={options} data={data} />
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            {
              this.state.userRole === "DEALER" ||
                this.state.userRole === "ADMINISTRATOR" ||
                this.state.userRole === "MANAGER" ||
                this.state.userRole === "ACCOUNT_OFFICER" ? (
                // planned Orders
                <div className="row row-cols-1 row-cols-lg-2">
                  <div className="col-md-6">
                    <div className="card overflow-hidden radius-10">
                      <div className="card-body">
                        <div className=" align-items-stretch justify-content-between overflow-hidden pb-1">
                          <div className="">
                            <img
                              src="assets/images/dummy.png"
                              style={{
                                height: "35px",
                                width: "auto",
                                borderRadius: "32px",
                                marginBottom: "5px"
                              }}
                              alt=""
                            />
                            <div className="">
                              <p className="mb-1 text-success">funds</p>
                            </div>
                            <div className="mb-1">Wallet Balance</div>

                            <h5 className="" style={{
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>
                              {formatter.format(this.state.walletBalance)}
                            </h5>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card overflow-hidden radius-10">
                      <div className="card-body">
                        <div className="align-items-stretch justify-content-between overflow-hidden">
                          <div className="">
                            <img
                              src="assets/images/dummy.png"
                              style={{
                                height: "35px",
                                width: "auto",
                                borderRadius: "32px",
                                marginBottom: "5px"
                              }}
                              alt=""
                            />
                            <div className="">
                              <p className="mb-1 text-danger">
                                your stocks
                              </p>
                            </div>
                            <div className="mb-1">Planned Orders</div>
                            <h5 className="" style={{
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>{this.state.vouchers.length}</h5>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card overflow-hidden radius-10">
                      <div className="card-body">
                        <div className="align-items-stretch justify-content-between overflow-hidden">
                          <div className="">
                            <img
                              src="assets/images/dummy.png"
                              style={{
                                height: "35px",
                                width: "auto",
                                borderRadius: "32px",
                                marginBottom: "5px"
                              }}
                              alt=""
                            />
                            <div className="">
                              <p className="mb-1 text-success">
                                your sales
                              </p>
                              <div id="chart4"></div>
                            </div>
                            <div className="mb-1">Funding Requests</div>
                            <h5 className="" style={{
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>0</h5>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card overflow-hidden radius-10">
                      <div className="card-body">
                        <div className="align-items-stretch justify-content-between overflow-hidden">
                          <div className="">
                            <img
                              src="assets/images/dummy.png"
                              style={{
                                height: "35px",
                                width: "auto",
                                borderRadius: "32px",
                                marginBottom: "5px"
                              }}
                              alt=""
                            />
                            <div className="">
                              <p className="mb-1 text-success">your flow</p>
                              <div id="chart4"></div>
                            </div>
                            <div className="mb-1">Transactions</div>

                            <h5 className="" style={{
                              fontSize: "14px",
                              fontWeight: "600",
                            }}>0</h5>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              ) : (
                <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-2">
                  <div className="col">
                    <div className="card overflow-hidden radius-10">
                      <div className="card-body">
                        <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                          <div className="">
                            <p>Vouchers</p>
                            <div className="mb-1 text-danger">
                              your stocks
                            </div>
                            <h4 className="">{this.state.boughtvouchers.length}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card overflow-hidden radius-10">
                      <div className="card-body">
                        <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                          <div className="">
                            <p>Printed Vouchers</p>
                            <div className="mb-1 text-success">
                              your sales
                            </div>
                            <h4 className="">0</h4>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>

        {
          this.state.userRole === "DEALER" ||
            this.state.userRole === "ADMINISTRATOR" ||
            this.state.userRole === "MANAGER" ||
            this.state.userRole === "ACCOUNT_OFFICER" ? (
            // planned Orders
            <>
              <div className="row">
                <div className="col-12 col-lg-12 col-xl-12 d-flex">
                  <div className="card radius-10 w-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <h6 className="mb-0">Recent Planned Orders</h6>
                        <div className="fs-5 ms-auto dropdown">
                          <div
                            className="dropdown-toggle dropdown-toggle-nocaret cursor-pointer"
                            data-bs-toggle="dropdown"
                          >
                            <i className="bi bi-three-dots"></i>
                          </div>
                          <ul className="dropdown-menu">
                            <li>
                              <a className="dropdown-item" href="#">
                                Action
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                Another action
                              </a>
                            </li>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                Something else here
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="table-responsive mt-2">
                        <table className="table align-middle mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Batch ID</th>
                              <th>Description</th>
                              <th>Unit Quantity</th>
                              <th>Network</th>
                              <th>Voucher denomination</th>
                              <th>Cost Price</th>
                              <th>Total Amount</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.vouchers.map((voucher) => (
                              <tr>
                                <td>{voucher.id}</td>
                                <td>
                                  <div className="d-flex align-items-center gap-3">
                                    <div className="product-info">
                                      <h6 className="product-name mb-1">
                                        {voucher.orderDescription}
                                      </h6>
                                    </div>
                                  </div>
                                </td>
                                <td>{voucher.units}</td>
                                <td>{voucher.network}</td>
                                <td>{formatter.format(voucher.unitAmount)}</td>
                                <td>{voucher.costPrice}</td>
                                <td>{formatter.format(voucher.totalAmount)}</td>
                                <td>
                                  {voucher.dateInitiated !== null
                                    ? voucher.dateInitiated.split("T")[0]
                                    : 0}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            //Bought Vouchers
            <></>
          )
        }
        <div className="row">
          <div className="col-12 col-lg-12 col-xl-12 d-flex">
            <div className="card radius-10 w-100">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <h6 className="mb-0">Recent Bought Vouchers</h6>
                  <div className="fs-5 ms-auto dropdown">
                    <div
                      className="dropdown-toggle dropdown-toggle-nocaret cursor-pointer"
                      data-bs-toggle="dropdown"
                    >
                      <i className="bi bi-three-dots"></i>
                    </div>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-responsive mt-2">
                  <table className="table align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Batch ID</th>
                        <th>Description</th>
                        <th>Unit Quantity</th>
                        <th>Network</th>
                        <th>Voucher denomination</th>
                        <th>Selling Price</th>
                        <th>Total Amount</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.boughtvouchers.length !== 0 ? (
                        this.state.boughtvouchers.map((voucher) => (
                          <tr>
                            <td>{voucher.id}</td>
                            <td>
                              <div className="d-flex align-items-center gap-3">
                                <div className="product-info">
                                  <h6 className="product-name mb-1">
                                    {voucher.orderDescription}
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td>{voucher.units}</td>
                            <td>{voucher.network}</td>
                            <td>{formatter.format(voucher.unitAmount)}</td>
                            <td>{voucher.sellingPrice}</td>
                            <td>{formatter.format(voucher.totalAmount)}</td>
                            <td>
                              {voucher.createdDate !== undefined
                                ? voucher.createdDate.split("T")[0]
                                : 0}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr colspan="7">
                          <td>No Bought Vouchers</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default Main;
