import React from "react";

class Footer extends React.Component {
  constructor() {
    super();
    const date = new Date(),
      y = date.getFullYear();
    this.state = {
      year: y,
    };
  }

  render() {
    return (
      <div
        class="breadcrumb-item active mt-3 "
        style={{
          width: "100%",
          position: "fixed",
          background: "#fff",
          paddingBottom: "10px",
          bottom: 0,
          textAlign: "center",
        }}
      >
        Copyright © {this.state.year} TopUpBox Dashboard LLC. All rights
        reserved.
      </div>
    );
  }

  componentDidMount() { }
}

export default Footer;
