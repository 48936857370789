/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Voucheritem from "./voucheritem";
import { WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import UploadModal from "./UploadModal";

class Vouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      walletBalance: cookie.load("walletBalance"),
      token: cookie.load("token"),
      businessName: cookie.load("businessName"),
      vouchers: [],
      readyPrint: false,
      backup: [],
      readyVouchers: [],
      selectedDescription: "Voucher",
      selectedNetwork: "MTN",
      hasError: false,
      userRole: cookie.load("userRole"),
    };

    this._Mounted = false;
    this.getVouchers = this.getVouchers.bind(this);
  }

  getVouchers = () => {
    const id = toast.loading("Please wait...");
    axios
      .get(WEB_BASE_URL + "/dealer/planned-order", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.data !== null) {
          console.log(res.data.data);
          res.data.data.reverse();
          //filter data to only those whose status is Unexecuted
          const filteredData = res.data.data.filter(
            (item) => item.orderStatus !== "executed"
          );
          this.setState({ vouchers: filteredData, backup: filteredData });
        }
        toast.dismiss(id);
      })
      .catch((err) => {
        console.log(err);
        toast.update(id, {
          render: "Error fetching vouchers",
          type: "error",
          isLoading: false,
        });
        toast.dismiss(id);
      });
  };

  componentDidMount() {
    if (this._Mounted) {
    } else {
      this.getVouchers();
      this._Mounted = true;
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
  }

  render() {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    const printDiv = {
      display: this.state.readyPrint ? "block" : "none",
    };
    try {
      // Block of code to try
      return (
        <main className="page-content">
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <a href="javascript:;">
                      <i className="bx bx-home-alt"></i>
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Execute Orders
                  </li>
                </ol>
              </nav>
            </div>
            <div className="ms-auto">
              <div className="btn-group">
                <button type="button" className="btn btn-primary">
                  Wallet Balance:
                  {formatter.format(this.state.walletBalance)}
                </button>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header py-3">
              <div class="row g-3">
                <div class="col-lg-3 col-md-6 me-auto">
                  <div class="ms-auto position-relative">
                    <div class="position-absolute top-50 translate-middle-y search-icon px-3">
                      <i class="bi bi-search"></i>
                    </div>
                    <input
                      class="form-control ps-5"
                      type="text"
                      placeholder="Search batch "
                      onChange={(e) => {
                        let search = e.target.value;
                        if (search.length === 0) {
                          this.setState({
                            vouchers: this.state.backup,
                          });
                          return;
                        }
                        let vouchers = [].concat(this.state.vouchers);

                        let filteed = vouchers.filter((voucher) => {
                          return voucher.network
                            .toLowerCase()
                            .includes(search.toLowerCase());
                        });

                        this.setState({
                          vouchers: filteed,
                        });
                      }}
                    />
                  </div>
                </div>
                <div class="col-lg-2 col-6 col-md-3">
                  <select class="form-select">
                    <option>Status</option>
                    <option>Active</option>
                    <option>Disabled</option>
                    <option>Pending</option>
                    <option>Show All</option>
                  </select>
                </div>
                <div class="col-lg-2 col-6 col-md-3">
                  <select class="form-select">
                    <option>Show 10</option>
                    <option>Show 30</option>
                    <option>Show 50</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table align-middle mb-0">
                  <thead class="table-light">
                    <tr>
                      <th>Order ID</th>
                      <th>Description</th>
                      <th>Units</th>
                      <th>Voucher denomination</th>
                      <th>Status</th>
                      <th>Network</th>
                      <th>Created On </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.vouchers.map((voucher, index) => (
                      <tr>
                        <td>{voucher.orderId}</td>
                        <td>
                          <div class="d-flex align-items-center gap-3 cursor-pointer">
                            <div class="">
                              <p class="mb-0">{voucher.orderDescription}</p>
                            </div>
                          </div>
                        </td>
                        <td>{voucher.units}</td>
                        <td>&#8358;{voucher.unitAmount}</td>
                        <td>
                          {voucher.orderStatus === "executed" ? (
                            <span class="badge rounded-pill alert-success">
                              Executed
                            </span>
                          ) : (
                            <span class="badge rounded-pill alert-danger">
                              Unexecuted
                            </span>
                          )}
                        </td>
                        <td>{voucher.network}</td>
                        <td>
                          {voucher.dateInitiated !== null
                            ? voucher.dateInitiated.split("T")[0]
                            : 0}
                        </td>
                        <td>
                          {this.state.userRole === "DEALER" ||
                            this.state.userRole === "ADMINISTRATOR" ? (
                            <div class="d-flex align-items-center gap-3 fs-6">
                              <a
                                href="javascript:void(0);"
                                class="text-success"
                                data-bs-toggle="modal"
                                data-bs-target={"#UploadModal" + voucher.id}
                                title="Upload"
                                data-bs-original-title="Upload"
                                aria-label="Upload"
                              >
                                <i class="bi bi-upload"></i>
                              </a>
                            </div>
                          ) : (
                            <>
                              <a
                                href="javascript:void(0);"
                                class="text-success"
                              >
                                <i class="bi bi-dash-circle-fill"></i>
                              </a>
                            </>
                          )}
                        </td>
                        <UploadModal
                          title="Upload Batch File"
                          id={"UploadModal" + voucher.id}
                          batchId={voucher.orderId}
                          onSubmit={this.onSubmit}
                          formDescription={voucher.orderDescription}
                          formNetwork={voucher.network}
                          navigate={this.props.navigate}
                        ></UploadModal>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <nav class="float-end mt-3">
                <ul class="pagination">
                  <li class="page-item disabled">
                    <a class="page-link" href="#">
                      Previous
                    </a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <div id="pdfdiv" style={printDiv}>
              <div className="row g-3">
                {this.state.readyVouchers.map((voucher, index) => (
                  <>
                    <Voucheritem
                      id={voucher.id}
                      pin={voucher.pin}
                      serial={voucher.serialNo}
                      dateCreated={voucher.dateCreated.split("T")[0]}
                    ></Voucheritem>
                    {index % 2 === 0 ? <></> : <div class="w-100"></div>}
                  </>
                ))}
              </div>
            </div>
          </div>
        </main>
      );
    } catch (err) {
      // Block of code to handle errors
      console.log(err);
      return (
        <main className="page-content">
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <a href="javascript:;">
                      <i className="bx bx-home-alt"></i>
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Execute Orders
                  </li>
                </ol>
              </nav>
            </div>
            <div className="ms-auto">
              <div className="btn-group">
                <button type="button" className="btn btn-primary">
                  Wallet Balance: &#8358;200
                </button>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header py-3">
              <div class="row g-3">
                <div class="col-lg-3 col-md-6 me-auto">
                  <div class="ms-auto position-relative">
                    <div class="position-absolute top-50 translate-middle-y search-icon px-3">
                      <i class="bi bi-search"></i>
                    </div>
                    <input
                      class="form-control ps-5"
                      type="text"
                      placeholder="Search Payment"
                    />
                  </div>
                </div>
                <div class="col-lg-2 col-6 col-md-3">
                  <select class="form-select">
                    <option>Status</option>
                    <option>Active</option>
                    <option>Disabled</option>
                    <option>Pending</option>
                    <option>Show All</option>
                  </select>
                </div>
                <div class="col-lg-2 col-6 col-md-3">
                  <select class="form-select">
                    <option>Show 10</option>
                    <option>Show 30</option>
                    <option>Show 50</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <div></div>
              </div>
            </div>
          </div>
        </main>
      );
    }
  }
}

export default Vouchers;
