import React from "react";
import cookie from "react-cookies";
import Switch from "react-switch";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: cookie.load("token"),
      userRole: cookie.load("userRole"),
      userName: cookie.load("userName"),
      selectedProduct: cookie.load("activeProduct"),
      vasState: cookie.load("vasState"),
    };
    this.onChangeSelect = this.onChangeSelect.bind(this);

  }

  componentDidMount() {
  }

  componentDidUpdate(prevState) {
    if (prevState.vasState !== this.state.vasState) {

    }

  }


  onChangeSelect(product) {
    if (product != "logical") {
      this.setState({
        selectedProduct: product,
      });
      this.props.setActiveProduct(product)
      this.props.navigate("/");
    }
    else { window.open('https://logical.topupbox.com?token=' + this.state.token, '_blank', 'noreferrer'); }
  }

  render() {
    return (
      <header className="top-header bg-white">
        <nav className="navbar navbar-expand gap-3 bg-white">
          <div className="mobile-toggle-icon fs-3">
            <i className="bi bi-list"></i>
          </div>
          <div>Dashboard</div>
          <div className="top-navbar-right ms-auto">
            <ul className="navbar-nav align-items-center">
              <li className="nav-item search-toggle-icon">
                <a className="nav-link" href="#">
                  <div className="">
                    <i className="bi bi-search"></i>
                  </div>
                </a>
              </li>
              <li className="nav-item dropdown dropdown-large">
                <a
                  className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <div className="">
                    <select
                      id="productSelect"
                      className="form-select"
                      value={this.props.activeProduct}
                      style={{
                        backgroundColor: "#E9EAEC50",
                        borderRadius: "48px",
                        fontWeight: "600"
                      }}
                      onChange={(event) => {
                        this.onChangeSelect(event.target.value);
                      }}
                    >
                      <option value="enterprise">
                        Enterprise
                      </option>
                      <option value="logical">
                        Logical
                      </option>
                      <option value="unicard">
                        Unicard
                      </option>
                      <option value="mobileapp">
                        Mobile App
                      </option>
                      <option value="bills">
                        Bills - VAS
                      </option>
                      <option value="USSD">
                        USSD (Comming Soon)
                      </option>
                    </select>
                  </div>
                </a>
              </li>

              <li className="nav-item dropdown dropdown-user-setting">
                <a
                  className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                  href="£"
                  data-bs-toggle="dropdown"
                >
                  <div className="user-setting d-flex align-items-center">
                    <img
                      src="assets/images/dummy.png"
                      className="user-img"
                      alt=""
                    />
                    <small className="px-3 mb-0 dropdown-user-designation">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "14px",
                          fontWeight: "300",
                        }}
                      >
                        <div>{this.state.userName}</div>
                        <div style={{
                          fontWeight: "600",
                        }}>{this.state.userRole}</div>
                      </div>
                    </small>
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <a className="dropdown-item" href="#">
                      <div className="d-flex align-items-center">
                        <img
                          src="assets/images/avatars/avatar-1.png"
                          alt=""
                          className="rounded-circle"
                          width="54"
                          height="54"
                        />
                        <div className="ms-3"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="/profile">
                      <div className="d-flex align-items-center">
                        <div className="">
                          <i className="bi bi-person-fill"></i>
                        </div>
                        <div className="ms-3">
                          <span>Profile</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      <div className="d-flex align-items-center">
                        <div className="">
                          <i className="bi bi-speedometer"></i>
                        </div>
                        <div className="ms-3">
                          <span>Dashboard</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <div className="d-flex align-items-center">
                        <div className="">
                          <i className="bi bi-piggy-bank-fill"></i>
                        </div>
                        <div className="ms-3">
                          <span>Earnings</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <div className="d-flex align-items-center">
                        <div className="">
                          <i className="bi bi-cloud-arrow-down-fill"></i>
                        </div>
                        <div className="ms-3">
                          <span>Download Report</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="/signIn">
                      <div className="d-flex align-items-center">
                        <div className="">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <div className="ms-3">
                          <span>Logout</span>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
