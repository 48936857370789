import  React, {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { WEB_BASE_URL } from './webbase';
import axios from 'axios';
import cookie from "react-cookies";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  boxShadow: 24,
};

export default function DeleteQuestion({open, handleClose, name, email, vtu , sim, vas}) {
     
  
      const [loading, setLoading] = useState(false)
      const [value, setValue] = useState('')
      const token = cookie.load("token")
    const [valueAmount, setValueAmount] = useState('')
    const setThresholdValue = (e) =>{
        e.preventDefault()
        const id = toast.loading("Please wait...");
    const valuess = {
        "product": value,
        "treshold": valueAmount,
        "merchantEmail": email,
    }

    axios
    .post(WEB_BASE_URL + "/onboarding/set-treshold-merchant", valuess,  {
      headers: {
        authorization: token,
      },
    })
    .then((res) => {
      if (res.data.status === "2000") {
        toast.update(id, {
          render: res.data.description,
          type: "success",
          isLoading: false,
        });
        setTimeout(() => {
        //   toast.dismiss(id);
          window.location.href ="/set-threshold";
        }, 2000);
      } else {
        console.log(res);
        toast.update(id, {
          render: res.data.description,
          type: "error",
          isLoading: false,
        });
      }
    })
    .catch((err) => {
      toast.update(id, {
        render: err,
        type: "error",
        isLoading: false,
      });
    });
}

const handleChange = (event) => {
    const selectedProduct = event.target.value;
   if(selectedProduct === 'vtuTreshold'){
    setValueAmount(vtu)
   }
   else if(selectedProduct === 'tresholdSim'){
    setValueAmount(sim)
   }
   else  if(selectedProduct === 'vasTreshold'){
    setValueAmount(vas)
   } else{
    setValueAmount('')
   }
    setValue(selectedProduct);
    
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg  p-4 pt-10'> 
          
            <div className=' '>
           
                <div className=' d-flex justify-content-end'>
                   
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-black cursor-pointer text-[38px]' fontSize="large"/>
                    
                    </div>
                </div>
                <h5 className=' px-4 '> Merchant name: {name}</h5>
                <form onSubmit={setThresholdValue} className="row">
       <div className="row">
       <div className="col-3"></div>
       <div className="col-6">
       <div className=' py-3'>
                  <label className="form-label">Products</label>
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    value={value}
                    required
                    onChange={handleChange}
                  >
                    <option value=""> Select Product</option>
                    <option value="tresholdSim"> Sim Treshold</option>
                    <option value="vasTreshold"> Vas Treshold</option>
                    <option value="vtuTreshold"> Vtu Treshold</option>
                    
                  </select>
                </div>
                <div>
                <label className=' mb-2'>Amount :</label>
                <input
                    className="form-control "
                    type="text"
                    required
                    value={valueAmount}
                    onChange={(e) => setValueAmount(e.target.value)}
                    placeholder="Set threshold"
                  />
                </div>
                        </div>
                        <div className="col-3"></div>
                        </div>
                        <div className=' row'>
                        <div className="col-8"></div>
                        <div className="col-3 my-3">
                        <div className="ms-auto">
                                <div className="btn-group">
                                <button type="submit" 
                                onClick={setThresholdValue}
                                className="btn btn-primary">
                                    Submit
                                </button>
                                </div>
                            </div>
                        </div>
                        </div>
                        </form>
               
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}