import React from "react";
import axios from "axios";
import { VAS_WEB_BASE_URL, WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    BarElement,
} from 'chart.js';
import { BlockLoading } from 'react-loadingg';
import { faker } from '@faker-js/faker';
import { Bar, Line } from 'react-chartjs-2';
import Switch from "react-switch";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaidIcon from '@mui/icons-material/Paid';
import PaymentsIcon from '@mui/icons-material/Payments';
import Airtime from '../../assets/menu_icons/airtime.svg'
import Cable from '../../assets/menu_icons/cable.svg'
import Exam from '../../assets/menu_icons/exam.svg'
import Electricity from '../../assets/menu_icons/elec.svg'
import Data from '../../assets/menu_icons/data.svg'
import Wifi from '../../assets/menu_icons/wife.svg'
class Main extends React.Component {
    constructor() {
        super();
        this.state = {
            token: cookie.load("token"),
            businessName: cookie.load("businessName"),
            walletBalance: 0,
            vasWalletBalance:0,
            succesfulTransaction:0,
            failedTransaction:0,
            vouchers: [],
            boughtvouchers: [],
            userRole: cookie.load("userRole"),
            networks: [],
            dailyReport: [],
            transactionCount: {},
            avaliableVouchers: {},
            dataByDate: {},
            isLoading: false,
            total: 0,
        };
        this.getBoughtVouchers = this.getBoughtVouchers.bind(this);
        this.getWalletBalance = this.getWalletBalance.bind(this);
        this.getTrasanctionCount = this.getTrasanctionCount.bind(this);
        this.reduceTransactions = this.reduceTransactions.bind(this);
        this.makeShortDate = this.makeShortDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getProfile = this.getProfile.bind(this);

        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            BarElement,
            Title,
            Tooltip,
            Legend,
            Filler,
        );
    }


    getWalletBalance() {
        axios
            .get(WEB_BASE_URL +  "/onboarding/account-balance",
              //  "/bills/get-balance", 
                {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                console.log(res)
                this.setState({ walletBalance: res.data.data.entBalance ? res.data.data.entBalance : 0});
                this.setState({ vasWalletBalance: res.data.data.unicardBalance ? res.data.data.unicardBalance : 0})

            })
            .catch((err) => { console.log(VAS_WEB_BASE_URL + " not working " + err); })
    }

    makeShortDate(datew) {
        datew = new Date(datew);
        let yy = datew.getFullYear();
        let mm = datew.getMonth();
        let dd = datew.getDate();
        let shortDate = `${yy}/${mm < 10 ? 0 : ""}${mm + 1}/${dd < 10 ? 0 : ""
            }${dd}`;
        return shortDate;
    }

    getBoughtVouchers = () => {
        this.setState({ isLoading: true });
        let api = "";
        const today = new Date();
        let datee = this.makeShortDate(today);
        let yesterday = new Date(today);
        yesterday = this.makeShortDate(yesterday.setDate(today.getDate() - 1))

        api = "/bills/custom-report";

        axios
            .get(VAS_WEB_BASE_URL + api + "?startDate=" +
                yesterday.split('/').join('-') +
                "&endDate=" +
                datee.split('/').join('-') + "&offSet=0&page=600&network=&msisdn=&merchantId=&customerRef=&beneficiaryPhone=", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.description == "No data available") {
                } else {
                    // console.log(res.data.data.data);
                    let trans = res.data.reportData.reverse()
                    const total = trans.reduce((sum, transaction) => sum + transaction.amount, 0);
                    this.setState({ boughtvouchers: trans, total: total });
                    this.setState({succesfulTransaction:res.data.successfullTrans })
                    this.setState({failedTransaction:res.data.failedTrans })
                }
            })
            .catch((err) => { console.log(VAS_WEB_BASE_URL + " " + err); }).finally(() => {
                this.setState({ isLoading: false });
            });
    };

    //write a function to get the count of total transactions from count-transaction API
    getTrasanctionCount() {
        const today = new Date();
        // Get the components of today's date
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth() + 1; // Months are zero-indexed, so add 1
        const todayDate = today.getDate();

        // Formatted string of today's date (in yyyy-mm-dd format)
        const formattedToday = `${todayYear}-${todayMonth < 10 ? '0' : ''}${todayMonth}-${todayDate < 10 ? '0' : ''}${todayDate}`;

        // Subtract one day (24 hours) from today's date
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        // Get the components of yesterday's date
        const yesterdayYear = yesterday.getFullYear();
        const yesterdayMonth = yesterday.getMonth() + 1; // Months are zero-indexed, so add 1
        const yesterdayDate = yesterday.getDate();

        // Formatted string of yesterday's date (in yyyy-mm-dd format)
        const formattedYesterday = `${yesterdayYear}-${yesterdayMonth < 10 ? '0' : ''}${yesterdayMonth}-${yesterdayDate < 10 ? '0' : ''}${yesterdayDate}`;

        axios
            .get(VAS_WEB_BASE_URL + "/dealer/dealer-dataBoard?endDate=" + formattedToday
                + "&merchantEmail=&startDate=" + formattedYesterday, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.status === "2000" && res.data.data !== null) {
                    this.setState({ transactionCount: res.data.data });
                }
            })
            .catch((err) => {
                console.log(VAS_WEB_BASE_URL + " " + err);
            });
    }


    componentDidMount() {
        const user = cookie.load("user");
        console.log(cookie.load("vasState"));
        if (cookie.load("vasState") == "true" && user !== undefined) {
            this.setState({
                vasState: true,
            })
            this.getBoughtVouchers();
            this.getWalletBalance();
            // this.getProfile();
            //  this.getTrasanctionCount();
        } else {
            this.setState({
                vasState: false,
            })
        }
    }

    reduceTransactions(transactions) {
        const dataByDate = transactions.reverse().reduce((accumulator, transaction) => {
            const date = transaction.requestDate.split("T")[0];
            const amount = transaction.amount;
            if (!accumulator[date]) {
                accumulator[date] = 0;
            }
            accumulator[date] += amount;
            return accumulator;
        }, {});
        this.setState({ dataByDate });
    }

    async getProfile() {
        //toast loading
        axios
            .get(VAS_WEB_BASE_URL + "/bills/get-user", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                console.log(res.data)
                this.setState({
                    vasState: res.data.data[0].accountStatus,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleChange(checked) {
        this.setState({ isLoading: true, vasState: !checked });
        checked = !checked;
        const id = toast.loading("Please wait...");
        axios
            .post(WEB_BASE_URL + "/onboarding/signup-for-service", {
                "service": "vas",
                "status": checked,
            }, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then(async (res) => {
                let iflog = res.data.status == 2000 ? true : false;
                if (iflog) {
                    cookie.save("vasState", checked, {
                        path: "/",
                        expires: new Date(Date.now() + 60 * 27 * 1000),
                    });
                    toast.update(id, {
                        render: res.data.description,
                        type: "success",
                        isLoading: false,
                    });
                    setTimeout(() => this.props.navigate(0), 1500)
                }
                else {
                    console.log(res);
                    toast.update(id, {
                        render: "" + res.data.description,
                        type: "error",
                        isLoading: false,
                    });
                    this.setState({ vasState: !checked });
                    setTimeout(() => {
                        toast.dismiss(id);
                    }, 2000);
                }
            })
            .catch((err) => {
                console.log(WEB_BASE_URL + " " + err);
                this.setState({ vasState: !checked });
            }).finally(() => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "NGN",
        });

        const labels = ["Type of Transaction"];
        const options = {
            responsive: true,
            plugins: [{
                legend: {
                    position: 'top',
                },
                title: {
                    display: false,
                    text: 'Chart.js Line Chart',
                },
            },
            {
                beforeDraw: (chart) => {
                    if (this.state.isLoading) {
                        const canvas = chart.canvas;
                        const ctx = canvas.getContext('2d');
                        const centerX = canvas.width / 2;
                        const centerY = canvas.height / 2;

                        ctx.save();
                        ctx.beginPath();
                        ctx.translate(centerX, centerY);
                        BlockLoading.render({
                            size: 50,
                            color: '#263B95',
                            speed: 1,
                            duration: 2000
                        }, ctx);
                        ctx.restore();
                    }
                }
            }
            ],
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "Transaction Type"
                    },
                },
                y: {
                    grid: {
                        display: false,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "Number of transactions"
                    },
                }
            }
        };
        const data = {
            labels,
            datasets: [
                {
                    label: "All transactions",
                    data: [this.state.transactionCount["totalCount"]],
                    backgroundColor: '#263B95',
                    borderColor: '#263B95',
                    borderRadius: 14,
                    borderWidth: 2,
                    fill: true, // Add color to the area underneath the line
                },
                {
                    label: "Successful",
                    data: [this.state.succesfulTransaction],
                    backgroundColor: '#76c48b',
                    borderColor: '#76c48b',
                    borderRadius: 14,
                    borderWidth: 2,
                    fill: true, // Add color to the area underneath the line
                },
                {
                    label: "Failed",
                    data: [this.state.failedTransaction],
                    backgroundColor: '#e0365e',
                    borderColor: '#e0365e',
                    borderRadius: 14,
                    borderWidth: 2,
                    fill: true, // Add color to the area underneath the line
                },
            ],
        };
        return (
            <div className="page-content">
                <ToastContainer
                    position="top-center"
                    autoClose={false}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card overflow-hidden radius-10">
                            <div className="card-body align-items-center d-flex justify-content-between" >
                                <div className="text-bold" style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                }}>Status: {this.state.vasState ? "Active" : "Not Active"}</div>
                                <Switch onChange={e => this.handleChange(this.state.vasState)} checked={this.state.vasState} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 card ">
                        <div className=" overflow-hidden radius-10">
                            <div className="card-body align-items-center flex justify-content-between" style={{
                                minHeight: "30vh",
                                maxHeight: "40vh",
                                padding: "7%"
                            }}>
                                {this.state.isLoading && <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><BlockLoading color="#263B95" /></div>}
                                <Bar options={options} data={data} />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4">

                        <div className="row ">
                            <div className="col-md-5 card m-2">
                                <div className=" overflow-hidden radius-10">
                                    <div className="card-body">
                                        <div className=" align-items-stretch justify-content-between overflow-hidden pb-1">
                                            <div className="">
                                            <AccountBalanceWalletIcon/>
                                            <div className="mb-1 mt-4">VTU Wallet Balance</div>

                                            <h5 className="" style={{
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            }}>
                                            {formatter.format(this.state.walletBalance)}
                                            </h5>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 m-2 card">
                                <div className=" overflow-hidden radius-10">
                                    <div className="card-body">
                                        <div className="align-items-stretch justify-content-between overflow-hidden">
                                            <div className="">
                                            <PaymentsIcon/>
                                            <div className="mb-1 mt-4">VAS Wallet balance</div>
                                            <h5 className="" style={{
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            }}> {formatter.format(this.state.vasWalletBalance)}</h5> 
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-5 card m-2">
                                <div className=" overflow-hidden radius-10">
                                    <div className="card-body">
                                        <div className="align-items-stretch justify-content-between overflow-hidden">
                                            <div className="">
                                                <img
                                                    src="assets/images/failed.png"
                                                    style={{
                                                        height: "35px",
                                                        width: "auto",
                                                        borderRadius: "32px",
                                                        marginBottom: "5px"
                                                    }}
                                                    alt=""
                                                    className="mb-4"
                                                />

                                                <div className="mb-1">Failed Transactions</div>
                                                <h5 className="" style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                }}>{this.state.transactionCount["countFailed"] ? this.state.transactionCount["countFailed"] : 0}</h5>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 card m-2">
                                <div className=" overflow-hidden radius-10">
                                    <div className="card-body">
                                        <div className="align-items-stretch justify-content-between overflow-hidden">
                                            <div className="">
                                                <img
                                                    src="assets/images/valid.png"
                                                    style={{
                                                        height: "35px",
                                                        width: "auto",
                                                        borderRadius: "32px",
                                                        marginBottom: "5px"
                                                    }}
                                                    alt=""
                                                    className="mb-4"
                                                />
                                                <div className="mb-1">Sucessful Transactions</div>
                                                <h5 className="" style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                }}>{formatter.format(this.state.total)}</h5>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-12 col-lg-12 col-xl-12 d-flex">
                        <div className="card radius-10 w-100">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h6 className="mb-0">Recent transactions for the past week</h6>
                                    <div className="fs-5 ms-auto dropdown">
                                        <div
                                            className="dropdown-toggle dropdown-toggle-nocaret cursor-pointer"
                                            data-bs-toggle="dropdown"
                                        >
                                            <i className="bi bi-three-dots"></i>
                                        </div>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item" href="/transactions">
                                                    See All
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="table-responsive mt-2">
                                    <table className="table align-middle mb-0">
                                        <thead className="table-light">
                                            <tr>
                                                <th>Customer reference</th>
                                                <th>Merchant</th>
                                                <th>Network</th>
                                                <th>Beneficiary</th>
                                                <th>Amount</th>
                                                <th>Type</th>
                                                <th>Date</th>
                                                <th>Current Balance</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.boughtvouchers.length !== 0 ? (
                                                this.state.boughtvouchers.slice(0, 10).map((voucher, index) => (
                                                    <tr key={index}>
                                                        <td>{voucher.id}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-3">
                                                                <div className="product-info">
                                                                    <h6 className="product-name mb-1">
                                                                        {voucher.accountId}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{voucher?.networkId}</td>
                                                        <td>{voucher?.beneficiary}</td>
                                                        <td>{formatter.format(voucher.amount)}</td>
                                                        <td>{voucher.requestType}</td>
                                                        <td>
                                                            {voucher.requestDate !== undefined
                                                                ? voucher.requestDate.split("T")[0]
                                                                : 0}
                                                        </td>
                                                        <td>{formatter.format(voucher.newBalance)}</td>
                                                        <td>{voucher.statusMessage}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr colSpan="7">
                                                    <td>No Transaction Record.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div>
                <h4>Vas Products</h4>
                <div className=" card p-3 mt-3">
                <div className=" row  ">
                    <div className="col-2  p-2 ">
                        <div className="d-flex justify-content-center">
                        <div>
                        <div className="d-flex justify-content-center">
                                <img
                                    src={Airtime}
                                    alt="Airtime"
                                    className="img-fluid"
                                />
                            </div>
                            <h6 className=" pt-2">Airtime</h6>
                        </div>
                           
                        </div>
                    </div>
                    <div className="col-2  p-2 ">
                        <div className="d-flex justify-content-center">
                        <div>
                        <div className="d-flex justify-content-center">
                                <img
                                    src={Data}
                                    alt="Data"
                                />
                            </div>
                            <h6 className=" text-center pt-2">Data</h6>
                        </div>
                           
                        </div>
                    </div>
                    <div className="col-2  p-2 ">
                        <div className="d-flex justify-content-center">
                        <div>
                        <div className="d-flex justify-content-center">
                                <img
                                    src={Cable}
                                    alt="Cable"
                                />
                            </div>
                            <h6 className="text-center pt-2">Cable</h6>
                        </div>
                           
                        </div>
                    </div>
                    <div className="col-2  p-2 ">
                        <div className="d-flex justify-content-center">
                        <div>
                        <div className="d-flex justify-content-center">
                                <img
                                    src={Electricity}
                                    alt="Electricity"
                                />
                            </div>
                            <h6 className="text-center pt-2">Electricity</h6>
                        </div>
                           
                        </div>
                    </div>
                    <div className="col-2  p-2 ">
                        <div className="d-flex justify-content-center">
                        <div>
                        <div className="d-flex justify-content-center">
                                <img
                                    src={Wifi}
                                    alt="Wifi"
                                />
                            </div>
                            <h6 className=" text-center pt-2">Internet</h6>
                        </div>
                           
                        </div>
                    </div>
                    <div className="col-2  p-2 ">
                        <div className="d-flex justify-content-center">
                        <div>
                        <div className="d-flex justify-content-center">
                                <img
                                    src={Exam}
                                    alt="Exam"
                                    className="img-fluid"
                                />
                            </div>
                            <h6 className=" pt-2">School & Exam</h6>
                        </div>
                           
                        </div>
                    </div>
                </div>
                </div>
               
                </div>
            </div >
        );
    }
}

export default Main;
