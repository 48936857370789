import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "../../unified/webbase";

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      networks: [],
      token: cookie.load("token"),
      spdAIRTEL: 0,
      spdGLO: 0,
      spdMTN: 0,
      spd9MOBILE: 0,
    };
    this.getNetworks = this.getNetworks.bind(this);
  }

  getNetworks() {
    axios
      .get(WEB_BASE_URL + "/onboarding/get-network", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status !== "403") {
          let networks = res.data.data.map((network) => {
            return { name: network.network, status: network.status };
          });
          this.setState({ networks: networks });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  componentDidMount() {
    this.getNetworks();
  }

  render() {
    let ifEmailEditable = this.props.title === "Edit User" ? true : false;
    return (
      <div
        className="modal fade"
        id={this.props.id}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.props.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row g-3">
                <div className="col-12">
                  <label className="form-label">User Email</label>
                  <input
                    onChange={(event) => this.props.onChangeEmail(event)}
                    type="email"
                    className="form-control"
                    value={this.props.formEmail}
                    disabled={ifEmailEditable}
                  />
                </div>
                {this.state.networks.map((network, index) =>
                  network.status === "active" ? (
                    <div className="col-12 my-2">
                      <label className="form-label">{network.name}</label>
                      <input
                        type="text"
                        name="dsp"
                        placeholder="Selling Price Discounted"
                        class="form-control"
                        onChange={(e) => {
                          this.setState({
                            [`spd${network.name}`]: e.target.value,
                          });
                        }}
                        disabled={network.status !== "active" ? true : false}
                        required
                      />
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </form>
            </div>
            <div className="modal-footer">
              <button
                onClick={(e) => {
                  this.props.onSubmit(
                    e,
                    this.props.table_id,
                    this.props.formEmail,
                    this.state.spd9MOBILE,
                    this.state.spdMTN,
                    this.state.spdGLO,
                    this.state.spdAIRTEL
                  );
                }}
                type="button"
                className="btn btn-primary"
              >
                {this.props.buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUser;
