import axios from "axios";
import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import { WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import { CSVLink } from "react-csv";
import Multiselect from 'multiselect-react-dropdown';

class PaymentRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: cookie.load("token"),
            userRole: cookie.load("userRole"),
            walletBalance: cookie.load("walletBalance"),
            selectedTable: "Monthly",
            startDate: new Date(),
            endDate: new Date(),
            fromDate: this.makeShortDate(new Date()),
            toDate: this.makeShortDate(new Date()),
            transactions: [],
            walletHistory: [],
            dataByDate: {},
            isLoading: false,
            currentPage: 1,
            network: [
                { id: 1, name: "MTN" },
                { id: 2, name: "Airtel" },
                { id: 3, name: "Glo" },
                { id: 4, name: "9mobile" },
            ],
            filterOptions: [
                { id: 1, name: "Network" },
                { id: 2, name: "MSISDN" },
                { id: 3, name: "Beneficiary No" },
                { id: 4, name: "Merchant ID" },
            ],
            selectedFilters: [],
            msisdn: "",
            beneficiaryPhone: "",
            customerReference: "",
            selectedNetwork: "MTN",
            merchantId: "",
            status: "200",
        };

        this.uploadRef = React.createRef();
        //binding this to event-handler functions
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.getCustomReport = this.getCustomReport.bind(this);
        this.getWalletBalance = this.getWalletBalance.bind(this);
        this.reduceTransactions = this.reduceTransactions.bind(this);
        this.onSelectFilter = this.onSelectFilter.bind(this);
        this.onRemoveFilter = this.onRemoveFilter.bind(this);
        this.makeShortDate = this.makeShortDate.bind(this);
    }

    onSelectFilter(selectedList, selectedItem) {
        this.setState({
            selectedFilters: selectedList
        })
    }

    onRemoveFilter(selectedList, removedItem) {
        this.setState({
            selectedFilters: selectedList
        })
    }
    makeShortDate(datew) {
        datew = new Date(datew);
        let yy = datew.getFullYear();
        let mm = datew.getMonth();
        let dd = datew.getDate();
        let shortDate = `${yy}/${mm < 10 ? 0 : ""}${mm + 1}/${dd < 10 ? 0 : ""
            }${dd}`;
        return shortDate;
    }

    setStartDate(date) {
        let datew = this.makeShortDate(date);

        this.setState({
            startDate: date,
            fromDate: datew,
        });
    }

    setEndDate(date) {
        let datew = this.makeShortDate(date);

        this.setState({
            endDate: date,
            toDate: datew,
        });
    }

    getCustomReport() {
        this.setState({
            isLoading: true
        })
        let api = "";
        api = "/dealer/monthly-invoice";
        axios
            .get(
                WEB_BASE_URL +
                api + "?startDate=" +
                this.state.fromDate.split('/').join('-') +
                "&endDate=" +
                this.state.toDate.split('/').join('-') + "&beneficiaryPhone=" + this.state.beneficiaryPhone
                + "&offSet=0&page=1000&customerRef=" + this.state.customerReference +
                "&msisdn=" + this.state.msisdn +
                "&network=" + this.state.selectedNetwork + "&merchantId=" + this.state.merchantId,
                {
                    headers: {
                        authorization: this.state.token,
                    },
                }
            )
            .then((res) => {
                if (res.data.status === "2000" && res.data.reportData !== null) {
                    console.log(res.data.reportData);
                    this.setState({ transactions: res.data.reportData.reverse() });
                } else {
                    toast.error("No data found");
                    this.setState({ transactions: [] });
                }
            })
            .catch((err) => {
                console.log(WEB_BASE_URL + " " + err);
                toast.error("Error fetching transactions");
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            });
    }


    getWalletBalance() {
        axios
            .get(WEB_BASE_URL + "/onboarding/account-balance", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                cookie.save("walletBalance", res.data.data.entBalance, {
                    path: "/",
                    expires: new Date(Date.now() + 60 * 30 * 1000),
                });

                this.setState({ walletBalance: res.data.data.entBalance ? res.data.data.entBalance : 0 });
            })
            .catch((err) => { console.log(WEB_BASE_URL + " " + err); })
    }

    reduceTransactions(transactions) {
        const dataByDate = transactions.reduce((accumulator, transaction) => {
            const date = transaction.requestDate.split("T")[0];
            const amount = transaction.amount;
            if (!accumulator[date]) {
                accumulator[date] = 0;
            }
            accumulator[date] += amount;
            return accumulator;
        }, {});
        this.setState({ dataByDate });
    }

    componentDidMount() {
        this.getCustomReport();
        this.getWalletBalance();
    }

    render() {
        var formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "NGN",
        });

        const pageSize = 50;
        let totalPages = 0;
        if (this.state.transactions.length !== 0) {
            totalPages = Math.ceil(this.state.transactions.length / pageSize);
        }

        const handlePageChange = (page) => {
            this.setState({ currentPage: page });
        }

        let transactionsToDisplay = this.state.transactions.slice((this.state.currentPage - 1) * pageSize, this.state.currentPage * pageSize);
        return (
            <main className="page-content">
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;">
                                        <i className="bx bx-home-alt"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Payment Records
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <form className="row">
                            <div className="row">
                                <div className="col-3">
                                    <label className="form-label">From:</label>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onSelect={(date) => this.setStartDate(date)}
                                        onChange={(date) => this.setStartDate(date)}
                                    />
                                </div>
                                <div className="col-3">
                                    <label className="form-label">To:</label>
                                    <DatePicker
                                        selected={this.state.endDate}
                                        onSelect={(date) => this.setEndDate(date)}
                                        onChange={(date) => this.setEndDate(date)}
                                    />
                                </div>
                                <div className="col-3">
                                    <label className="form-label">Payment Status</label>
                                    <select
                                        className="form-select "
                                        aria-label="Default select example"
                                        value={this.state.status}
                                        onChange={(event) => {
                                            this.setState({
                                                status: event.target.value,
                                            })
                                        }}>
                                        <option value="1">All</option>
                                        <option value="200">Paid</option>
                                        <option value="5010">Pending</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3 mt-3">
                                    <button
                                        className="btn btn-secondary d-flex"
                                        type="button"
                                        style={{
                                            width: "100%"
                                        }}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.getCustomReport();
                                        }}
                                    >
                                        <div>
                                            <i class="bi bi-arrow-clockwise"></i>
                                        </div>
                                        <div className="mx-1">
                                            {this.state.isLoading ? "Generating..." : "Generate report"}</div>
                                    </button>

                                </div>
                                <div className="col-3 mt-3">
                                    <CSVLink
                                        data={this.state.transactions}
                                        filename={"Topupbox Report.csv"}
                                        target="_blank"
                                    >
                                        <button
                                            className="btn btn-primary d-flex"
                                            type="button"
                                            style={{
                                                width: "100%"
                                            }}
                                        >
                                            <div>
                                                <i class="bi bi-cloud-arrow-down-fill"></i>
                                            </div>
                                            <div className="mx-1">Export report as CSV</div>
                                        </button>
                                    </CSVLink>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >

                <div className="card">
                    <div className="card-header py-3">
                        <div className="row g-3">
                            <div className="col-lg-3 col-6 col-md-3">
                                <select
                                    className="form-select"
                                    value={this.state.selectedTable}
                                    onChange={(event) => {
                                        this.setState({
                                            selectedTable: event.target.value,
                                        });
                                        if (event.target.value === "Daily") {
                                            this.getDailyTransactions();
                                        }
                                    }}
                                >
                                    <option value="Daily">Daily</option>
                                </select>
                            </div>
                            <div className="col-lg-3 col-md-6 me-auto">
                                <div className="ms-auto position-relative">
                                    <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                        <i className="bi bi-search"></i>
                                    </div>
                                    <input
                                        className="form-control ps-5"
                                        type="text"
                                        placeholder="Search Payment"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <input className="d-none" ref={this.uploadRef} type="file" />
                            <table className="table align-middle mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th>ID</th>
                                        <th>Month</th>
                                        <th>No of Merchants</th>
                                        <th>Amount</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={0}>
                                        <td>1</td>
                                        <td>August</td>
                                        <td>3</td>
                                        <td>NGN 3000</td>
                                        <td>03-06-2023</td>
                                        <td>
                                            <span class="badge rounded-pill alert-warning">Pending</span>
                                        </td>
                                        <td>
                                            <button onClick={e => {
                                                this.uploadRef.current?.click()
                                            }}>Upload Receipt</button>
                                        </td>
                                    </tr>
                                    {transactionsToDisplay.length !== 0 ? (
                                        transactionsToDisplay.map((voucher, index) => (
                                            <tr key={index}>
                                                <td>{voucher?.monthly}</td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="product-info">
                                                            <h6 className="product-name mb-1">
                                                                {voucher.merchantsNo}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{formatter.format(voucher.amount)}</td>
                                                <td>{
                                                    voucher.requestDate !== undefined
                                                        ? voucher.requestDate.split("T")[0]
                                                        : 0
                                                }</td>
                                                <td>{voucher?.status}</td>
                                                <td>
                                                    <button>Upload Receipt</button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr colSpan="7">
                                            <td>No Transaction Record.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div style={{
                            overflowX: "scroll",
                        }}>
                            <nav class="float-end mt-3">
                                <ul class="pagination">
                                    {Array.from(Array(totalPages), (e, i) => {
                                        let pageNumber = i + 1;
                                        return (
                                            <li class={"page-item " + this.state.currentPage == pageNumber && "active"}>
                                                <a class="page-link" href="/2"
                                                    style={{
                                                        backgroundColor: this.state.currentPage == pageNumber ? "#0d6efd" : "",
                                                        color: this.state.currentPage == pageNumber ? "#fff" : ""
                                                    }}
                                                    onClick={
                                                        (e) => {
                                                            e.preventDefault();
                                                            this.setState({
                                                                currentPage: pageNumber
                                                            })
                                                        }
                                                    }
                                                >
                                                    {pageNumber}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </main >
        );
    }
}

export default PaymentRecord;
