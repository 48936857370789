import React from 'react';
import Header from '../unified/header';
import SideBar from '../unified/SideBar';
import {
    BrowserRouter as Router,
    Routes as Switch,
    Route,
} from "react-router-dom";
import Main from './components/main';
import Transaction from "./components/transaction";
import Fund from "./components/fund";
import EditTransactionPin from "./components/EditTransactionPin";
import Vouchers from "./components/vouchers";
import GenerateVoucher from "./components/generateVoucher";
import cookie from "react-cookies";
import InviteAgent from "./components/InviteAgent";
import FundWalletReq from "./components/fundWalletReq";
import CreateUser from '../logical/components/CreateUser';
import TransferFund from '../logical/components/transferFund';
import WalletHistory from '../logical/components/WalletHistory';
import Support from '../logical/components/support';
import Users from '../logical/components/users';
import Profile from '../logical/components/profile';
import PaymentRecord from './components/paymentRecord';
import VoucherHistory from './components/VoucherHistory';
import SetThreshold from './components/SetThreshold';
import MakeFund from '../enterprise/components/makeFund';
import Compliance from '../logical/components/compliance';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            someKey: 'someValue'
        };
    }

    componentDidMount() {
        this.setState({
            someKey: 'otherValue'
        });
    }

    render() {
        return (<div>
            <Header {...this.props} activeProduct={this.props.activeProduct} setActiveProduct={this.props.setActiveProduct}></Header>
            <SideBar {...this.props} product={this.props.activeProduct} setActiveProduct={this.props.setActiveProduct}></SideBar>
            <Switch>
                <Route
                    exact
                    path="/"
                    key={1}
                    element={<Main {...this.props}></Main>}
                />
                <Route
                    exact
                    key={2}
                    path="/transactions"
                    element={<Transaction {...this.props}></Transaction>}
                />
                <Route
                    exact
                    key={3}
                    path="/profile"
                    element={<Profile {...this.props}></Profile>}
                />
                <Route
                    exact
                    key={4}
                    path="/users"
                    element={<Users {...this.props}></Users>}
                />
                <Route
                    exact
                    key={9}
                    path="/invite-agents"
                    element={<InviteAgent {...this.props}></InviteAgent>}
                />
                <Route
                    exact
                    key={5}
                    path="/fund"
                    element={<MakeFund {...this.props}></MakeFund>}
                />
                <Route
                    exact
                    key={6}
                    path="/support"
                    element={<Support {...this.props}></Support>}
                />
                <Route
                    exact
                    key={7}
                    path="/wallet-history"
                    element={<WalletHistory {...this.props}></WalletHistory>}
                />
                <Route
                    exact
                    key={8}
                    path="/transfer-fund"
                    element={<TransferFund {...this.props}></TransferFund>}
                />
                <Route
                    exact
                    key={8}
                    path="/fund-wallet-req"
                    element={<FundWalletReq {...this.props}></FundWalletReq>}
                />
                <Route
                    exact
                    key={9}
                    path="/edit-transaction-pin"
                    element={<EditTransactionPin {...this.props}></EditTransactionPin>}
                />
                <Route
                    exact
                    key={10}
                    path="/vouchers"
                    element={<Vouchers {...this.props}></Vouchers>}
                />
                <Route
                    exact
                    key={11}
                    path="/generate-voucher"
                    element={<GenerateVoucher {...this.props}></GenerateVoucher>}
                />
                <Route
                    exact
                    key={12}
                    path="/create-users"
                    element={<CreateUser {...this.props}></CreateUser>}
                />
                <Route
                    exact
                    key={15}
                    path="/payment-records"
                    element={<PaymentRecord {...this.props}></PaymentRecord>}
                />
                 <Route
                    exact
                    key={16}
                    path="/vouchers-h"
                    element={<VoucherHistory {...this.props}></VoucherHistory>}
                />
                <Route
                    exact
                    key={16}
                    path="/set-threshold"
                    element={<SetThreshold {...this.props}></SetThreshold>}
                />
                <Route
                    exact
                    key={17}
                    path="/system-config"
                    element={<Compliance walletBalance={this.state.walletBalance} {...this.props} />}
                />
            </Switch>
        </div>);
    }


}

export default Index;
