/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Voucheritem from "./voucheritem";
import { WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import UploadModal from "./UploadModal";
import DatePicker from "react-datepicker";
import Reportdetails from "./reportDetails";
class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      walletBalance: cookie.load("walletBalance"),
      token: cookie.load("token"),
      businessName: cookie.load("businessName"),
      selectedTable: "daily",
      vouchers: [],
      backup: [],
      startDate: new Date(),
      endDate: new Date(),
      fromDate: this.makeShortDate(new Date()),
      toDate: this.makeShortDate(new Date()),
      readyPrint: false,
      weeklyBackUp: [],
      monthlybackup: [],
      customBackup: [],
      readyVouchers: [],
      avaliableVouchers: {},
      selectedDescription: "Voucher",
      selectedNetwork: "MTN",
      hasError: false,
      userRole: cookie.load("userRole"),
      dailyReport: {},
      weeklyReport: {},
      monthlyReport: {},
      weeklyDetails: [],
      monthlyDetails: [],
      inventoryDetails: [],
      inventoryReport: {},
    };

    this._Mounted = false;
    this.getVouchers = this.getVouchers.bind(this);
    this.getNetworks = this.getNetworks.bind(this);
    this.getAvaliable = this.getAvaliable.bind(this);
    this.getDailyReport = this.getDailyReport.bind(this);
    this.getMonthlyReport = this.getMonthlyReport.bind(this);
    this.getWeeklyReport = this.getWeeklyReport.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.getMonthlyDetails = this.getMonthlyDetails.bind(this);
    this.getWeeklyDetails = this.getWeeklyDetails.bind(this);
    this.makeShortDate = this.makeShortDate.bind(this);
    this.getCustomDetails = this.getCustomDetails.bind(this);
    this.getCustomReport = this.getCustomReport.bind(this);
    this.groupByUser = this.groupByUser.bind(this);
  }

  getVouchers = () => {
    const id = toast.loading("Please wait...");
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/planned-order", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.data !== null) {
          //console.log(res.data.data);
          res.data.data.reverse();
          //filter data to only those whose status is Unexecuted
          const filteredData = res.data.data.filter(
            (item) => item.orderStatus === "executed"
          );
          this.setState({ vouchers: filteredData, backup: filteredData });
        }
        toast.dismiss(id);
      })
      .catch((err) => {
        console.log(err);
        toast.update(id, {
          render: "Error fetching vouchers",
          type: "error",
          isLoading: false,
        });
        toast.dismiss(id);
      });
  };

  getDailyReport() {
    axios
      .get(WEB_BASE_URL + "/logical/daily-report", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000" && res.data.data !== null) {
          //console.log(res.data.data);

          this.setState({ dailyReport: res.data.data });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  makeShortDate(datew) {
    datew = new Date(datew);
    let yy = datew.getFullYear();
    let mm = datew.getMonth();
    let dd = datew.getDate();
    let shortDate = `${yy}/${mm < 10 ? 0 : ""}${mm + 1}/${dd < 10 ? 0 : ""
      }${dd}`;
    return shortDate;
  }

  setStartDate(date) {
    let datew = this.makeShortDate(date);

    this.setState({
      startDate: date,
      fromDate: datew,
    });
  }

  setEndDate(date) {
    let datew = this.makeShortDate(date);

    this.setState({
      endDate: date,
      toDate: datew,
    });
  }
  getWeeklyReport() {
    axios
      .get(WEB_BASE_URL + "/logical/weekly-report", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000" && res.data.data !== null) {
          //console.log(res.data.data);
          this.setState({ weeklyReport: res.data.data });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }
  getMonthlyReport() {
    axios
      .get(WEB_BASE_URL + "/logical/monthly-report", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000" && res.data.data !== null) {
          //console.log(res.data.data);

          this.setState({ monthlyReport: res.data.data });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  getMonthlyDetails() {
    axios
      .get(WEB_BASE_URL + "/logical/monthly-TransactionDetail", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000" && res.data.data !== null) {
          //console.log(res.data.data);

          this.setState({
            monthlyDetails: this.groupByUser(res.data.data),
            monthlybackup: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  getWeeklyDetails() {
    axios
      .get(WEB_BASE_URL + "/logical/weekly-TransactionDetail", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000" && res.data.data !== null) {
          this.setState({
            weeklyDetails: this.groupByUser(res.data.data),
            weeklyBackUp: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  getCustomReport() {
    axios
      .post(
        WEB_BASE_URL +
        "/logical/inventory-report?startDate=" +
        this.state.fromDate +
        "&endDate=" +
        this.state.toDate,
        {
          startDate: this.state.fromDate,
          endDate: this.state.toDate,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "2000" && res.data.data !== null) {
          //console.log(res.data.data);

          this.setState({ inventoryReport: res.data.data });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  getCustomDetails() {
    axios
      .post(
        WEB_BASE_URL +
        "/logical/custom-report?startDate=" +
        this.state.fromDate +
        "&endDate=" +
        this.state.toDate,
        {
          startDate: this.state.fromDate,
          endDate: this.state.toDate,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "2000" && res.data.data !== null) {
          console.log(res.data.data);

          this.setState({
            inventoryDetails: this.groupByUser(res.data.data),
            customBackup: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  groupByUser(list) {
    let users = [];
    //shuffle products

    //copy Products State
    let copy = [].concat(list);

    let user_names = [];
    //group products by category_name
    users = copy.reduce((acc, item) => {
      if (!acc.some((el) => el.fullname === item.fullname)) {
        //add to categories array

        acc.push({
          fullname: item.fullname,
          total: 0,
          SoldUnits: 0,
          SoldMTN: 0,
          SoldAIRTEL: 0,
          SoldGLO: 0,
          SoldNINEMOBILE: 0,
          SoldUnitsMTN: 0,
          SoldUnitsAIRTEL: 0,
          SoldUnitsGLO: 0,
          SoldUnitsNINEMOBILE: 0,
        });
      }

      let trans = acc.find((trans) => trans.fullname === item.fullname);
      trans.total += item.totalAmount;
      trans.SoldUnits += item.units;
      trans.SoldMTN += item.network === "MTN" ? item.totalAmount : 0;
      trans.SoldAIRTEL += item.network === "AIRTEL" ? item.totalAmount : 0;
      trans.SoldGLO += item.network === "GLO" ? item.totalAmount : 0;
      trans.SoldNINEMOBILE +=
        item.network === "NINEMOBILE" ? item.totalAmount : 0;

      trans.SoldUnitsMTN += item.network === "MTN" ? item.units : 0;
      trans.SoldUnitsAIRTEL += item.network === "AIRTEL" ? item.units : 0;
      trans.SoldUnitsGLO += item.network === "GLO" ? item.units : 0;
      trans.SoldUnitsNINEMOBILE +=
        item.network === "NINEMOBILE" ? item.units : 0;

      return acc;
    }, []);

    console.log(users);
    return users;
  }

  getNetworks() {
    axios
      .get(WEB_BASE_URL + "/onboarding/get-network", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status !== "403") {
          //console.log(res.data.data);
          let networks = res.data.data.map((network) => {
            return { name: network.network, status: network.status };
          });

          if (networks.length == 0) {
            toast.error("No Networks Found, Please Setup Config");
          }
          this.setState({ networks: networks });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  getAvaliable() {
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/available-voucher", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000" && res.data.data !== null) {
          this.setState({ avaliableVouchers: res.data.data });
        }
      })
      .catch((err) => {
        console.log(WEB_BASE_URL + " " + err);
      });
  }

  componentDidMount() {
    if (this._Mounted) {
    } else {
      //this.getVouchers();
      this.getNetworks();
      this.getAvaliable();
      this.getDailyReport();
      this.getMonthlyReport();
      this.getWeeklyReport();
      this.getMonthlyDetails();
      this.getWeeklyDetails();
      this._Mounted = true;
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
  }

  render() {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    const printDiv = {
      display: this.state.readyPrint ? "block" : "none",
    };
    try {
      // Block of code to try
      return (
        <main className="page-content">
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <a href="javascript:;">
                      <i className="bx bx-home-alt"></i>
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Reports on each Order
                  </li>
                </ol>
              </nav>
            </div>

            <div class="col-lg-2 col-6 col-md-3 ms-auto">
              <select
                class="form-select"
                value={this.state.selectedTable}
                onChange={(event) => {
                  this.setState({
                    selectedTable: event.target.value,
                  });
                }}
              >
                <option value={"daily"}>Daily Reports</option>
                <option value={"weekly"}>Weekly Reports</option>
                <option value={"monthly"}>Monthly Reports</option>
                <option value={"custom"}>Custom</option>
              </select>
            </div>
          </div>

          {this.state.selectedTable === "custom" ? (
            <div className="card">
              <div className="card-body">
                <form
                  className="row"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.getCustomDetails();
                    this.getCustomReport();
                  }}
                >
                  <div className="row">
                    <div className="col-6">
                      <label className="form-label">From:</label>
                      <DatePicker
                        selected={this.state.startDate}
                        onSelect={(date) => this.setStartDate(date)}
                        onChange={(date) => this.setStartDate(date)}
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-label">To:</label>
                      <DatePicker
                        selected={this.state.endDate}
                        onSelect={(date) => this.setStartDate(date)}
                        onChange={(date) => this.setStartDate(date)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <button
                        style={{ marginTop: 29 }}
                        className="btn btn-primary d-flex"
                      >
                        <div>
                          <i class="bi bi-cloud-arrow-down-fill"></i>
                        </div>
                        <div className="mx-3">Get Report</div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="row row-cols-1 row-cols-lg-3">
            {this.state.selectedTable === "daily" ? (
              <>
                <div className="col">
                  <div className="card overflow-hidden radius-10">
                    <div className="card-body">
                      <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                        <div className="">
                          <p>Cost Price</p>
                          {this.state.networks.map((network) => (
                            <h5 className="">
                              [{network?.name}]{"   "}
                              {this.state.dailyReport[
                                "Total CostPrice " + network?.name
                              ] !== undefined
                                ? formatter.format(
                                  this.state.dailyReport[
                                  "Total CostPrice " + network?.name
                                  ]
                                )
                                : 0}
                            </h5>
                          ))}
                        </div>
                        <div className="">
                          <p className="mb-3 float-end text-success">Daily</p>
                          <div id="chart3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card overflow-hidden radius-10">
                    <div className="card-body">
                      <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                        <div className="">
                          <p>Profit</p>
                          {this.state.networks.map((network) => (
                            <h5 className="">
                              [{network?.name}]{"   "}
                              {this.state.dailyReport[
                                "Total DailyProft " + network?.name
                              ] !== undefined
                                ? formatter.format(
                                  this.state.dailyReport[
                                  "Total DailyProft " + network?.name
                                  ]
                                )
                                : 0}
                            </h5>
                          ))}
                        </div>
                        <div className="">
                          <p className="mb-3 float-end text-success">Daily</p>
                          <div id="chart3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card overflow-hidden radius-10">
                    <div className="card-body">
                      <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                        <div className="">
                          <p>Selling Price</p>
                          {this.state.networks.map((network) => (
                            <h5 className="">
                              [{network?.name}]{"   "}
                              {this.state.dailyReport[
                                "Total SellingPrice " + network?.name
                              ] !== undefined
                                ? formatter.format(
                                  this.state.dailyReport[
                                  "Total SellingPrice " + network?.name
                                  ]
                                )
                                : 0}
                            </h5>
                          ))}
                        </div>
                        <div className="">
                          <p className="mb-3 float-end text-success">Daily</p>
                          <div id="chart3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : this.state.selectedTable === "weekly" ? (
              <>
                <div className="col">
                  <div className="card overflow-hidden radius-10">
                    <div className="card-body">
                      <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                        <div className="">
                          <p>Cost Price</p>
                          {this.state.networks.map((network) => (
                            <h5 className="">
                              [{network?.name}]{" "}
                              {this.state.weeklyReport[
                                "Total CostPrice " + network?.name
                              ] !== undefined
                                ? formatter.format(
                                  this.state.weeklyReport[
                                  "Total CostPrice " + network?.name
                                  ]
                                )
                                : 0}
                            </h5>
                          ))}
                        </div>
                        <div className="">
                          <p className="mb-3 float-end text-success">Weekly</p>
                          <div id="chart3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card overflow-hidden radius-10">
                    <div className="card-body">
                      <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                        <div className="">
                          <p>Profit</p>
                          {this.state.networks.map((network) => (
                            <h5 className="">
                              [{network?.name}]{" "}
                              {this.state.weeklyReport[
                                "Total weeklyProfit " + network?.name
                              ] !== undefined
                                ? formatter.format(
                                  this.state.weeklyReport[
                                  "Total weeklyProfit " + network?.name
                                  ]
                                )
                                : 0}
                            </h5>
                          ))}
                        </div>
                        <div className="">
                          <p className="mb-3 float-end text-success">Weekly</p>
                          <div id="chart3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card overflow-hidden radius-10">
                    <div className="card-body">
                      <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                        <div className="">
                          <p>Selling Price</p>
                          {this.state.networks.map((network) => (
                            <h5 className="">
                              [{network?.name}]{"   "}
                              {this.state.weeklyReport[
                                "Total SellingPrice " + network?.name
                              ] !== undefined
                                ? formatter.format(
                                  this.state.weeklyReport[
                                  "Total SellingPrice " + network?.name
                                  ]
                                )
                                : 0}
                            </h5>
                          ))}
                        </div>
                        <div className="">
                          <p className="mb-3 float-end text-success">Weekly</p>
                          <div id="chart3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : this.state.selectedTable === "monthly" ? (
              <>
                <div className="col">
                  <div className="card overflow-hidden radius-10">
                    <div className="card-body">
                      <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                        <div className="">
                          <p>Cost Price</p>
                          {this.state.networks.map((network) => (
                            <>
                              <h5 className="">
                                [{network?.name}]{"   "}
                                {this.state.monthlyReport[
                                  "Total CostPrice " + network?.name
                                ] !== undefined
                                  ? formatter.format(
                                    this.state.monthlyReport[
                                    "Total CostPrice " + network?.name
                                    ]
                                  )
                                  : 0}
                              </h5>
                            </>
                          ))}
                        </div>
                        <div className="">
                          <p className="mb-3 float-end text-success">Monthly</p>
                          <div id="chart3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card overflow-hidden radius-10">
                    <div className="card-body">
                      <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                        <div className="">
                          <p>Profit</p>
                          {this.state.networks.map((network) => (
                            <>
                              <h5 className="">
                                [{network?.name}]{"   "}
                                {this.state.monthlyReport[
                                  "Total monthlyProfit " + network?.name
                                ] !== undefined
                                  ? formatter.format(
                                    this.state.monthlyReport[
                                    "Total monthlyProfit " + network?.name
                                    ]
                                  )
                                  : 0}
                              </h5>
                            </>
                          ))}
                        </div>
                        <div className="">
                          <p className="mb-3 float-end text-success">Monthly</p>
                          <div id="chart3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card overflow-hidden radius-10">
                    <div className="card-body">
                      <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                        <div className="">
                          <p>Selling Price</p>
                          {this.state.networks.map((network) => (
                            <h5 className="">
                              [{network?.name}]{"   "}
                              {this.state.monthlyReport[
                                "Total SellingPrice " + network?.name
                              ] !== undefined
                                ? formatter.format(
                                  this.state.monthlyReport[
                                  "Total SellingPrice " + network?.name
                                  ]
                                )
                                : 0}
                            </h5>
                          ))}
                        </div>
                        <div className="">
                          <p className="mb-3 float-end text-success">Monthly</p>
                          <div id="chart3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col">
                  <div className="card overflow-hidden radius-10">
                    <div className="card-body">
                      <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                        <div className="">
                          <p>Cost Price</p>
                          {this.state.networks.map((network) => (
                            <h4 className="">
                              [{network?.name}] &#8358;
                              {this.state.inventoryReport[
                                "Total CostPrice " + network?.name
                              ] !== undefined
                                ? this.state.inventoryReport[
                                "Total CostPrice " + network?.name
                                ]
                                : 0}
                            </h4>
                          ))}
                        </div>
                        <div className="">
                          <p className="mb-3 float-end text-success">
                            Custom sales
                          </p>
                          <div id="chart3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card overflow-hidden radius-10">
                    <div className="card-body">
                      <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                        <div className="">
                          <p>Profit</p>
                          {this.state.networks.map((network) => (
                            <h4 className="">
                              [{network?.name}] ₦
                              {this.state.inventoryReport[
                                "Total customProfit " + network?.name
                              ] !== undefined
                                ? this.state.inventoryReport[
                                "Total customProfit " + network?.name
                                ]
                                : 0}
                            </h4>
                          ))}
                        </div>
                        <div className="">
                          <p className="mb-3 float-end text-success">Custom</p>
                          <div id="chart3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card overflow-hidden radius-10">
                    <div className="card-body">
                      <div className="d-flex align-items-stretch justify-content-between overflow-hidden pb-2">
                        <div className="">
                          <p>Selling Price</p>
                          {this.state.networks.map((network) => (
                            <h5 className="">
                              [{network?.name}]{"   "}
                              {this.state.inventoryReport[
                                "Total SellingPrice " + network?.name
                              ] !== undefined
                                ? formatter.format(
                                  this.state.inventoryReport[
                                  "Total SellingPrice " + network?.name
                                  ]
                                )
                                : 0}
                            </h5>
                          ))}
                        </div>
                        <div className="">
                          <p className="mb-3 float-end text-success">Monthly</p>
                          <div id="chart3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {this.state.networks.map((network) => (
              <>
                <div className="col">
                  <div className="card overflow-hidden radius-10">
                    <div className="card-body">
                      <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                        <div className="w-50">
                          <p>Units Available {network.name}</p>
                          <h5 className="">
                            [100]{"  "}
                            {
                              this.state.avaliableVouchers[
                              "Available Voucher " + network?.name + " 100"
                              ]
                            }
                          </h5>
                          <h5 className="">
                            [200]{"  "}
                            {
                              this.state.avaliableVouchers[
                              "Available Voucher " + network?.name + " 200"
                              ]
                            }
                          </h5>
                          <h5 className="">
                            [500]{"  "}
                            {
                              this.state.avaliableVouchers[
                              "Available Voucher " + network?.name + " 500"
                              ]
                            }
                          </h5>
                          <h5 className="">
                            [1000]{"  "}
                            {
                              this.state.avaliableVouchers[
                              "Available Voucher " + network?.name + " 1000"
                              ]
                            }
                          </h5>
                        </div>
                        <div className="w-50">
                          <p className="mb-3 float-end text-danger">
                            your stocks
                          </p>
                          <div id="chart2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>

          {this.state.selectedTable === "daily" &&
            this.state.networks.length == 0 ? (
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table align-middle mb-0">
                    <thead class="table-light">
                      <tr>
                        <th>Batch ID</th>
                        <th>Username</th>
                        <th>Description</th>
                        <th>Units</th>
                        <th>Voucher denomination</th>
                        <th>Network</th>
                        <th>Selling Price</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : this.state.selectedTable === "weekly" ? (
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table align-middle mb-0">
                    <thead class="table-light">
                      <tr>
                        <th>ID</th>
                        <th>User Name</th>

                        {this.state.networks.map((network) => (
                          <>
                            <th key={network} class="">
                              {network.name}
                            </th>
                          </>
                        ))}
                        <th>Total Units</th>
                        <th>Total Amount Sold</th>
                        <th>Wallet Balance</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.weeklyDetails.map((voucher, index) => (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{voucher.fullname}</td>
                            {this.state.networks.map((network) => (
                              <>
                                <td>
                                  <div class="d-flex align-items-center gap-3 cursor-pointer">
                                    <div class="">
                                      <p class="mb-0" key={network}>
                                        {voucher["SoldUnits" + network.name]}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </>
                            ))}
                            <td>{voucher.SoldUnits}</td>
                            <td>{formatter.format(voucher.total)}</td>
                            <td>{formatter.format(voucher.total)}</td>
                            <td>
                              <a
                                href="javascript:void(0);"
                                class="text-success"
                                data-bs-toggle="modal"
                                data-bs-target={"#reportweekly" + index}
                                title="Transaction Details"
                                data-bs-original-title="Transaction Details"
                                aria-label="Transaction Details"
                              >
                                <i class="bi bi-clipboard-data"></i>
                              </a>
                            </td>
                          </tr>

                          <Reportdetails
                            id={"reportweekly" + index}
                            username={voucher.fullname}
                            title={
                              "Weekly Transaction Details for " +
                              voucher.fullname
                            }
                            networks={this.state.networks}
                            SoldMTN={voucher.SoldMTN}
                            SoldAIRTEL={voucher.SoldAIRTEL}
                            SoldGLO={voucher.SoldGLO}
                            SoldNINEMOBILE={voucher.SoldNINEMOBILE}
                            vouchers={this.state.weeklyBackUp.filter(
                              (voucher_i) => {
                                return voucher_i.fullname
                                  .toLowerCase()
                                  .includes(voucher.fullname.toLowerCase());
                              }
                            )}
                          ></Reportdetails>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : this.state.selectedTable === "monthly" ? (
            <div class="card">
              <div class="card-header py-3">
                <div class="row g-3"></div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table align-middle mb-0">
                    <thead class="table-light">
                      <tr>
                        <th>ID</th>
                        <th>User Name</th>

                        {this.state.networks.map((network) => (
                          <>
                            <th key={network} class="">
                              {network.name}
                            </th>
                          </>
                        ))}
                        <th>Total Units</th>
                        <th>Total Amount Sold</th>
                        <th>Wallet Balance</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.monthlyDetails.map((voucher, index) => (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{voucher.fullname}</td>
                            {this.state.networks.map((network) => (
                              <>
                                <td>
                                  <div class="d-flex align-items-center gap-3 cursor-pointer">
                                    <div class="">
                                      <p class="mb-0" key={network}>
                                        {voucher["SoldUnits" + network.name]}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </>
                            ))}
                            <td>{voucher.SoldUnits}</td>
                            <td>{formatter.format(voucher.total)}</td>
                            <td>{formatter.format(voucher.total)}</td>
                            <td>
                              <a
                                href="javascript:void(0);"
                                class="text-success"
                                data-bs-toggle="modal"
                                data-bs-target={"#reportmonthly" + index}
                                title="Transaction Details"
                                data-bs-original-title="Transaction Details"
                                aria-label="Transaction Details"
                              >
                                <i class="bi bi-clipboard-data"></i>
                              </a>
                            </td>
                          </tr>

                          <Reportdetails
                            id={"reportmonthly" + index}
                            username={voucher.fullname}
                            title={
                              "Monthly Transaction Details for " +
                              voucher.fullname
                            }
                            networks={this.state.networks}
                            SoldMTN={voucher.SoldMTN}
                            SoldAIRTEL={voucher.SoldAIRTEL}
                            SoldGLO={voucher.SoldGLO}
                            SoldNINEMOBILE={voucher.SoldNINEMOBILE}
                            vouchers={this.state.monthlybackup.filter(
                              (voucher_i) => {
                                return voucher_i.fullname
                                  .toLowerCase()
                                  .includes(voucher.fullname.toLowerCase());
                              }
                            )}
                          ></Reportdetails>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div class="card">
              <div class="card-header py-3">
                <div class="row g-3">
                  <div class="col-lg-3 col-md-6 me-auto">
                    <div class="ms-auto position-relative">
                      <div class="position-absolute top-50 translate-middle-y search-icon px-3">
                        <i class="bi bi-search"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-6 col-md-3">
                    <select class="form-select">
                      <option>Show 10</option>
                      <option>Show 30</option>
                      <option>Show 50</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table align-middle mb-0">
                    <thead class="table-light">
                      <tr>
                        <th>ID</th>
                        <th>User Name</th>

                        {this.state.networks.map((network) => (
                          <>
                            <th key={network} class="">
                              {network.name}
                            </th>
                          </>
                        ))}
                        <th>Total Units</th>
                        <th>Total Amount Sold</th>
                        <th>Wallet Balance</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.inventoryDetails.map((voucher, index) => (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{voucher.fullname}</td>
                            {this.state.networks.map((network) => (
                              <>
                                <td>
                                  <div class="d-flex align-items-center gap-3 cursor-pointer">
                                    <div class="">
                                      <p class="mb-0" key={network}>
                                        {voucher["SoldUnits" + network.name]}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </>
                            ))}
                            <td>{voucher.SoldUnits}</td>
                            <td>{formatter.format(voucher.total)}</td>
                            <td>{formatter.format(voucher.total)}</td>
                            <td>
                              <a
                                href="javascript:void(0);"
                                class="text-success"
                                data-bs-toggle="modal"
                                data-bs-target={"#reportcustom" + index}
                                title="Transaction Details"
                                data-bs-original-title="Transaction Details"
                                aria-label="Transaction Details"
                              >
                                <i class="bi bi-clipboard-data"></i>
                              </a>
                            </td>
                          </tr>

                          <Reportdetails
                            id={"reportcustom" + index}
                            username={voucher.fullname}
                            title={
                              "Custom Transaction Details for " +
                              voucher.fullname
                            }
                            networks={this.state.networks}
                            SoldMTN={voucher.SoldMTN}
                            SoldAIRTEL={voucher.SoldAIRTEL}
                            SoldGLO={voucher.SoldGLO}
                            SoldNINEMOBILE={voucher.SoldNINEMOBILE}
                            vouchers={this.state.customBackup.filter(
                              (voucher_i) => {
                                return voucher_i.fullname
                                  .toLowerCase()
                                  .includes(voucher.fullname.toLowerCase());
                              }
                            )}
                          ></Reportdetails>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </main>
      );
    } catch (err) {
      // Block of code to handle errors
      console.log(err);
      return (
        <main className="page-content">
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <a href="javascript:;">
                      <i className="bx bx-home-alt"></i>
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Reports
                  </li>
                </ol>
              </nav>
            </div>
            <div className="ms-auto">
              <div className="btn-group"></div>
            </div>
          </div>

          <div class="card">
            <div class="card-header py-3">
              <div class="row g-3">
                <div class="col-lg-3 col-md-6 me-auto">
                  <div class="ms-auto position-relative">
                    <div class="position-absolute top-50 translate-middle-y search-icon px-3">
                      <i class="bi bi-search"></i>
                    </div>
                    <input
                      class="form-control ps-5"
                      type="text"
                      placeholder="Search Payment"
                    />
                  </div>
                </div>
                <div class="col-lg-2 col-6 col-md-3">
                  <select class="form-select">
                    <option>Status</option>
                    <option>Active</option>
                    <option>Disabled</option>
                    <option>Pending</option>
                    <option>Show All</option>
                  </select>
                </div>
                <div class="col-lg-2 col-6 col-md-3">
                  <select class="form-select">
                    <option>Show 10</option>
                    <option>Show 30</option>
                    <option>Show 50</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <div></div>
              </div>
            </div>
          </div>
        </main>
      );
    }
  }
}

export default Reports;
