import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import AuthModal from "../AuthModal";
import { WEB_BASE_URL } from "../../../unified/webbase";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: cookie.load("token"),
      support: [
        {
          name: "Illorin",
          number: "08171299359",
        },
        {
          name: "Lagos",
          number: "08171299359",
        },
      ],
      transactionPin: "",
    };

    this.setTransactionPin = this.setTransactionPin.bind(this);
  }

  getSupport() {
    //toast loading
    const id = toast.loading("Please wait...");

    axios
      .get(WEB_BASE_URL + "/onboarding/get-support", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.data !== null) {
          this.setState({
            support: res.data.data,
          });
        }
        toast.dismiss(id);
      })
      .catch((err) => {
        console.log(err);
        toast.update(id, {
          render: "Error getting support contacts",
          type: "error",
          isLoading: false,
        });
        setTimeout(() => {
          toast.dismiss(id);
        }, 3000);
      });
  }

  setSupport() {
    //toast loading
    const id = toast.loading("Please wait...");

    for (let i in this.state.support) {
      axios
        .post(WEB_BASE_URL + "/dealer/add-support", {
          firstname: i.name.split(" ")[0],
          lastname: i.name.split(" ")[1],
          phoneNumber: i.number,
          transactionPin: this.state.transactionPin,
        })
        .then((res) => {
          toast.dismiss(id);
        })
        .catch((err) => {
          console.log(err);
          toast.update(id, {
            render: "" + err,
            type: "error",
            isLoading: false,
          });
        })
        .then(() => {
          toast.dismiss(id);
        });
    }
  }

  handleChange(e, index, type) {
    const value = e.target.value;
    const support = [].concat(this.state.support);
    support[index][type] = value;
    this.setState({
      support: support,
    });
  }

  setTransactionPin(e) {
    this.setState({
      transactionPin: e.target.value,
    });
  }

  componentDidMount() {
    this.getSupport();
  }

  render() {
    return (
      <form className="row g-3 py-3">
        <h4>Update Support Contacts</h4>
        <div className="row col-12 mt-3">
          <div className="row">
            <label class="form-label">Support Contact 1</label>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                placeholder="Full Name"
                onChange={(e) => {
                  this.handleChange(e, 0, "name");
                }}
                value={
                  this.state.support[0].name === undefined
                    ? ""
                    : this.state.support[0].name
                }
              />
            </div>

            <div class="col-6">
              <input
                type="text"
                class="form-control"
                placeholder="Phone Number"
                onChange={(e) => {
                  this.handleChange(e, 0, "number");
                }}
                value={
                  this.state.support[0].number === undefined
                    ? ""
                    : this.state.support[0].number
                }
              />
            </div>
          </div>
          <div className="row">
            <label class="form-label mt-3">Support Contact 2</label>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                placeholder="Full Name"
                onChange={(e) => {
                  this.handleChange(e, 1, "name");
                }}
                value={
                  this.state.support[1].name === undefined
                    ? ""
                    : this.state.support[1].name
                }
              />
            </div>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                placeholder="Phone Number"
                onChange={(e) => {
                  this.handleChange(e, 1, "number");
                }}
                value={
                  this.state.support[1].number === undefined
                    ? ""
                    : this.state.support[1].number
                }
              />
            </div>
          </div>
        </div>
        <div className="row my-4 px-3 gap-1">
          <button
            data-bs-toggle="modal"
            data-bs-target="#AuthModal"
            type="button"
            class="btn btn-primary px-2  col-md-2"
          >
            Save
          </button>
        </div>
        <AuthModal
          title="Enter Authenticator Code"
          id="AuthModal"
          formPin={this.state.transactionPin}
          setPin={this.setTransactionPin}
          onSubmit={this.setSupport.bind(this)}
        ></AuthModal>
      </form>
    );
  }
}

export default Contact;
