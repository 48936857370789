import React from "react";
import Modal from "./modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "../../unified/webbase";
import EditUser from "./editUserSpd";
import AuthModal from "./activateModal";

class ManageMerchants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: cookie.load("user"),
            token: cookie.load("token"),
            users: [],
            roles: [],
            Pin: [],
            searchQuery: ''
        };

        this.getUsers = this.getUsers.bind(this);
        this.setPin = this.setPin.bind(this);
        this.activateUser = this.activateUser.bind(this);
    }

    activateUser(ide, action) {
        const id = toast.loading("Please wait...");
        axios
            .post(WEB_BASE_URL + "/dealer/activate-merchant?emailAddress=" + ide, {}, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                if (res.data.status == "2000") {
                    toast.update(id, {
                        render: "Success",
                        type: "success",
                        isLoading: false,
                    });
                    this.props.navigate("/manage-merchants");
                    this.props.navigate(0);
                } else {
                    toast.update(id, {
                        render: "" + res.data.description,
                        type: "error",
                        isLoading: false,
                    });
                }
            })
            .catch((err) => {
                toast.update(id, {
                    render: "" + err,
                    type: "error",
                    isLoading: false,
                });
            });
    }


    //get all users
    getUsers() {
        axios
            .post(WEB_BASE_URL + "/onboarding/all-merchants", {

            }, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                console.log(res.data.data.data);
                let iflog = res.data.status == 2000 ? true : false;

                if (iflog) {
                    this.setState({
                        users: res.data.data.data,
                    });
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    setPin(event) {
        this.setState({ Pin: event.target.value });
    }

    editSPD(event, table_id, email, spd9MOBILE, spdMTN, spdAIRTEL, spdGLO) {
        event.preventDefault();
        console.log(email);
        const id = toast.loading("Please wait...");
        axios
            .post(
                WEB_BASE_URL + "/dealer/set-commision?id=" + table_id,
                {
                    emailAddress: email,
                    airtelDiscount: spdAIRTEL,
                    gloDiscount: spdGLO,
                    mtnDiscount: spdMTN,
                    id: table_id,
                    nineMobileDiscount: spd9MOBILE,
                },
                {
                    headers: {
                        authorization: this.state.token,
                    },
                }
            )
            .then((res) => {
                if (res.data.status == "2000") {
                    toast.update(id, {
                        render: "User Discount Edited successfully",
                        type: "success",
                        isLoading: false,
                    });
                } else {
                    console.log(res);
                    toast.update(id, {
                        render: "" + res.data.description,
                        type: "error",
                        isLoading: false,
                    });
                }
            })
            .catch((err) => {
                toast.update(id, {
                    render: "" + err,
                    type: "error",
                    isLoading: false,
                });
            });
    }


    //set state for formRole
    onChangeRole(event) {
        this.setState({
            formRole: event.target.value,
        });
    }

    componentDidMount() {
        this.getUsers();
    }
    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
      };
    render() {
        const { users, searchQuery } = this.state;
        const filteredUsers = users.filter((user) =>
            (user && user.businessEmail &&  user.businessEmail.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user && user.businessName &&  user.businessName.toLowerCase().includes(searchQuery.toLowerCase()))
        );
        function formatNumber(num) {
            if (num === null) {
                return 0.00;
            }
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        }

        return (
            <main className="page-content">
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;">
                                        <i className="bx bx-home-alt"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Manage Merchants
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <h5 className="mb-0">All Users Under You</h5>
                            <form className="ms-auto position-relative">
                                <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                    <i className="bi bi-search"></i>
                                </div>
                                <input
                                    className="form-control ps-5"
                                    type="text"
                                    placeholder="search by email or name"
                                    value={searchQuery}
                                    onChange={this.handleSearchChange}
                                />
                            </form>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table align-middle">
                                <thead className="table-secondary">
                                    <tr>
                                        <th>Account Id</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                        <th>Balance</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredUsers.map((user, index) => (
                                        <tr key={index}>
                                            <td>{user?.accountId}</td>
                                            <td>
                                                <div className="d-flex align-items-center gap-3 cursor-pointer">
                                                    <div className="">
                                                        <p className="mb-0">{user.businessName}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{user?.businessEmail}</td>
                                            <td>{user?.userGroup}</td>
                                            <td>NGN {formatNumber(user?.accountBalance)}</td>
                                            <td>{user.status === true ? "Active" : "Not Active"}</td>
                                            <td>
                                                <div className="table-actions d-flex align-items-center gap-3 fs-6">
                                                    {user.status === true ? (
                                                        <>
                                                            <a
                                                                href="/"
                                                                className="text-danger mx-2"
                                                                title="Disable/Deactivate"
                                                                data-bs-toggle="modal"
                                                                data-bs-target={"#AuthModal" + user.id}
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                }}
                                                            >
                                                                <i class="bi bi-x-circle-fill mr-2"></i>
                                                                {"     "} Disable
                                                            </a>
                                                        </>) : (
                                                        <>
                                                            <a
                                                                href="/"
                                                                className="text-success mx-2"
                                                                title="Enable/Activate"
                                                                data-bs-toggle="modal"
                                                                data-bs-target={"#AuthModal" + user.id}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                            >
                                                                <i class="bi bi-check-circle-fill mr-2"></i>
                                                                {"     "} Enable
                                                            </a>
                                                        </>)}


                                                </div>
                                            </td>
                                            <AuthModal
                                                title="Enter Authenticator Code"
                                                id={"AuthModal" + user.id}
                                                formPin={this.state.Pin}
                                                setPin={this.setPin}
                                                requestId={user.businessEmail}
                                                description="Activate User"
                                                onSubmit={this.activateUser}
                                            ></AuthModal>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default ManageMerchants;
