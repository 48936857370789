import React from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WEB_BASE_URL } from "../../unified/webbase";

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      history: this.props.history,
      otp: "",
      requestId: props.location.state.requestId,
      userEmailAddress: props.location.state.userEmailAddress,
    };

    //bind functionsToOverrideByDerivedClasses
    this.onOtpChange = this.onOtpChange.bind(this);
    this.onTransactionPinChange = this.onTransactionPinChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onResendOTP = this.onResendOTP.bind(this);
    this.createTimer = this.createTimer.bind(this);
  }

  //on OtpChange
  onOtpChange = (e) => {
    this.setState({
      otp: e.target.value,
    });
  };

  //on TransactionPinChange
  onTransactionPinChange = (e) => {
    this.setState({
      transactionPin: e.target.value,
    });
  };

  //onsubmit

  onSubmit = (event) => {
    event.preventDefault();
    const id = toast.loading("Please wait...");

    //axios post to sign in
    axios
      .post(WEB_BASE_URL + "/onboarding/verify-email", {
        otp: this.state.otp,
        requestId: this.state.requestId,
        userEmailAddress: this.state.userEmailAddress,
      })
      .then((res) => {
        if (res.data.status.localeCompare(2000) === 0) {
          toast.update(id, {
            render: "Successfully Verified, Going back to Sign in",
            type: "success",
            isLoading: false,
          });
          setTimeout(() => {
            this.props.navigate("/signIn");
          }, 1500);
        } else {
          toast.update(id, {
            render: "Invalid Credentials",
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: "Invalid Credentials",
          type: "error",
          isLoading: false,
        });
      });
  };
  onResendOTP = (event) => {
    event.preventDefault();
    const id = toast.loading("Please wait...");

    //axios post to sign in
    axios
      .post(WEB_BASE_URL + "/onboarding/re-sendOtp?username=" + this.state.userEmailAddress, {
        userEmailAddress: this.state.userEmailAddress,
      })
      .then((res) => {
        if (res.data.status.localeCompare(2000) === 0) {
          toast.update(id, {
            render: "Successfully sent OTP",
            type: "success",
            isLoading: false,
          });
        } else {
          toast.update(id, {
            render: "Error sending OTP",
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
      });
  };

  //create timer for Resend OTP
  createTimer() {
    let span;
    var timer = 60;
    var interval = setInterval(function () {
      timer--;
      if (timer >= 0) {
        span = document.getElementById("timer");
        span.innerHTML = timer;
        this.setState({
          timer: timer,
        });
      }
      if (timer === 0) {
        clearInterval(interval);
      }
    }, 1000);
  }


  render() {
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <main className="authentication-content">
          <div className="container-fluid">
            <div className="authentication-card">
              <div className="card shadow rounded-0 overflow-hidden">
                <div className="row g-0">
                  <div className="col-lg-6 bg-login d-flex align-items-center justify-content-center">
                    <img
                      src="assets/images/error/login-img.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="card-body p-4 p-sm-5">
                      <h5 className="card-title">Topupbox Dashboard - Verify Email</h5>
                      <p className="card-text mb-5"></p>
                      <form
                        className="form-body"
                        onSubmit={(event) => {
                          event.preventDefault();
                          this.onSubmit(event);
                        }}
                      >
                        <div className="row g-3">
                          <div className="col-12 ">
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-lock-fill"></i>
                              </div>
                              <input
                                type="text"
                                className="form-control radius-30 ps-5"
                                id="inputMerchantName"
                                placeholder="Enter Pin Sent to Your Mail"
                                onChange={this.onOtpChange}
                                value={this.state.otp}
                                required
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div class="col-6">
                              <a
                                href="/"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.onResendOTP();
                                }}
                              >
                                Resend OTP?
                              </a>
                              <i
                                className="float-end"
                              >
                                00:{this.state.timer}
                              </i>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="d-grid">
                              <button
                                type="submit"
                                className="btn btn-primary radius-30"
                              >
                                Sign Up
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

  componentDidMount() {

  }
}

export default VerifyEmail;
