/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Voucheritem from "./voucheritem";
import { WEB_BASE_URL } from "../../unified/webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import AuthModal from "./AuthModal";

class ReprintRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: cookie.load("token"),
      businessName: cookie.load("businessName"),
      vouchers: [],
      backup: [],
      readyPrint: false,
      readyVouchers: [],
      Pin: "",
    };

    this.getReprintRequests = this.getReprintRequests.bind(this);
    this.setPin = this.setPin.bind(this);
  }

  getReprintRequests = () => {
    axios
      .get(WEB_BASE_URL + "/api/v2/w2/get-re-print-request", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.data !== null) {
          if (res.data.data.length > 0) {
            res.data.data.reverse();
            this.setState({ vouchers: res.data.data, backup: res.data.data });
          } else {
            console.log(res);
          }
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  approveReprint = (e, id) => {
    const id1 = toast.loading("Please wait...");
    axios
      .post(
        WEB_BASE_URL + "/api/v2/w2/approve-reprint-request",
        {
          requestId: id,
          transactionPin: this.state.Pin,
        },
        {
          headers: {
            authorization: this.state.token,
          },
        }
      )
      .then((res) => {
        if (res.data.status == 2000) {
          toast.update(id1, {
            render: "Voucher Reprint Request Approved",
            type: "success",
            isLoading: false,
          });

          this.getReprintRequests();
        } else {
          console.log(res);
          toast.update(id1, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update(id1, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
      });
  };

  setPin(event) {
    this.setState({
      Pin: event.target.value,
    });
  }

  componentDidMount() {
    this.getReprintRequests();
  }

  render() {
    const printDiv = {
      display: this.state.readyPrint ? "block" : "none",
    };
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  View Reprint Requests
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button type="button" className="btn btn-primary">
                Wallet Balance: &#8358;200
              </button>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header py-3">
            <div class="row g-3">
              <div class="col-lg-3 col-md-6 me-auto">
                <div class="ms-auto position-relative">
                  <div class="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i class="bi bi-search"></i>
                  </div>
                  <input
                    class="form-control ps-5"
                    type="text"
                    placeholder="Search Request..."
                    onChange={(e) => {
                      let search = e.target.value;
                      if (search.length === 0) {
                        this.setState({
                          vouchers: this.state.backup,
                        });
                        return;
                      }
                      let vouchers = [].concat(this.state.vouchers);

                      let filteed = vouchers.filter((voucher) => {
                        return voucher.rePrintRequestBy
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      });

                      this.setState({
                        vouchers: filteed,
                      });
                    }}
                  />
                </div>
              </div>

              <div class="col-lg-2 col-6 col-md-3">
                <select class="form-select">
                  <option>Show 10</option>
                  <option>Show 30</option>
                  <option>Show 50</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead class="table-light">
                  <tr>
                    <th>Batch ID</th>
                    <th>Name Of User</th>
                    <th>Status</th>
                    <th>Printed On </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.vouchers.map((voucher, index) => (
                    <tr>
                      <td>{index}</td>
                      <td>
                        <div class="d-flex align-items-center gap-3 cursor-pointer">
                          <div class="">
                            <p class="mb-0">{voucher.rePrintRequestBy}</p>
                          </div>
                        </div>
                      </td>

                      <td>
                        {voucher.status == "pending" ? (
                          <span class="badge rounded-pill alert-danger">
                            Pending
                          </span>
                        ) : (
                          <span class="badge rounded-pill alert-success">
                            Approved
                          </span>
                        )}
                      </td>

                      <td>{voucher.rePrintRequestDate.split("T")[0]}</td>
                      <td>
                        <div class="d-flex align-items-center gap-3 fs-6">
                          {voucher.status == "pending" ? (
                            <a
                              href="javascript:void(0);"
                              class="text-dangers"
                              title="Approve"
                              data-bs-toggle="modal"
                              data-bs-target={
                                "#AuthModalApprove" + voucher.requestId
                              }
                            >
                              Approve
                            </a>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <AuthModal
                        title="Enter Authenticator Code"
                        id={"AuthModalApprove" + voucher.requestId}
                        requestId={voucher.requestId}
                        onSubmit={this.approveReprint}
                        formPin={this.state.Pin}
                        setPin={this.setPin}
                      ></AuthModal>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <nav class="float-end mt-3">
              <ul class="pagination">
                <li class="page-item disabled">
                  <a class="page-link" href="#">
                    Previous
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </main>
    );
  }
}

export default ReprintRequests;
