import React from "react";
import Header from "../../unified/header";
import Main from "./main";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from "react-router-dom";
import Transaction from "./transaction";
import Profile from "./profile";
import Users from "./users";
import Fund from "./fund";
import Support from "./support";
import WalletHistory from "./WalletHistory";
import TransferFund from "./transferFund";
import Vouchers from "./vouchers";
import GenerateVoucher from "./generateVoucher";
import CreateUser from "./CreateUser";
import cookie from "react-cookies";
import InviteAgent from "./InviteAgent";
import FundWalletReq from "./fundWalletReq";
import Uploaded from "./Uploaded-files";
import PrintUnits from "./PrintUnits";
import Footer from "./footer";
import Printed from "./PrintedFiles";
import GenerateVoucherB from "./generateVoucher_b";
import VouchersB from "./vouchers_B";
import VouchersHistory from "./vouchers_history";
import ReprintRequests from "./ReprintRequests";
import axios from "axios";
import { WEB_BASE_URL } from "../../unified/webbase";

import Compliance from "./compliance";
import SignIn from "../../unified/signIn";
import FundWalletReqHistory from "./fundWalletReqHistory";
import UploadHistory from "./UploadHistory";
import PrintInvitations from "./PrintInvitations";
import Reports from "./reports";
import FundOperator from "./fundOperator";
import SideBar from "../../unified/SideBar";
import EditTransactionPin from "../../unicard/components/EditTransactionPin";
import { default as UnicardIndex } from "../../unicard/index";
import { default as EnterpriseIndex } from "../../enterprise/index";
import { default as MobileAppIndex } from "../../mobileapp/index";
import { default as BillsIndex } from "../../billVAX/index";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    //  console.log(props);
    this.props = props;
    this.state = {
      walletBalance: cookie.load("walletBalance"),
      token: cookie.load("token"),
      user: cookie.load("user"),
    };

    this.getWalletBalance = this.getWalletBalance.bind(this);
  }

  getWalletBalance() {
    axios
      .get(WEB_BASE_URL + "/onboarding/account-balance", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        cookie.save("walletBalance", res.data.data.unicardBalance, {
          path: "/",
          expires: new Date(Date.now() + 60 * 30 * 1000),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    const user = cookie.load("user");
    const token = cookie.load("token");
    const sidebar = cookie.load("sidebar");
    if (!user || !token || !sidebar) {
      this.setState({
        user: undefined,
      });
      return;
    } else {
      this.getWalletBalance();
    }
  }

  componentDidUpdate() {
    const user = cookie.load("user");
    const token = cookie.load("token");
    const sidebar = cookie.load("sidebar");

    if (this.state.user !== undefined) {
      if (!user || !token || !sidebar) {
        this.setState({
          user: undefined,
        });
      }
    }
  }



  render() {
    if (this.state.token === undefined) {
      return (
        <SignIn {...this.props} />
      )
    }


    if (this.props.activeProduct == "unicard") {
      return (
        <UnicardIndex {...this.props} product={this.props.activeProduct} setActiveProduct={this.props.setActiveProduct}></UnicardIndex>
      );
    }
    if (this.props.activeProduct == "enterprise") {
      return (
        <EnterpriseIndex {...this.props} product={this.props.activeProduct} setActiveProduct={this.props.setActiveProduct}></EnterpriseIndex>
      );
    }

    if (this.props.activeProduct == "mobileapp") {
      return (
        <MobileAppIndex {...this.props} product={this.props.activeProduct} setActiveProduct={this.props.setActiveProduct}></MobileAppIndex>
      );
    }

    if (this.props.activeProduct == "bills") {
      return (
        <BillsIndex {...this.props} product={this.props.activeProduct} setActiveProduct={this.props.setActiveProduct}></BillsIndex>
      );
    }

    return (
      <EnterpriseIndex {...this.props} product={this.props.activeProduct} setActiveProduct={this.props.setActiveProduct}></EnterpriseIndex>
    );
  }
}

export default Dashboard;
