import  React, {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelIcon from '@mui/icons-material/Cancel';

import axios from 'axios';
import cookie from "react-cookies";
import { MOBILE_WEB_BASE_URL } from '../../unified/webbase';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  boxShadow: 24,
};

export default function UpdateUser({open, handleClose, iid}) {
     
      const token = cookie.load("token")
    
    const setThresholdValue = (e) =>{
        e.preventDefault()
        const id = toast.loading("Please wait...");
    

    axios
    .post(MOBILE_WEB_BASE_URL + `/upgrade-user?${iid}`,  {
      headers: {
        authorization: token,
      },
    })
    .then((res) => {
      if (res.data.status === "2000") {
        toast.update(id, {
          render: res.data.description,
          type: "success",
          isLoading: false,
        });
        setTimeout(() => {
        //   toast.dismiss(id);
          window.location.href ="/app-users";
        }, 2000);
      } else {
        console.log(res);
        toast.update(id, {
          render: res.data.description,
          type: "error",
          isLoading: false,
        });
      }
    })
    .catch((err) => {
      toast.update(id, {
        render: err,
        type: "error",
        isLoading: false,
      });
    });
}


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className=' rounded-lg  p-4 pt-10'> 
          
            <div className=' '>
           
                <div className=' d-flex justify-content-end'>
                   
                    <div>
                    <CancelIcon onClick={handleClose} className=' text-black cursor-pointer text-[38px]' fontSize="large"/>
                    
                    </div>
                </div>
                <h5 className=' px-4 '> Are you sure you want to Update user?</h5>
                <div className=' row container'>
                        <div className=' col-2'>

                        </div>
                        <div className=' col-4'>
                        <div className="btn-group">
                                <button type="submit" 
                                onClick={handleClose}
                                className="btn btn-primary">
                                    No
                                </button>
                                </div>
                        </div>
                        <div className=' col-4'>
                        <div className="btn-group">
                                <button type="submit" 
                                onClick={setThresholdValue}
                                className="btn btn-primary">
                                    Yes
                                </button>
                                </div>
                        </div>
                        <div className=' col-2'>

                        </div>
                </div>
                <form onSubmit={setThresholdValue} className="row">
       
                      
                        </form>
               
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}