import React from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Select from "react-select";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "../../unified/webbase";
import AuthModal from "./AuthModal";
import Footer from "./footer";

class GenerateVoucher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      walletBalance: cookie.load("walletBalance"),
      networkOptions: [
        { value: "MTN", label: "MTN" },
        { value: "AIRTEL", label: "AIRTEL" },
        { value: "GLO", label: "GLO" },
        { value: "NINEMOBILE", label: "9MOBILE" },
      ],
      unitAmountOptions: [
        { value: "100", label: "100" },
        { value: "200", label: "200" },
        { value: "500", label: "500" },
        { value: "1000", label: "1000" },
      ],
      businessName: cookie.load("businessName"),
      token: cookie.load("token"),
      description: "",
      units: "",
      unitAmount: "",
      Pin: "",
      network: "",
      costPrice: "",
    };
    this.onSave = this.onSave.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setDescription = this.setDescription.bind(this);
    this.setUnits = this.setUnits.bind(this);
    this.setUnitAmount = this.setUnitAmount.bind(this);
    this.setPin = this.setPin.bind(this);
    this.setNetwork = this.setNetwork.bind(this);
    this.setCostPrice = this.setCostPrice.bind(this);
  }

  onSave(event) {
    event.preventDefault();
    const id = toast.loading("Please wait...");

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;

    const user = {
      orderDescription: this.state.description,
      transactionPin: this.state.Pin,
      units: this.state.units,
      unitAmount: this.state.unitAmount,
      network: this.state.network,
      costPrice: this.state.costPrice,
    };
    console.log();
    axios
      .post(WEB_BASE_URL + "/logical/initiate-order", user, {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        if (res.data.status === "2000") {
          toast.update(id, {
            render: "" + res.data.description,
            type: "success",
            isLoading: false,
          });
          console.log(user);
          setTimeout(() => {
            toast.dismiss(id);
            this.props.navigate("/execute-orders");
            this.props.navigate(0);
          }, 2000);
        } else {
          console.log(res);
          toast.update(id, {
            render: "" + res.data.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        toast.update(id, {
          render: err,
          type: "error",
          isLoading: false,
        });
      });
  }

  onSubmit(event) {
    this.onSave(event);
  }

  //write setter function
  setDescription(description) {
    if (description.target.value.length < 5) {
      this.setState({
        description: description.target.value,
      });
    } else {
      alert("Description can not be longer than 4 characters");
    }
  }

  setUnits(units) {
    this.setState({
      units: units.target.value,
    });
  }

  setNetwork(network) {
    this.setState({
      network: network.value,
    });
  }

  setUnitAmount(unitAmount) {
    this.setState({
      unitAmount: unitAmount.value,
    });
  }

  setPin(event) {
    this.setState({
      Pin: event.target.value,
    });
  }

  setCostPrice(costPrice) {
    this.setState({
      costPrice: costPrice.target.value,
    });
  }

  render() {
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.selectProps.menuColor,
        padding: 20,
      }),

      control: (provided, { selectProps: { width } }) => ({
        ...provided,
        borderRadius: "25px",
        padding: "2px 4px",
        width: width,
        minWidth: "310px",
      }),
    };
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Innitiate New Order
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
              >
                Wallet Balance : &#8358; {this.state.walletBalance}
              </button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <form
              autocomplete="off"
              class="row g-3"
              onSubmit={(event) => {
                this.onSave(event);
              }}
            >
              <div class="col-6">
                <label class="form-label">Voucher Description</label>
                <input
                  type="text"
                  name="description"
                  class="form-control"
                  style={{ borderRadius: "17px" }}
                  value={this.state.description}
                  onChange={(event) => this.setDescription(event)}
                  required
                />
              </div>
              <div class="col-6">
                <label class="form-label">Network</label>
                <Select
                  onChange={this.setNetwork}
                  options={this.state.networkOptions}
                  styles={customStyles}
                  width="100%"
                  placeholder="Select Network"
                  borderRadius="17px"
                />
              </div>
              <div class="col-6">
                <label class="form-label">Units</label>
                <input
                  type="number"
                  name="units"
                  class="form-control"
                  style={{ borderRadius: "17px" }}
                  role="voucher"
                  value={this.state.units}
                  onChange={(event) => this.setUnits(event)}
                  required
                />
              </div>
              <div class="col-6">
                <label class="form-label">Voucher denomination</label>
                <Select
                  onChange={this.setUnitAmount}
                  options={this.state.unitAmountOptions}
                  styles={customStyles}
                  width="100%"
                  placeholder="Select Unit Price"
                  borderRadius="17px"
                />
              </div>

              <div class="col-6">
                <label class="form-label">Cost Price</label>
                <input
                  type="number"
                  name="units"
                  class="form-control"
                  style={{ borderRadius: "17px" }}
                  value={this.state.costPrice}
                  onChange={(event) => this.setCostPrice(event)}
                  required
                />
              </div>

              <div class="text-start">
                <input
                  type="button"
                  class="btn btn-primary px-4"
                  value="Initiate Order"
                  data-bs-toggle="modal"
                  data-bs-target="#AuthModal"
                />
              </div>
              <AuthModal
                title="Enter Authenticator Code"
                id="AuthModal"
                formPin={this.state.Pin}
                setPin={this.setPin}
                onSubmit={this.onSubmit}
              ></AuthModal>
            </form>
          </div>
        </div>
      </main>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default GenerateVoucher;
