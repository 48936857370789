import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import cookie from "react-cookies";
import { MOBILE_WEB_BASE_URL } from '../../unified/webbase';
import { toast } from 'react-toastify';
import axios from 'axios';
import PromoDiscount from './PromoDiscountModal';
import ResellerDiscount from './ResellerModal';
import UpdateUser from './UpdateUser';
function SetDiscount() {
    const walletBalance = cookie.load("walletBalance")
    const token = cookie.load("token")
    const [users,setUsers] = useState([])
    const [search, setSearch] = useState('')
    const [open, setOpen] = useState(false)
    const handleClose= () => setOpen(false)
    const [open1, setOpen1] = useState(false)
    const handleClose1= () => setOpen1(false)
    const [open2, setOpen2] = useState(false)
    const handleClose2= () => setOpen2(false)
    const [id, setId] = useState('')
    useEffect(() => {
        let api = "";
        api = "/get-all-users";
        axios
            .get(
                MOBILE_WEB_BASE_URL + "/get-all-users",
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            .then((res) => {
                if (res.data.status === "2000" && res.data.data !== null) {
                    console.log(res.data.data);
                   setUsers(res.data.data.reverse())
                } else {
                    toast.error("No data found");
                    setUsers([])
                }
            })
            .catch((err) => {
                console.log(MOBILE_WEB_BASE_URL + " " + err);
                toast.error("Error fetching transactions");
            }).finally(() => {
                
            });
    },[ token])
   
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "NGN",
    });
    const filteredData = users.filter((person) => {
        const searchLowerCase = search.toLowerCase();
        const fullName = `${person.firstName} ${person.middleName} ${person.lastName}`.toLowerCase();
        return fullName.includes(searchLowerCase) || person.emailAddress.toLowerCase().includes(searchLowerCase);
      });
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "NGN",
      });
  return (
    
    <main className="page-content">
    <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <PromoDiscount
                    open={open}
                    handleClose={handleClose}
                />
                <ResellerDiscount
                     open={open1}
                    handleClose={handleClose1}
                />
                <UpdateUser
                    open={open2}
                    handleClose={handleClose2}
                    iid={id}
                />
                 <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;">
                                        <i className="bx bx-home-alt"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                Manage discount
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">
                        <div className="btn-group">
                            <button type="button" className="btn btn-primary">
                                Wallet Balance: {formatter.format(walletBalance) }
                            </button>
                        </div>
                    </div>
                </div>
     
        <div className=' row'>
            <div className=' col-3'>
            <div className="btn-group">
                            <button
                            onClick={() => { setOpen(true)}}
                             type="button" className="btn btn-primary">
                               Promo discount
                            </button>
                        </div>
            </div>
            <div className=' col-3'>
            <div className="btn-group">
                            <button 
                            onClick={() => { setOpen1(true)}}
                            type="button" className="btn btn-primary">
                            Resellers discount
                            </button>
                        </div>
            </div>
        </div>
        <div className=' row mt-4'>
    <div className="col-lg-6 col-md-6 me-auto">
                <div className="ms-auto position-relative">
                  <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i className="bi bi-search"></i>
                  </div>
                  <input
                    className="form-control ps-5"
                    type="text"
                    placeholder="Search by name or email"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
    </div>
        <div className=' mt-6'>
        <div className=' bg-white mt-4'>
        <div class="table-responsive">
                            
                            <table className="table align-middle mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th>Name</th>
                                        {/* <th>Contact Address</th> */}
                                        <th>Referral code</th>
                                        <th>Merchant Name</th>
                                        {/* <th>State</th>
                                        <th>Local Gov</th> */}
                                        <th>Email Address</th>
                                        <th>Phone Number</th>
                                        <th>Account balance</th>
                                        <th>Date Created</th>
                                        <th>Last Login</th>
                                        <th>Bvn Status</th>
                                        <th>PinStatus</th>
                                        <th>Address Status</th>
                                        <th>status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   
                                    {filteredData.length !== 0 ? (
                                        filteredData.map((e, index) => (
                                            <tr key={index}>
                                                <td>{e?.firstName} {e?.middleName} {e?.lastName}</td>
                                                
                                                {/* <td>{
                                                    e.contactAddress
                                                }</td> */}
                                                <td>{e?.referralCode}</td>
                                                
                                                <td>{e?.merchantName}</td>
                                                {/* <td>{e?.states}</td>
                                                <td>{e?.localGov}</td> */}
                                                <td>{e?.emailAddress}</td>
                                               
                                                <td>{e?.phoneNumber}</td>
                                                <td>{ formatter.format(e?.currentBal)}</td>
                                                <td> {e.dateCreated !== undefined
                                                            ? new Date(e.dateCreated).toLocaleString()
                                                            : 0}</td>
                                                <td>{e.lastLogin !== undefined
                                                            ? new Date(e.lastLogin).toLocaleString()
                                                            : 0}
                                                </td>
                                                <td>{e?.bvnStatus === true ?"Active":"Inactive"}</td>
                                                <td>{e?.pinStatus=== true ?"Active":"Inactive"}</td>
                                                <td>{e?.addressStatus === true ?"Active":"Inactive"}</td>
                                                <td>{e?.status}</td>
                                                <td>
                                                <div className="btn-group">
                                                    <button 
                                                    onClick={() => {
                                                        setOpen2(true)
                                                        setId(e?.id)
                                                    }}
                                                    type="button" className="btn btn-primary">
                                                    Update user
                                                    </button>
                                                </div>
                                                </td>
                                                 
                                            </tr>
                                        ))
                                    ) : (
                                        <tr colSpan="7">
                                            <td>No User record found.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div> 
            </div>
       </div>
    </main>
  )
}

export default SetDiscount