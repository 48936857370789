import axios from "axios";
import React from "react";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import TransferModal from "./TransferModal";
import { WEB_BASE_URL } from "../../unified/webbase";

class TransferFund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: props.histroy,
      walletBalance: cookie.load("walletBalance"),
      token: cookie.load("token"),
      users: [],
    };

    this.getUsers = this.getUsers.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();
    toast.loading("Transferring Funds...");
    setTimeout(() => {
      toast.dismiss();
      toast.success("Funds Transferred");
    }, 2000);
  }

  //get all users
  getUsers() {
    axios
      .get(WEB_BASE_URL + "/onboarding/get-all-users", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        let iflog = res.data.status == 2000 ? true : false;

        if (iflog) {
          this.setState({
            users: res.data.data,
          });
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.getUsers();
  }

  render() {
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  All Users
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
              >
                Wallet Balance : &#8358;{this.state.walletBalance}
              </button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <h5 className="mb-0">All Users Under You</h5>
              <form className="ms-auto position-relative">
                <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                  <i className="bi bi-search"></i>
                </div>
                <input
                  className="form-control ps-5"
                  type="text"
                  placeholder="search"
                />
              </form>
            </div>
            <div className="table-responsive mt-3">
              <table className="table align-middle">
                <thead className="table-secondary">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.users.map((user, index) => (
                    <>
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="d-flex align-items-center gap-3 cursor-pointer">
                            <img
                              src="assets/images/dummy.png"
                              className="rounded-circle"
                              width="44"
                              height="44"
                              alt=""
                            />
                            <div className="">
                              <p className="mb-0">{user.name}</p>
                            </div>
                          </div>
                        </td>
                        <td>{user.emailAddress}</td>
                        <td>{user.role}</td>
                        <td>
                          <div className="table-actions d-flex align-items-center gap-3 fs-6">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target={"#TransferModal" + index}
                            >
                              Transfer
                            </button>
                          </div>
                        </td>
                      </tr>
                      <TransferModal
                        title={"Transfer Funds to User "}
                        id={"TransferModal" + index}
                        history={this.state.history}
                        onSubmit={this.onSubmit}
                        dealerId={user.dealerId}
                        emailAddress={user.emailAddress}
                        formEmail={user.emailAddress}
                        formRole={user.role}
                      ></TransferModal>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default TransferFund;
