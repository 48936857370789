import React from "react";
import cookie from "react-cookies";
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import EmailIcon from '@mui/icons-material/Email';
class Support extends React.Component {
  constructor() {
    super();
    this.state = {
      dealerId: cookie.load("dealerId")
        ? cookie.load("dealerId").toUpperCase
        : "",
    };
  }

  render() {
    return (
      <main className="page-content">
      <h6 className="mb-0 text-uppercase">Support Lines</h6>
            <div className="my-3 border-top"></div>
       <div className="row ">
       <div className="card mx-2 col-md-3">
                  <div className="row g-0 p-2">
                    <div className="col-md-4">
                      <img
                        src="assets/images/gallery/60.png"
                        alt="..."
                        className="card-img"
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">
                          Topupbox Support Line - Bisi
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mx-2 col-md-4">
                  <div className="row g-0 p-2">
                   
                    <div className="col-md-12">
                      <div className="card-body">
                        <h5 className="card-title">
                          Contact numbers
                        </h5>
                        

                        <p className="card-text">
                          <small className="text-muted"></small>
                        </p>
                        <div className=" row">
                          <div className=" col">
                            <p>Illorin contact</p>
                            <h6>08171299359</h6>
                          </div>
                          <div className=" col">
                            <p>Lagos contact</p>
                            <h6>08171299359</h6>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mx-2 col-md-4">
                  <div className="row g-0 p-2">
                  
                    <div className="col-md-12">
                      <div className="card-body">
                        <h5 className="card-title">
                        Social Media handles
                        </h5>
                        
                        <p className="card-text">
                          <small className="text-muted"></small>
                        </p>
                        
                        <div className=" mt-4">
                         
                          <div className=" row">
                         
                            <div className=" col">
                             
                              <a href="https://www.instagram.com/topupboxhq/" className=" px-4" target="_blank" rel="noreferrer"><InstagramIcon/></a>
                              <p>@topupboxhq</p>
                            </div>
                            <div className=" col">
                            
                              <a href="https://twitter.com/topupboxHQ" className=" px-4" target="_blank" rel="noreferrer"><XIcon/></a>
                              <p>@topupboxHQ</p>
                            </div>
                            <div className=" col">
                          
                              <a href="mailto:support@topupbox.com" className=" px-4" target="_blank" rel="noreferrer"><EmailIcon/></a>
                              <p className=" mt-2">support@topupbox.com</p>
                            </div>
                            <div className=" col"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
       </div>
        
       {/* <div className="card col-4 bg-transparent shadow-none">
          <div className="card-body">
            
            <div className="ms-auto"></div>
            <div className="my-3"></div>
            <div className="row row-cols-1 row-cols-lg-2 justify-content-center g-lg-5">

              <div className="col">
                <div className="card">
                  <div className="row g-0 p-2">
                    <div className="col-md-4">
                      <img
                        src="assets/images/gallery/60.png"
                        alt="..."
                        className="card-img"
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">
                          Topupbox Support Line - Bisi
                        </h5>
                        <p className="card-text">support@topupbox.com</p>
                        <p className="card-text">
                          <small className="text-muted"></small>
                        </p>
                        <div className=" row">
                          <div className=" col">
                            <p>Illorin contact</p>
                            <h6>08171299359</h6>
                          </div>
                          <div className=" col">
                            <p>Lagos contact</p>
                            <h6>08171299359</h6>
                          </div>
                        </div>
                        <div className=" mt-4">
                          <h6>Social Media handles</h6>
                          <div className=" row">
                         
                            <div className=" col">
                              <a href="https://www.instagram.com/topupboxhq/" target="_blank" rel="noreferrer"><InstagramIcon/></a>
                            </div>
                            <div className=" col">
                              <a href="https://twitter.com/topupboxHQ" target="_blank" rel="noreferrer"><XIcon/></a>
                            </div>
                            <div className=" col">
                              <a href="mailto:support@topupbox.com" target="_blank" rel="noreferrer"><EmailIcon/></a>
                            </div>
                            <div className=" col"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </main>
    );
  }

  componentDidMount() {
    this.setState({
      someKey: "otherValue",
    });
  }
}

export default Support;
