import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import cookie from "react-cookies";
import { MOBILE_WEB_BASE_URL } from '../../unified/webbase';
import BlockUser from './BlockUser';

function AppUsers() {
    const token = cookie.load("token")
    const [users,setUsers] = useState([])
    const [search, setSearch] = useState('')
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const [id, setId] = useState('')
    const [status, setStatus] = useState(false)
    useEffect(() => {
        let api = "";
        api = "/get-all-users";
        axios
            .get(
                MOBILE_WEB_BASE_URL + "/get-all-users",
                {
                    headers: {
                        authorization: token,
                    },
                }
            )
            .then((res) => {
                if (res.data.status === "2000" && res.data.data !== null) {
                    console.log(res.data.data);
                   setUsers(res.data.data.reverse())
                } else {
                    toast.error("No data found");
                    setUsers([])
                }
            })
            .catch((err) => {
                console.log(MOBILE_WEB_BASE_URL + " " + err);
                toast.error("Error fetching transactions");
            }).finally(() => {
                
            });
    },[ token])
   

    const filteredData = users.filter((person) => {
        const searchLowerCase = search.toLowerCase();
        const fullName = `${person.firstName} ${person.middleName} ${person.lastName}`.toLowerCase();
        return fullName.includes(searchLowerCase) || person.emailAddress.toLowerCase().includes(searchLowerCase);
      });
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "NGN",
      });
  return (
    
    <main className="page-content">
    <BlockUser
        open={open}
        handleClose={handleClose}
        id={id}
        status={status}
    />
    <ToastContainer
         position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
      />
    <h3 className=' mb-2'>Mobile App users</h3> 
    <div className=' row'>
    <div className="col-lg-6 col-md-6 me-auto">
                <div className="ms-auto position-relative">
                  <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i className="bi bi-search"></i>
                  </div>
                  <input
                    className="form-control ps-5"
                    type="text"
                    placeholder="Search by name or email"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
    </div>
   
<div className=' mt-6'>
        <div className=' bg-white mt-4'>
        <div class="table-responsive">
                            
                            <table className="table align-middle mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th>Name</th>
                                        {/* <th>Contact Address</th> */}
                                        <th>Referral code</th>
                                        <th>Merchant Name</th>
                                        {/* <th>State</th>
                                        <th>Local Gov</th> */}
                                        <th>Email Address</th>
                                        <th>Phone Number</th>
                                        <th>Account balance</th>
                                        <th>Date Created</th>
                                        <th>Last Login</th>
                                        <th>Bvn Status</th>
                                        <th>PinStatus</th>
                                        <th>Address Status</th>
                                        <th>status</th>
                                        <th>blocked</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   
                                    {filteredData.length !== 0 ? (
                                        filteredData.map((e, index) => (
                                            <tr key={index}>
                                                <td>{e?.firstName} {e?.middleName} {e?.lastName}</td>
                                                
                                                {/* <td>{
                                                    e.contactAddress
                                                }</td> */}
                                                <td>{e?.referralCode}</td>
                                                
                                                <td>{e?.merchantName}</td>
                                                {/* <td>{e?.states}</td>
                                                <td>{e?.localGov}</td> */}
                                                <td>{e?.emailAddress}</td>
                                               
                                                <td>{e?.phoneNumber}</td>
                                                <td>{ formatter.format(e?.currentBal)}</td>
                                                <td> {e.dateCreated !== undefined
                                                            ? new Date(e.dateCreated).toLocaleString()
                                                            : 0}</td>
                                                <td>{e.lastLogin !== undefined
                                                            ? new Date(e.lastLogin).toLocaleString()
                                                            : 0}
                                                </td>
                                                <td>{e?.bvnStatus === true ?"Active":"Inactive"}</td>
                                                <td>{e?.pinStatus=== true ?"Active":"Inactive"}</td>
                                                <td>{e?.addressStatus === true ?"Active":"Inactive"}</td>
                                                <td>{e?.status}</td>
                                                <td>
                                                <div className="btn-group">
                                                    <button type="submit" 
                                                    onClick={() =>{
                                                        setOpen(true)
                                                        setId(e?.id)
                                                        setStatus(e?.blocked)
                                                    }}
                                                    className="btn btn-primary">
                                                      {e?.blocked=== true ?"Blocked user":"Active user"}
                                                    </button>
                                                    </div>
                                                </td>
                                                 
                                            </tr>
                                        ))
                                    ) : (
                                        <tr colSpan="7">
                                            <td>No User record found.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div> 
            </div>
       </div>
    </main>
  )
}

export default AppUsers